import { MdHome, MdMenu,  MdAssignment } from 'react-icons/md';
import { FcWorkflow, FcTodoList } from "react-icons/fc";
import { GiSolarPower } from "react-icons/gi";
import { CloudUploadFill } from 'framework7-icons/react';
import { Link } from "konsta/react";

import HasConnection from '../Components/Utils/HasConnection/HasConnection';

import Home from '../Components/Pages/Home/Home';
import Calendar from '../Components/Layout/Calendar/Calendar';
import Notifications from '../Components/Pages/Notifications/Notifications';
import DataSync from '../Components/Pages/DataSync/DataSync';
import Search from '../Components/Pages/Search/Search';
import WorkTask from '../Components/Pages/WorkTask/WorkTaskHome';
import OperationalEvent from '../Components/Pages/OperationalEvent/OperationalEventHome';
import WorkOrder from '../Components/Pages/WorkOrder/WorkOrderHome';
import WorkOrderNewInfo from '../Components/Pages/WorkOrder/WorkOrderNewInfo';
import WorkOrderUploadFile from '../Components/Pages/WorkOrder/WorkOrderUploadFile';
import WorkAssignmentLanding from '../Components/Pages/WorkAssignment/WorkAssignmentMain';
import WorkAssignmentDetail from '../Components/Pages/WorkAssignment/WorkAssignmentDetail';
import WorkOrderDetailView from '../Components/Pages/WorkOrder/WorkOrderDetail';
import WorkTaskDetailView from '../Components/Pages/WorkTask/WorkTaskDetail';
import OperationalEventDetailView from '../Components/Pages/OperationalEvent/OperationalEventDetail';
import AssetHome from '../Components/Pages/Assets/AssetHome';
import AssetDetail from '../Components/Pages/Assets/AssetDetail';
import InspectionForm from '../Components/Pages/InspectionForm/InspectionForm';
import TimeTracker from '../Components/Pages/TimeTracker/TimeTracker';

const pageRoutes = [{
        id: 'home-page',
        name: 'home',
        nav: '/',
        cmp: <Home />,
        renderNavbarLeft: (navigate) => {
            return (
                <Link onClick={() => { navigate('/') }}>
                    <img width="80px" src="/PF-logo-primary-fullcolor-trustednavy.png" alt="Power Factors" />
                </Link>
            );
        },
        renderNavbarRight: () => {
            return (
                <div className="flex items-center space-between">
                    <div className="pl-2">
                        <HasConnection />
                    </div>
                </div>
            );
        }
    },
    { id: 'apps-page',name: 'apps',  nav: '/apps', cmp: <Calendar /> },
    { id: 'notification-page',name: 'notifications',  nav: '/Notifications', cmp: <Notifications /> },
    { id: 'sync-page',name: 'sync',  nav: '/sync', cmp: <DataSync /> },
    { id: 'search-page',name: 'global-search',  nav: '/global-search', cmp: <Search /> },
    {
        id: 'work-order-page',
        name: 'Work Order',
        nav: '/work-order',
        cmp: <WorkOrder />,
        renderNavbarRight: (navigate) => {
            const newWorkOrderPageNav = pageRoutes.find((route) => route.id === "work-order-new-info-page")?.nav;
            return (<Link onClick={() => { navigate(newWorkOrderPageNav); }} className="text-sky-700">New</Link>)
        }
    },
    { id: 'work-order-upload-file',name: 'Work Order Upload File',  nav: '/work-order-upload-file', cmp: <WorkOrderUploadFile /> },
    { id: 'work-order-new-info-page',name: 'Work Order New Info',  nav: '/work-order-new-info', cmp: <WorkOrderNewInfo /> },
    {
        id: 'work-order-detail-page',
        name: 'work-order-detail',
        nav: '/work-order/detail/:id',
        cmp: <WorkOrderDetailView /> ,
        renderNavbarLeft: (navigate) => {
            return <Link onClick={() => { navigate(-1) }} className="text-sky-700">Back</Link>
        }
    },
    { id: 'work-task-page',name: 'Work Task',  nav: '/work-task', cmp: <WorkTask /> },
    { id: 'work-assignment-page', name: 'Work Assignments', nav: '/work-assignment', cmp: <WorkAssignmentLanding /> },
    { id: 'time-tracker-page', name: 'Time Tracker', nav:'/time-tracker-page', cmp: <TimeTracker/>,
        renderNavbarLeft: (navigate) => {
            return <Link onClick={() => { navigate(-1) }} className="text-sky-700">Back</Link>
        }},
    {
        id: 'work-assignment-detail-page',
        name:'Work Assignment Detail',
        nav: '/work-assignment/detail/:id',
        cmp:<WorkAssignmentDetail/>,
        renderNavbarLeft: (navigate) => {
            return <Link onClick={() => { navigate(-1) }} className="text-sky-700">Back</Link>
        }
    },
    { id: 'work-task-page',name: 'Work Task',  nav: '/work-task', cmp: <WorkTask /> },
    {
        id: 'work-task-detail-page',
        name: 'Work Task Detail',
        nav: '/work-task/detail/:id',
        cmp: <WorkTaskDetailView />,
        renderNavbarLeft: (navigate) => {
            return <Link onClick={() => { navigate(-1) }} className="text-sky-700">Back</Link>
        }
    },
    { id: 'operational-event-page',name: 'Operational Event',  nav: '/operational-event', cmp: <OperationalEvent /> },
    {
        id: 'operational-event-detail-page',
        name: 'Operational Event Detail',
        nav: '/operational-event/detail/:id',
        cmp: <OperationalEventDetailView />,
        renderNavbarLeft: (navigate) => {
            return <Link onClick={() => { navigate(-1) }} className="text-sky-700">Back</Link>
        }
    },
    { id: 'asset-page', name: 'assets',  nav: '/asset', cmp: <AssetHome /> },
    {
        id: 'asset-detail-page',
        name: 'Asset Detail',
        nav: '/asset/detail/:id',
        cmp: <AssetDetail />,
        renderNavbarLeft: (navigate) => {
            return <Link onClick={() => { navigate(-1) }} className="text-sky-700">Back</Link>
        },
    },
    {
        id: 'inspection-form-page',
        name: 'Inspection Form',
        nav: '/inspection-form/:id',
        cmp: <InspectionForm />,
        renderNavbarLeft: (navigate) => {
            return <Link onClick={() => { navigate(-1) }} className="text-sky-700">Back</Link>
        }
    }
]

const leftMenuPanelRoutes = [
    {   type: 'action-nav-list',
        items : [
            {id: 'work-assignments', label: "Work Assignments", nav: '/work-assignment', icon:  <MdAssignment/> },
            {id: 'work-orders', label: "Work Orders", nav: '/work-order', icon:  <FcWorkflow/>},
            {id: 'work-tasks', label: "Work Tasks", nav: '/work-task', icon:  <FcTodoList/>},
            {id: 'assets', label: "Asset Tag", nav: '/asset', icon:  <GiSolarPower/>},
        ],
    }
]

const dockedFooterRoutes = [
    {id: 'home-menu', label: "Home", nav: '', icon:  <MdHome className="w-6 h-6" />},
    {id: 'sync-menu', label: "Data Sync", nav: 'sync', icon:  <CloudUploadFill className="w-7 h-7" />},
    {id: 'hamburger-menu', label: "Menu", nav: 'menu', icon: <MdMenu className="w-6 h-6" />}
]

export { pageRoutes, leftMenuPanelRoutes, dockedFooterRoutes };