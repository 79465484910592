import { DataStore } from "aws-amplify";
import { useEffect, useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import { useParams } from "react-router-dom";
import { dynamoTables } from "../../../dbTables/syncObjects";
import "./HierarchySearch.css";
import "react-checkbox-tree/lib/react-checkbox-tree.css";

export default function HierarchySearch({tree, asset, setTreeBranch}){
    const { id } = useParams();
    const [searchedNodes, setSearchNodes] = useState([]);
    const [assetTree, setAssetTree] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [query, setQuery] = useState("");
    const [currentAsset, setCurrentAsset] = useState({});
    const [searching, setSearching] = useState(false);
    const assTable = dynamoTables.find((table) => table.ref === "pffsm__smEquipment__c");

    useEffect(()=>{
      const asset = Array.from(document.getElementsByClassName('rct-title'))
      asset.forEach(asset => {
        (asset.innerText === `${currentAsset?.Name} - ${currentAsset?.pffsm__Description__c}`) ?
          asset.parentNode.classList.add('current-asset')
          :
          asset.parentNode.classList.remove('current-asset')
      });
    })

    useEffect(()=>{
      async function getExpanded(){
        const toExpand = await expandTree(currentAsset, []);
        setExpanded(toExpand)
      }
      if(currentAsset?.id){
        getExpanded();
      }
    }, [currentAsset])

    useEffect(()=>{
        const treeClone = JSON.parse(JSON.stringify(tree))
        setAssetTree(treeClone)
        setCurrentAsset(asset)
    }, [tree, asset]);

    useEffect(()=>{
      setSearchNodes(assetTree);
  }, [assetTree]);


    const expandTree = async (asset, assList) =>{
      let expandedAss = [...assList]
      expandedAss.push(asset.id)
      const assetById = await DataStore.query(assTable.model, asst => asst.sfId__custom.eq(asset.pffsm__Parent__c));
      if(assetById[0]?.pffsm__Parent__c){
        return expandTree(assetById[0], expandedAss)
      }
      else{
        if(assetById?.[0]?.id){
          expandedAss.push(assetById?.[0].id)
        }
        return expandedAss
      }
    }
  const queryFilterNodes = (nodes, keyword) => {
    let newNodes = [];
    for (let n of nodes) {
      if (n?.children) {
        const nextNodes = queryFilterNodes(n.children, keyword);
        if (nextNodes.length > 0) {
          n.children = nextNodes;
        }
        else if (n?.label?.toLowerCase()?.includes(keyword?.toLowerCase())) {
          n.children = nextNodes.length > 0 ? nextNodes : [];
        }
        if ( nextNodes.length > 0 || n?.label?.toLowerCase()?.includes(keyword?.toLowerCase())) {
            newNodes.push(n);
            }
        }
      else {
        if (n?.label?.toLowerCase()?.includes(keyword?.toLowerCase())) {
          newNodes.push(n);
        }
      }
    }
    return newNodes;
  };

  const getAllNodeValues = (nodes, firstLevel) => {
    if (firstLevel) {
      let values = [];
      for (let n of nodes) {
        values.push(n.value);
        if (n.children) {
          values.push(...getAllNodeValues(n.children, false));
        }
      }
      return values;
    } else {
      let values = [];
      for (let n of nodes) {
        values.push(n.value);
        if (n.children) {
          values.push(...getAllNodeValues(n.children, false));
        }
      }
      return values;
    }
  };

  useEffect(()=>{
    // use debounce to avoid a query per keystroke - causes performance issues and visible lag
    // on each query - clone original Tree and find matches
    const timer = setTimeout(() => {
        const treeClone = JSON.parse(JSON.stringify(tree))
        if(query?.trim()?.length > 1){
            const allNodeValues = getAllNodeValues(treeClone, true);
            setExpanded(allNodeValues);
            const searchResults = queryFilterNodes(treeClone, query);
            setSearchNodes(searchResults);
      }
      else{
            setSearchNodes(treeClone);
      }
      setSearching(false)
    }, 800);
    return () => clearTimeout(timer);
  }, [query])

  const handleInputChange = (e) =>{
        setSearching(true)
        setQuery(e.target.value)
    }

    return (
        <div className="flex flex-col hierearchy-container" id="hierarchy-container" >
            <div className="flex justify-center w-full p-2 my-6 ring-1 ring-black ring-opacity-4 rounded-lg">
                <input id="search" className="text-black rounded-md focus:outline-none dark:bg-gray-600 w-full" value={query} onInput={handleInputChange}  type="search" placeholder="Search assets" />
                <svg className="w-4 h-4 text-gray-600 inline mt-1" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" />
                </svg>
            </div>
          {
          !searching ?
          ( searchedNodes.length > 0 ) ?

            <div className="pt-4" >
              <CheckboxTree
                  nodes={searchedNodes.length > 0 ? searchedNodes : assetTree}
                  expanded={expanded}
                  onExpand={expanded => setExpanded(expanded)}
                  expandOnClick
                  onClick={(asset) => {
                      setTreeBranch(asset.value)
                  }}
                  showNodeIcon={false}
                  icons={{
                      expandClose: <span className="chevron right" />,
                      expandOpen: <span className="chevron bottom" />,
                  }}
              /> </div> : <div className="w-full flex justify-center content-center mt-12 text-lg">No results for {query} </div>
              : <div className="w-full flex justify-center content-center">
              <div className="loader mt-4 "></div>

              </div>

              }
        </div>)
}