import { useEffect, useState } from "react";
import { DataStore , Storage} from "aws-amplify";
import { useParams, useNavigate } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue } from "recoil";
import { Fab, Icon, Tabbar, TabbarLink} from "konsta/react";
import { MdAdd, MdDownload} from "react-icons/md"

import { pageRoutes } from "../../../routes/routes";
import { dynamoTables } from "../../../dbTables/syncObjects";
import { globalNavbarOptions, networkState } from "../../State/Global";
import { useNewRecentItems, useSignedInUser } from "../../Hooks/Hooks";

import Spinner from "../../Utils/Loading/Spinner"
import DetailView from "../../Utils/Detail/DetailContent";
import SearchResults from "../../Utils/SearchResults/SearchResults";
import HierarchySearch from "../../Utils/HierarchySearch/HierarchySearch";

import { globalSearchTables } from "../../../dbTables/syncObjects";
import { labels } from "../../../dbTables/labels";

import assetData from "./assetData";
import "./AssetDetail.css"

export default function AssetDetail () {
    const { id } = useParams();
    const [ query, setQuery ] = useState('');
    const [ flattenedData, setFlattenedData ] = useState();
    const [ assetName, setAssetName ] = useState();
    const [ asset, setAsset ] = useState({});
    const [ treeBranch, setTreeBranch ] = useState('');
    const [ dataTree, setDataTree ] = useState([]);
    const [ activeTab, setActiveTab ] = useState('Asset-Details');
    const [ isLoading, setIsLoading ] = useState(true);

    const [ assetFiles, setAssetFiles ] = useState([]);

    const isOnline = useRecoilValue(networkState)

    const setNavbarOptions = useSetRecoilState(globalNavbarOptions);
    const navigate = useNavigate();
    const [ user ] = useSignedInUser();

    useNewRecentItems();

    const workOrderTable = globalSearchTables.filter(searchTable => searchTable.ref === "pffsm__smWork_Order__c");
    const workOrderFieldLabels = labels.pffsm__smWork_Order__c;

    const woNav = pageRoutes.find((route) => route.id === "work-order-page")?.nav;
    const assNav = pageRoutes.find((route) => route.id === "asset-page")?.nav;

    const assTable = dynamoTables.find((table) => table.ref === "pffsm__smEquipment__c");
    const cvTable = dynamoTables.find( table => table.ref === 'ContentVersion' );

    const woSearchFields = workOrderTable.map(x=>{return {...x, fields: [
        "Name",
        "pffsm__Description__c",
        "pffsm__Equip_Description__c",
        "pffsm__Status__c",
        "pffsm__Priority__c",
        "pffsm__Equipment__c"
    ]}});

    const woNotDisplayFields = ["pffsm__Equipment__c"];

    // Create hierarchy for asset view - could potentially include in hierarchy component, and
    // standardize rendering of any hierarchy record.
    const createHierarchy = (list) => {
        let map = {}, node, hierarchy = [], i;
        let assetTagIdList = [];
        list.forEach( (asset, i) =>{
            assetTagIdList.push(asset.sfId__custom);
            map[list[i]['sfId__custom']] = i;
            list[i]['children'] = [];
        });

        for (i = 0; i < list.length; i += 1) {
            // Add additional properties to each node here
            node = list[i];
            node['value'] = node.id;
            node['label'] = `${node?.Name} - ${node?.pffsm__Description__c}`;

            if (node.pffsm__Parent__c && assetTagIdList.includes(node.pffsm__Parent__c)) {
                if(map[node.pffsm__Parent__c]){
                    list[map[node.pffsm__Parent__c]]['children'].push(node);
                }
            } else {
                node['name'] = node.Name
                hierarchy.push(node);
            }
        }

        return hierarchy;
      }

    const iterativeSearch = async (obj, parent, termOrId, expandPaths) => {
        const currentId = obj?.id;
        obj['current'] = (currentId !== termOrId ) ? false : true;
        if(currentId !== termOrId ){
            obj?.children.forEach( (item) =>{
                return iterativeSearch(item, parent, termOrId, expandPaths);
            })
        } else{
            obj['highlight'] = true;
            parent['current'] = true;
        }

        return parent
    }

    useEffect(() =>{
        async function getAssets(){
            let assetTree = [];
            // query all asste tags (to create hierarchy of all assets)
            const assTags = await DataStore.query(assTable.model);
            // copy assets into new array for mutability
            const cpyAss = assTags.map( asset => { return {...asset}});
            // create asset tree
            const tree = createHierarchy(cpyAss);
            // iterate ass trees to find which one the current asset belongs to.
            for(const asset of tree){
                const treeHierarchy = await iterativeSearch(asset, asset, id);
                if(treeHierarchy.current) assetTree.push(treeHierarchy);
            }
            setDataTree(assetTree);
        }

        getAssets();
    }, [])

    useEffect(() => {
        setNavbarOptions({
            title: "Asset Tag Detail",
            page: "asset-detail-page",
            hasLeft: true,
            hasRight: true
          });
    }, []);

    useEffect(() => {
        if (id && user) {
            loadData();
        }
    }, [ id, user ]);

    useEffect(() =>{
        if(treeBranch !== '') {
            navigate(`/asset/detail/${treeBranch}`)
        }
    }, [ treeBranch ])

    const loadData = async() => {
        const asset = await DataStore.query(assTable.model, id);
        const plant = await DataStore.query(assTable.model, asset.plant__localId);
        const parent = await DataStore.query(assTable.model, asset.parent__localId);

        setAssetName(asset.Name);
        setAsset(asset)
        setFlattenedData(assetData(asset, plant, parent, assNav))
        setQuery(asset.sfId__custom);

        let updatedFiles = [];
        const files = await DataStore.query(cvTable.model, a => a.linkedEntity__localId.eq(id));
        for (const file of files) {
            let title = file.Title;
            if (file.fileExtension__custom) {
                title += "." + file.fileExtension__custom;
            }

            const downloadUrl = await Storage.get(user.orgId + "/" + file.sfId__custom, {
                contentDisposition: "attachment;filename=\"" + title + "\""
            });

            updatedFiles.push({ ...file, downloadUrl: downloadUrl })
        }

        setAssetFiles(updatedFiles);

        setIsLoading(false);
    }

    const createNewWorkOrder = () => {
        navigate(`/work-order-new-info?assetId=${asset.sfId__custom}&assetLocalId=${id}&assetName=${assetName}`)
    }

    return (
        <div>
           <Tabbar labels={ false } className="left-0 top-10 fixed bg-gray-500 asset-tabs">
                <TabbarLink
                    active={activeTab === 'Asset-Details'}
                    onClick={() => setActiveTab('Asset-Details')}
                    label="Details"
                />
                <TabbarLink
                    active={activeTab === 'Asset-Hierarchy'}
                    onClick={() => setActiveTab('Asset-Hierarchy')}
                    label="Hierarchy"
                />
                <TabbarLink
                    active={activeTab === 'WO-Related'}
                    onClick={() => setActiveTab('WO-Related')}
                    label="WOs"
                />
                 <TabbarLink
                    active={activeTab === 'Files'}
                    onClick={() => setActiveTab('Files')}
                    label="Files"
                />
            </Tabbar>
            {
                activeTab === 'Asset-Hierarchy' && (
                    <div className="w-full mt-12">
                        <div className="p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                            <HierarchySearch tree={dataTree} asset={asset} setTreeBranch={setTreeBranch}/>
                        </div>
                    </div>)
            }
            {
                activeTab === 'Asset-Details' ?
                    <>
                        { isLoading ?
                            <Spinner /> :
                            <div className="py-1 pl-2 pr-2 h-full mt-4">
                                <Fab className="fixed right-6-safe bottom-18-safe z-20" icon={ <MdAdd /> } onClick={() => createNewWorkOrder()} />
                                <DetailView flattenedObject={ flattenedData } />
                            </div>
                        }
                    </> : null
            }
            {
                activeTab === 'WO-Related' && (
                    <div className="py-1 pl-2 pr-2 h-full mt-4">
                        <div>
                            <SearchResults nav={ woNav} searchFields={woSearchFields} queryString = {query}
                                fieldLabels={workOrderFieldLabels} notDisplayFields={woNotDisplayFields}/>
                        </div>
                    </div>)
            }
            {
                 activeTab === 'Files' && (
                    <div className="py-4 pl-2 pr-2 h-full mt-4">
                            {
                                assetFiles.length === 0 ? <div> no records. </div> :
                                <table className="table-custom">
                                    <tr>
                                        <th className="th-custom">File Name</th>
                                        <th className="th-custom">Download</th>
                                    </tr>
                                    {
                                         assetFiles.map((result, resultIndex) => (
                                            <tr key={resultIndex}>
                                                <td className ="td-custom">{result.Title} </td>
                                                <td className ="td-custom">
                                                    { isOnline ?
                                                        <a href={ result.downloadUrl } className="w-6 h-6" iconOnly>
                                                            <Icon className="w-6 h-6" ios={ <MdDownload size="30" /> } material={ <MdDownload size="30" /> } />
                                                        </a> :
                                                        <p>Not Online</p>
                                                    }
                                                </td>
                                            </tr>
                                        ))
                                    }
                                </table>
                            }
                    </div>
                )
            }
        </div>
    );
 }