import { useState, useEffect } from "react";
import { DataStore, SortDirection } from "aws-amplify"
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Block, List, ListButton, Popup, Page, Navbar, BlockTitle, ListItem, Card } from 'konsta/react';
import { MdDelete } from 'react-icons/md';
import { confirm } from "react-confirm-box";
import { useSetRecoilState } from "recoil";

import { dynamoTables } from '../../../dbTables/syncObjects';
import { getBase64 } from "../../Utils/Util";
import { fileAdded } from "../../State/Global";

import Spinner from "../../Utils/Loading/Spinner"

export default function WorkOrderUpLoadFile(){
    const [searchParams] = useSearchParams();
    const [modalOpen, setModalOpen] = useState(true);

    const woId = searchParams?.get('workOrderId');
    const cvTable = dynamoTables.find( table => table.ref === 'ContentVersion' );
    const localFileTable = dynamoTables.find( table => table.ref === 'sm_file_local' );

    const navigate = useNavigate();
    const setFileAdded = useSetRecoilState(fileAdded);

    const cancelModalPopup = (e) => {
        setModalOpen(false);
        navigate(-1);
    }

    const [ selectedFiles, setSelectedFiles ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ results, setResults ] = useState([]);

    useEffect(() => {
        loadFiles();
    }, []);

    const loadFiles =  async () => {
        const results = await DataStore.query(cvTable.model, wo => wo.linkedEntity__localId.eq(woId), {
            sort: s => s.updatedAt(SortDirection.DESCENDING)
        });

        setResults(results);
        setIsLoading(false);
    }

	const changeHandler = (event) => {
        setSelectedFiles([ ...selectedFiles, ...event.target.files ]);
	};

	const handleSubmission = async() => {
        setIsLoading(true);

        let addedFiles = [];
        for (const file of selectedFiles) {
            const fileResult = await getBase64(file);
            const cvFile = await DataStore.save(new cvTable.model({
                doPush__custom: true,
                sobject__custom: cvTable.ref,
                Title: file.name,
                linkedEntity__localId: woId,
                doFetchFileData__custom: false,
            }));

            await DataStore.save(new localFileTable.model({
                versionData: fileResult,
                linkedFileId: cvFile.id
            }));

            addedFiles.push(cvFile);
        }

        setResults([ ...addedFiles, ...results]);
        setSelectedFiles([]);

        setFileAdded(true);
        setIsLoading(false);
	};

    const handleDelete = async(e, idx) => {
        const deleteAction = await confirm("Are you sure to delete this file?");
        if (!deleteAction) return;

        const file = await DataStore.query(cvTable.model, e);
        const localFiles = await DataStore.query(localFileTable.model, f => f.linkedFileId.eq(file.id));

        const updatedFile = await DataStore.save(cvTable.model.copyOf(file, u => {
            u.doPush__custom = true
        }));

        // Need to set timer or the delete mutation doesn't execute properly.
        setTimeout(() => {
            DataStore.delete(updatedFile);
        }, 5000);

        for (const localFile of localFiles) {
            DataStore.delete(localFile);
        }

        let updatedFileResults = [ ...results ];
        updatedFileResults.splice(idx, 1);
        setResults(updatedFileResults);
    };

    return (
        <>
            { isLoading ? <Spinner /> : null }
            <Popup opened={modalOpen} onBackdropClick={() => setModalOpen(false)} position>
                <Page>
                    <Navbar title="Select upload file" />
                    <Block className="space-y-4">
                        <List inset opened="false">
                            <div>
                                <input type="file" name="file" multiple="multiple" onChange={changeHandler} />
                                { selectedFiles?.length > 0 ?
                                    selectedFiles.map((item) => (
                                        <div>
                                            <p>Filename: {item.name}</p>
                                            <p>Size in bytes: {item.size}</p>
                                        </div>)) :
                                    <p>Select a file to show details</p>
                                }
                            </div>
                        </List>

                        <List inset>
                            <ListButton key="bsubmit" colors={{ text: 'text-red-500', activeBg: 'active:bg-red-500', touchRipple: 'touch-ripple-red-500' }} onClick={() => handleSubmission()}>
                                Submit
                            </ListButton>
                            <ListButton key="bcancel" colors={{ text: 'text-red-500', activeBg: 'active:bg-red-500', touchRipple: 'touch-ripple-red-500' }} onClick={() => cancelModalPopup()}>
                                Cancel
                            </ListButton>
                        </List>
                    </Block>

                    <BlockTitle withBlock={false}>Files Uploaded</BlockTitle>
                    <Card>
                        <List nested className="-m-4">
                            {
                                results.map((result, resultIndex) => (
                                    <ListItem title={result.Title} key={resultIndex} media={<MdDelete/>} onClick={() => handleDelete(result.id, resultIndex)}/>
                                ))
                            }
                        </List>
                    </Card>
                </Page>
            </Popup>
        </>
    )
}