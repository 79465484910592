import { useState } from "react"
import GlobalSearch from "../../Utils/GlobalSearch/GlobalSearch";
import { dynamoTables, globalSearchTables } from "../../../dbTables/syncObjects";
import { useSearchResults } from "../../Hooks/Hooks";
import { Page, Block, BlockTitle, Card } from "konsta/react";
import { useSearchParams, Link } from "react-router-dom";
import CardInfo from "../../Utils/CardInfo/CardInfo";

const searchableProps = globalSearchTables;
export default function Search(props){
    let [searchParams, setSearchParams] = useSearchParams();
    const [searchables, setSearchables] = useSearchResults(searchParams?.get('q') ?? '', 10, 0, searchableProps);
    let [query, setQuery] = useState(searchParams?.get('q') ?? '');

    const searchButtonClick = () => {
        setSearchParams(`q=${query}`);
    }
    const keyInputChange = (e) => {
        if(e.which === 13){
           setSearchParams(`q=${query}`);
          }
      }
      const userInputChange = (e) => {
        setSearchParams(`q=${e?.target?.value}`);
        setQuery(e.target.value);
      }
      const constructPath = (item) => {
        const navConstruct = dynamoTables.find( table => table.ref === item?.sobject__custom)?.nav
        return `${navConstruct}${item?.id}`
      }

      return  <section className="h-full">
              <Page className="mt-2 flex flex-col">
                <Block className="w-full items-center flex flex-col hairline-b p-4 hairline-gray-200">
                  <GlobalSearch query={query} searchButtonClick={searchButtonClick} userInputChange={userInputChange}
                                keyInputChange={keyInputChange}/>
                </Block>
                    <div className="relative hairline-t hairline-gray-200">
                    {searchables && searchables.map( table =>
                        <div key={table.Name} className="relative hairline-b hairline-gray-500 pb-4">
                            <BlockTitle withBlock={false} className="dark:text-gray-400">{table.label}</BlockTitle>
                            <Block>
                            {(table?.results?.length > 0) ? table.results.map( result =>
                                <Link key={result.id} to={constructPath(result)}>
                                    <CardInfo item={result} type={result?.sobject__custom} key={result.id}/>
                                </Link>
                                ) :
                                <div key={table.Name} className="pl-6 pb-2  dark:text-gray-400"> <em>0 results found</em></div>
                            }
                            </Block>
                        </div>
                    )}
                    </div>
            </Page>
        </section>
}