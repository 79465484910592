import { useEffect, useState } from "react";
import { Page, Block } from "konsta/react";
import { useSetRecoilState } from "recoil";

import { globalSearchTables } from "../../../dbTables/syncObjects";
import { labels } from "../../../dbTables/labels";
import { pageRoutes } from "../../../routes/routes";
import { globalNavbarOptions } from "../../State/Global";

import GlobalSearch from "../../Utils/GlobalSearch/GlobalSearch";
import SearchResults from "../../Utils/SearchResults/SearchResults";
import RecentItems from "../../Utils/RecentItems/RecentItems";

export default function AssetHome(){
    const [query, setQuery] = useState('');
    const setNavbarOptions = useSetRecoilState(globalNavbarOptions);

    const assetTable = globalSearchTables.filter(searchTable => searchTable.ref === "pffsm__smEquipment__c");
    const assetFieldLabels = labels.pffsm__smEquipment__c;

    const assNav = pageRoutes.find((route) => route.id === "asset-page")?.nav;
    const assetFields = assetTable.map(ass=>{return {...ass, fields: [
        "Name",
        "pffsm__Description__c",
        "pffsm__Status__c"
    ]}});

    useEffect(() => {
        setNavbarOptions({
            title: "Asset Tags",
            page: "asset-page",
            hasLeft: false,
            hasRight: false
        });
    }, []);

    const userInputChange = (e) => {
        setQuery(e.target.value);
    }

    return (
        <Page className="mt-2">
            <Block className="w-full items-center flex flex-col hairline-b p-4 hairline-gray-200">
                <GlobalSearch query={query} userInputChange={userInputChange} />
            </Block>
            { query.trim().length > 2 ?
                <SearchResults nav={assNav} searchFields={assetFields} queryString={query} fieldLabels={assetFieldLabels}/> :
                <RecentItems type={["pffsm__smEquipment__c"]}/>
            }
        </Page>
    );
}