import moment from "moment";
import WorkAssignmentContent from "./WorkAssignmentContent";

export default function WorkAssignmentCurrent({ currentUserId }) {
    const todayUTC = moment.utc().toISOString();
    let todayStartTime = todayUTC.substring(0,10) + 'T00:00:00.000Z';
    let todayEndTime = todayUTC.substring(0,10) + 'T23:59:59.999Z';

    const queryPredicates = (w => w.and( w => [
        w.pffsm__Assignment_Status__c.eq("Schedule Confirmed"),
        w.pffsm__Assigned_User__c.eq(currentUserId),
        w.pffsm__Scheduled_Start_Time__c.le(todayEndTime),
        w.pffsm__Scheduled_Stop_Time__c.ge(todayStartTime)
    ]));

    return (<WorkAssignmentContent queryPredicates={ queryPredicates }/>);
}