/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateSmWorkOrderc = /* GraphQL */ `
  subscription OnCreateSmWorkOrderc(
    $filter: ModelSubscriptionSmWorkOrdercFilterInput
    $owner: String
  ) {
    onCreateSmWorkOrderc(filter: $filter, owner: $owner) {
      sfId__custom
      doPush__custom
      sobject__custom
      syncToOffline__custom
      localDeleteOnly__custom
      owner
      id
      Name
      OwnerId
      OwnerName
      RecordTypeId
      RecordTypeName
      CreatedDate
      CreatedById
      CreatedByName
      LastModifiedDate
      LastModifiedById
      LastModifiedByName
      pffsm__Equipment__c
      equipment__localId
      pffsm__Action_Code__c
      pffsm__Actual_Start_Date__c
      pffsm__Asset_ID_Text__c
      pffsm__Cause_Code__c
      pffsm__City__c
      pffsm__Completed_Date_Time__c
      pffsm__Completion_Date__c
      pffsm__Completion_Notes__c
      pffsm__Delay_Code_Due_Date__c
      pffsm__Delay_Notes__c
      pffsm__Delay_SubType__c
      pffsm__Delay_Type__c
      pffsm__Description__c
      pffsm__Detailed_Comments__c
      pffsm__Due_Date__c
      pffsm__Energy_Source__c
      pffsm__Equip_Description__c
      pffsm__Event_Duration__c
      pffsm__Event_End__c
      pffsm__Event_Start__c
      pffsm__Failure_Code__c
      pffsm__Gate_Combo_Codes__c
      pffsm__Initial_Est_Hours__c
      pffsm__Parent_Work_Order__c
      parentWorkOrder__localId
      pffsm__Priority__c
      pffsm__Site_Documentation__c
      pffsm__Site_Instructions__c
      pffsm__State_Province__c
      pffsm__Status__c
      pffsm__Street_Address__c
      pffsm__System_Size_kW_DC__c
      pffsm__WO_Type__c
      pffsm__Sub_Type__c
      pffsm__Zip_Postal_Code__c
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSmWorkOrderc = /* GraphQL */ `
  subscription OnUpdateSmWorkOrderc(
    $filter: ModelSubscriptionSmWorkOrdercFilterInput
    $owner: String
  ) {
    onUpdateSmWorkOrderc(filter: $filter, owner: $owner) {
      sfId__custom
      doPush__custom
      sobject__custom
      syncToOffline__custom
      localDeleteOnly__custom
      owner
      id
      Name
      OwnerId
      OwnerName
      RecordTypeId
      RecordTypeName
      CreatedDate
      CreatedById
      CreatedByName
      LastModifiedDate
      LastModifiedById
      LastModifiedByName
      pffsm__Equipment__c
      equipment__localId
      pffsm__Action_Code__c
      pffsm__Actual_Start_Date__c
      pffsm__Asset_ID_Text__c
      pffsm__Cause_Code__c
      pffsm__City__c
      pffsm__Completed_Date_Time__c
      pffsm__Completion_Date__c
      pffsm__Completion_Notes__c
      pffsm__Delay_Code_Due_Date__c
      pffsm__Delay_Notes__c
      pffsm__Delay_SubType__c
      pffsm__Delay_Type__c
      pffsm__Description__c
      pffsm__Detailed_Comments__c
      pffsm__Due_Date__c
      pffsm__Energy_Source__c
      pffsm__Equip_Description__c
      pffsm__Event_Duration__c
      pffsm__Event_End__c
      pffsm__Event_Start__c
      pffsm__Failure_Code__c
      pffsm__Gate_Combo_Codes__c
      pffsm__Initial_Est_Hours__c
      pffsm__Parent_Work_Order__c
      parentWorkOrder__localId
      pffsm__Priority__c
      pffsm__Site_Documentation__c
      pffsm__Site_Instructions__c
      pffsm__State_Province__c
      pffsm__Status__c
      pffsm__Street_Address__c
      pffsm__System_Size_kW_DC__c
      pffsm__WO_Type__c
      pffsm__Sub_Type__c
      pffsm__Zip_Postal_Code__c
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSmWorkOrderc = /* GraphQL */ `
  subscription OnDeleteSmWorkOrderc(
    $filter: ModelSubscriptionSmWorkOrdercFilterInput
    $owner: String
  ) {
    onDeleteSmWorkOrderc(filter: $filter, owner: $owner) {
      sfId__custom
      doPush__custom
      sobject__custom
      syncToOffline__custom
      localDeleteOnly__custom
      owner
      id
      Name
      OwnerId
      OwnerName
      RecordTypeId
      RecordTypeName
      CreatedDate
      CreatedById
      CreatedByName
      LastModifiedDate
      LastModifiedById
      LastModifiedByName
      pffsm__Equipment__c
      equipment__localId
      pffsm__Action_Code__c
      pffsm__Actual_Start_Date__c
      pffsm__Asset_ID_Text__c
      pffsm__Cause_Code__c
      pffsm__City__c
      pffsm__Completed_Date_Time__c
      pffsm__Completion_Date__c
      pffsm__Completion_Notes__c
      pffsm__Delay_Code_Due_Date__c
      pffsm__Delay_Notes__c
      pffsm__Delay_SubType__c
      pffsm__Delay_Type__c
      pffsm__Description__c
      pffsm__Detailed_Comments__c
      pffsm__Due_Date__c
      pffsm__Energy_Source__c
      pffsm__Equip_Description__c
      pffsm__Event_Duration__c
      pffsm__Event_End__c
      pffsm__Event_Start__c
      pffsm__Failure_Code__c
      pffsm__Gate_Combo_Codes__c
      pffsm__Initial_Est_Hours__c
      pffsm__Parent_Work_Order__c
      parentWorkOrder__localId
      pffsm__Priority__c
      pffsm__Site_Documentation__c
      pffsm__Site_Instructions__c
      pffsm__State_Province__c
      pffsm__Status__c
      pffsm__Street_Address__c
      pffsm__System_Size_kW_DC__c
      pffsm__WO_Type__c
      pffsm__Sub_Type__c
      pffsm__Zip_Postal_Code__c
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSmAssetTagc = /* GraphQL */ `
  subscription OnCreateSmAssetTagc(
    $filter: ModelSubscriptionSmAssetTagcFilterInput
    $owner: String
  ) {
    onCreateSmAssetTagc(filter: $filter, owner: $owner) {
      sfId__custom
      doPush__custom
      sobject__custom
      localDeleteOnly__custom
      owner
      id
      Name
      OwnerId
      OwnerName
      RecordTypeId
      RecordTypeName
      CreatedDate
      CreatedById
      CreatedByName
      LastModifiedDate
      LastModifiedById
      LastModifiedByName
      siteInstructions__custom
      gateComboCodes__custom
      siteDocumentation__custom
      systemSize__custom
      pffsm__City__c
      pffsm__State_Province__c
      pffsm__Zip_Postal_Code__c
      pffsm__Country__c
      pffsm__Street__c
      pffsm__Latitude__c
      pffsm__Longitude__c
      pffsm__Status__c
      pffsm__Warranty_Expiration_Date__c
      pffsm__Warranty__c
      pffsm__Warranty_Start_Date__c
      pffsm__Mfg_Date__c
      pffsm__Serial_Number__c
      pffsm__Model_Number__c
      pffsm__Manufacturer_Name__c
      pffsm__AC_Capacity__c
      pffsm__DC_Capacity__c
      pffsm__Plant__c
      pffsm__PlantName__c
      plant__localId
      pffsm__Asset_SubClass__c
      pffsm__Asset_Class__c
      pffsm__Description__c
      pffsm__Parent_ID_Text__c
      pffsm__Parent__c
      parent__localId
      pffsm__Sync_To_Offline__c
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSmAssetTagc = /* GraphQL */ `
  subscription OnUpdateSmAssetTagc(
    $filter: ModelSubscriptionSmAssetTagcFilterInput
    $owner: String
  ) {
    onUpdateSmAssetTagc(filter: $filter, owner: $owner) {
      sfId__custom
      doPush__custom
      sobject__custom
      localDeleteOnly__custom
      owner
      id
      Name
      OwnerId
      OwnerName
      RecordTypeId
      RecordTypeName
      CreatedDate
      CreatedById
      CreatedByName
      LastModifiedDate
      LastModifiedById
      LastModifiedByName
      siteInstructions__custom
      gateComboCodes__custom
      siteDocumentation__custom
      systemSize__custom
      pffsm__City__c
      pffsm__State_Province__c
      pffsm__Zip_Postal_Code__c
      pffsm__Country__c
      pffsm__Street__c
      pffsm__Latitude__c
      pffsm__Longitude__c
      pffsm__Status__c
      pffsm__Warranty_Expiration_Date__c
      pffsm__Warranty__c
      pffsm__Warranty_Start_Date__c
      pffsm__Mfg_Date__c
      pffsm__Serial_Number__c
      pffsm__Model_Number__c
      pffsm__Manufacturer_Name__c
      pffsm__AC_Capacity__c
      pffsm__DC_Capacity__c
      pffsm__Plant__c
      pffsm__PlantName__c
      plant__localId
      pffsm__Asset_SubClass__c
      pffsm__Asset_Class__c
      pffsm__Description__c
      pffsm__Parent_ID_Text__c
      pffsm__Parent__c
      parent__localId
      pffsm__Sync_To_Offline__c
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSmAssetTagc = /* GraphQL */ `
  subscription OnDeleteSmAssetTagc(
    $filter: ModelSubscriptionSmAssetTagcFilterInput
    $owner: String
  ) {
    onDeleteSmAssetTagc(filter: $filter, owner: $owner) {
      sfId__custom
      doPush__custom
      sobject__custom
      localDeleteOnly__custom
      owner
      id
      Name
      OwnerId
      OwnerName
      RecordTypeId
      RecordTypeName
      CreatedDate
      CreatedById
      CreatedByName
      LastModifiedDate
      LastModifiedById
      LastModifiedByName
      siteInstructions__custom
      gateComboCodes__custom
      siteDocumentation__custom
      systemSize__custom
      pffsm__City__c
      pffsm__State_Province__c
      pffsm__Zip_Postal_Code__c
      pffsm__Country__c
      pffsm__Street__c
      pffsm__Latitude__c
      pffsm__Longitude__c
      pffsm__Status__c
      pffsm__Warranty_Expiration_Date__c
      pffsm__Warranty__c
      pffsm__Warranty_Start_Date__c
      pffsm__Mfg_Date__c
      pffsm__Serial_Number__c
      pffsm__Model_Number__c
      pffsm__Manufacturer_Name__c
      pffsm__AC_Capacity__c
      pffsm__DC_Capacity__c
      pffsm__Plant__c
      pffsm__PlantName__c
      plant__localId
      pffsm__Asset_SubClass__c
      pffsm__Asset_Class__c
      pffsm__Description__c
      pffsm__Parent_ID_Text__c
      pffsm__Parent__c
      parent__localId
      pffsm__Sync_To_Offline__c
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSmWorkTaskc = /* GraphQL */ `
  subscription OnCreateSmWorkTaskc(
    $filter: ModelSubscriptionSmWorkTaskcFilterInput
    $owner: String
  ) {
    onCreateSmWorkTaskc(filter: $filter, owner: $owner) {
      sfId__custom
      doPush__custom
      sobject__custom
      syncToOffline__custom
      localDeleteOnly__custom
      owner
      id
      Name
      CreatedDate
      CreatedById
      CreatedByName
      LastModifiedDate
      LastModifiedById
      LastModifiedByName
      pffsm__WO_Description__c
      pffsm__Comments__c
      pffsm__Asset_Description__c
      pffsm__Planned_Hours__c
      pffsm__Due_Date__c
      pffsm__Std_Task__c
      StdTaskName
      pffsm__Craft__c
      craftName__custom
      pffsm__Percent_Complete__c
      pffsm__People_Required__c
      pffsm__Instructions__c
      pffsm__Task_Due_Date__c
      pffsm__Task_steps_completed__c
      pffsm__Asset_ID__c
      pffsm__Priority__c
      pffsm__Schedule_Date__c
      pffsm__Step__c
      pffsm__Planned_Hrs_Remaining__c
      pffsm__Create_Date__c
      pffsm__Work_Order__c
      workorder__localId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSmWorkTaskc = /* GraphQL */ `
  subscription OnUpdateSmWorkTaskc(
    $filter: ModelSubscriptionSmWorkTaskcFilterInput
    $owner: String
  ) {
    onUpdateSmWorkTaskc(filter: $filter, owner: $owner) {
      sfId__custom
      doPush__custom
      sobject__custom
      syncToOffline__custom
      localDeleteOnly__custom
      owner
      id
      Name
      CreatedDate
      CreatedById
      CreatedByName
      LastModifiedDate
      LastModifiedById
      LastModifiedByName
      pffsm__WO_Description__c
      pffsm__Comments__c
      pffsm__Asset_Description__c
      pffsm__Planned_Hours__c
      pffsm__Due_Date__c
      pffsm__Std_Task__c
      StdTaskName
      pffsm__Craft__c
      craftName__custom
      pffsm__Percent_Complete__c
      pffsm__People_Required__c
      pffsm__Instructions__c
      pffsm__Task_Due_Date__c
      pffsm__Task_steps_completed__c
      pffsm__Asset_ID__c
      pffsm__Priority__c
      pffsm__Schedule_Date__c
      pffsm__Step__c
      pffsm__Planned_Hrs_Remaining__c
      pffsm__Create_Date__c
      pffsm__Work_Order__c
      workorder__localId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSmWorkTaskc = /* GraphQL */ `
  subscription OnDeleteSmWorkTaskc(
    $filter: ModelSubscriptionSmWorkTaskcFilterInput
    $owner: String
  ) {
    onDeleteSmWorkTaskc(filter: $filter, owner: $owner) {
      sfId__custom
      doPush__custom
      sobject__custom
      syncToOffline__custom
      localDeleteOnly__custom
      owner
      id
      Name
      CreatedDate
      CreatedById
      CreatedByName
      LastModifiedDate
      LastModifiedById
      LastModifiedByName
      pffsm__WO_Description__c
      pffsm__Comments__c
      pffsm__Asset_Description__c
      pffsm__Planned_Hours__c
      pffsm__Due_Date__c
      pffsm__Std_Task__c
      StdTaskName
      pffsm__Craft__c
      craftName__custom
      pffsm__Percent_Complete__c
      pffsm__People_Required__c
      pffsm__Instructions__c
      pffsm__Task_Due_Date__c
      pffsm__Task_steps_completed__c
      pffsm__Asset_ID__c
      pffsm__Priority__c
      pffsm__Schedule_Date__c
      pffsm__Step__c
      pffsm__Planned_Hrs_Remaining__c
      pffsm__Create_Date__c
      pffsm__Work_Order__c
      workorder__localId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSmWorkTaskStepc = /* GraphQL */ `
  subscription OnCreateSmWorkTaskStepc(
    $filter: ModelSubscriptionSmWorkTaskStepcFilterInput
    $owner: String
  ) {
    onCreateSmWorkTaskStepc(filter: $filter, owner: $owner) {
      sfId__custom
      doPush__custom
      sobject__custom
      syncToOffline__custom
      localDeleteOnly__custom
      owner
      id
      Name
      CreatedDate
      CreatedById
      CreatedByName
      LastModifiedDate
      LastModifiedById
      LastModifiedByName
      pffsm__Additional_Details__c
      pffsm__Comments__c
      pffsm__Complete__c
      pffsm__Completion_Variance__c
      pffsm__User_Picklist__c
      pffsm__Sequence__c
      pffsm__Status__c
      pffsm__Track_Complete_Time__c
      pffsm__User_Text__c
      pffsm__Value__c
      pffsm__Assembly__c
      pffsm__Sub_Assembly__c
      pffsm__Critical_Inspection__c
      pffsm__Work_Task__c
      workTask__localId
      workOrderSFId__custom
      workorder__localId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSmWorkTaskStepc = /* GraphQL */ `
  subscription OnUpdateSmWorkTaskStepc(
    $filter: ModelSubscriptionSmWorkTaskStepcFilterInput
    $owner: String
  ) {
    onUpdateSmWorkTaskStepc(filter: $filter, owner: $owner) {
      sfId__custom
      doPush__custom
      sobject__custom
      syncToOffline__custom
      localDeleteOnly__custom
      owner
      id
      Name
      CreatedDate
      CreatedById
      CreatedByName
      LastModifiedDate
      LastModifiedById
      LastModifiedByName
      pffsm__Additional_Details__c
      pffsm__Comments__c
      pffsm__Complete__c
      pffsm__Completion_Variance__c
      pffsm__User_Picklist__c
      pffsm__Sequence__c
      pffsm__Status__c
      pffsm__Track_Complete_Time__c
      pffsm__User_Text__c
      pffsm__Value__c
      pffsm__Assembly__c
      pffsm__Sub_Assembly__c
      pffsm__Critical_Inspection__c
      pffsm__Work_Task__c
      workTask__localId
      workOrderSFId__custom
      workorder__localId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSmWorkTaskStepc = /* GraphQL */ `
  subscription OnDeleteSmWorkTaskStepc(
    $filter: ModelSubscriptionSmWorkTaskStepcFilterInput
    $owner: String
  ) {
    onDeleteSmWorkTaskStepc(filter: $filter, owner: $owner) {
      sfId__custom
      doPush__custom
      sobject__custom
      syncToOffline__custom
      localDeleteOnly__custom
      owner
      id
      Name
      CreatedDate
      CreatedById
      CreatedByName
      LastModifiedDate
      LastModifiedById
      LastModifiedByName
      pffsm__Additional_Details__c
      pffsm__Comments__c
      pffsm__Complete__c
      pffsm__Completion_Variance__c
      pffsm__User_Picklist__c
      pffsm__Sequence__c
      pffsm__Status__c
      pffsm__Track_Complete_Time__c
      pffsm__User_Text__c
      pffsm__Value__c
      pffsm__Assembly__c
      pffsm__Sub_Assembly__c
      pffsm__Critical_Inspection__c
      pffsm__Work_Task__c
      workTask__localId
      workOrderSFId__custom
      workorder__localId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSmOperationalEventc = /* GraphQL */ `
  subscription OnCreateSmOperationalEventc(
    $filter: ModelSubscriptionSmOperationalEventcFilterInput
    $owner: String
  ) {
    onCreateSmOperationalEventc(filter: $filter, owner: $owner) {
      sfId__custom
      doPush__custom
      sobject__custom
      syncToOffline__custom
      localDeleteOnly__custom
      owner
      id
      Name
      CreatedDate
      CreatedById
      CreatedByName
      LastModifiedDate
      LastModifiedById
      LastModifiedByName
      pffsm__Event_Type__c
      pffsm__Acknowledged_Time__c
      pffsm__Acknowledged_By_Name__c
      pffsm__Work_Order__c
      workorder__localId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSmOperationalEventc = /* GraphQL */ `
  subscription OnUpdateSmOperationalEventc(
    $filter: ModelSubscriptionSmOperationalEventcFilterInput
    $owner: String
  ) {
    onUpdateSmOperationalEventc(filter: $filter, owner: $owner) {
      sfId__custom
      doPush__custom
      sobject__custom
      syncToOffline__custom
      localDeleteOnly__custom
      owner
      id
      Name
      CreatedDate
      CreatedById
      CreatedByName
      LastModifiedDate
      LastModifiedById
      LastModifiedByName
      pffsm__Event_Type__c
      pffsm__Acknowledged_Time__c
      pffsm__Acknowledged_By_Name__c
      pffsm__Work_Order__c
      workorder__localId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSmOperationalEventc = /* GraphQL */ `
  subscription OnDeleteSmOperationalEventc(
    $filter: ModelSubscriptionSmOperationalEventcFilterInput
    $owner: String
  ) {
    onDeleteSmOperationalEventc(filter: $filter, owner: $owner) {
      sfId__custom
      doPush__custom
      sobject__custom
      syncToOffline__custom
      localDeleteOnly__custom
      owner
      id
      Name
      CreatedDate
      CreatedById
      CreatedByName
      LastModifiedDate
      LastModifiedById
      LastModifiedByName
      pffsm__Event_Type__c
      pffsm__Acknowledged_Time__c
      pffsm__Acknowledged_By_Name__c
      pffsm__Work_Order__c
      workorder__localId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSmWorkAssignmentc = /* GraphQL */ `
  subscription OnCreateSmWorkAssignmentc(
    $filter: ModelSubscriptionSmWorkAssignmentcFilterInput
    $owner: String
  ) {
    onCreateSmWorkAssignmentc(filter: $filter, owner: $owner) {
      sfId__custom
      doPush__custom
      sobject__custom
      syncToOffline__custom
      localDeleteOnly__custom
      owner
      id
      Name
      OwnerId
      OwnerName
      RecordTypeId
      RecordTypeName
      CreatedDate
      CreatedById
      CreatedByName
      LastModifiedDate
      LastModifiedById
      LastModifiedByName
      pffsm__Assigned_User__c
      AssignedUserName
      pffsm__Work_Order__c
      workorder__localId
      pffsm__WO_Task__c
      woTask__localId
      pffsm__Assignment_Due_Date__c
      pffsm__Assignment_Schedule_Date__c
      pffsm__Assignment_Status__c
      pffsm__Current_Assignment__c
      pffsm__Plant_Description__c
      pffsm__Plant_Name__c
      pffsm__Scheduled_Start_Time__c
      pffsm__Scheduled_Stop_Time__c
      pffsm__WO_Description__c
      pffsm__WO_Task_Description__c
      pffsm__WO_Task_Due_Date__c
      pffsm__WO_Task_Schedule_Date__c
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSmWorkAssignmentc = /* GraphQL */ `
  subscription OnUpdateSmWorkAssignmentc(
    $filter: ModelSubscriptionSmWorkAssignmentcFilterInput
    $owner: String
  ) {
    onUpdateSmWorkAssignmentc(filter: $filter, owner: $owner) {
      sfId__custom
      doPush__custom
      sobject__custom
      syncToOffline__custom
      localDeleteOnly__custom
      owner
      id
      Name
      OwnerId
      OwnerName
      RecordTypeId
      RecordTypeName
      CreatedDate
      CreatedById
      CreatedByName
      LastModifiedDate
      LastModifiedById
      LastModifiedByName
      pffsm__Assigned_User__c
      AssignedUserName
      pffsm__Work_Order__c
      workorder__localId
      pffsm__WO_Task__c
      woTask__localId
      pffsm__Assignment_Due_Date__c
      pffsm__Assignment_Schedule_Date__c
      pffsm__Assignment_Status__c
      pffsm__Current_Assignment__c
      pffsm__Plant_Description__c
      pffsm__Plant_Name__c
      pffsm__Scheduled_Start_Time__c
      pffsm__Scheduled_Stop_Time__c
      pffsm__WO_Description__c
      pffsm__WO_Task_Description__c
      pffsm__WO_Task_Due_Date__c
      pffsm__WO_Task_Schedule_Date__c
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSmWorkAssignmentc = /* GraphQL */ `
  subscription OnDeleteSmWorkAssignmentc(
    $filter: ModelSubscriptionSmWorkAssignmentcFilterInput
    $owner: String
  ) {
    onDeleteSmWorkAssignmentc(filter: $filter, owner: $owner) {
      sfId__custom
      doPush__custom
      sobject__custom
      syncToOffline__custom
      localDeleteOnly__custom
      owner
      id
      Name
      OwnerId
      OwnerName
      RecordTypeId
      RecordTypeName
      CreatedDate
      CreatedById
      CreatedByName
      LastModifiedDate
      LastModifiedById
      LastModifiedByName
      pffsm__Assigned_User__c
      AssignedUserName
      pffsm__Work_Order__c
      workorder__localId
      pffsm__WO_Task__c
      woTask__localId
      pffsm__Assignment_Due_Date__c
      pffsm__Assignment_Schedule_Date__c
      pffsm__Assignment_Status__c
      pffsm__Current_Assignment__c
      pffsm__Plant_Description__c
      pffsm__Plant_Name__c
      pffsm__Scheduled_Start_Time__c
      pffsm__Scheduled_Stop_Time__c
      pffsm__WO_Description__c
      pffsm__WO_Task_Description__c
      pffsm__WO_Task_Due_Date__c
      pffsm__WO_Task_Schedule_Date__c
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSmFile = /* GraphQL */ `
  subscription OnCreateSmFile(
    $filter: ModelSubscriptionSmFileFilterInput
    $owner: String
  ) {
    onCreateSmFile(filter: $filter, owner: $owner) {
      sfId__custom
      doPush__custom
      sobject__custom
      syncToOffline__custom
      localDeleteOnly__custom
      owner
      id
      Title
      Description
      VersionData
      LinkedEntityId
      linkedEntity__localId
      latestVersionId__custom
      fileExtension__custom
      doFetchFileData__custom
      isNew__custom
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSmFile = /* GraphQL */ `
  subscription OnUpdateSmFile(
    $filter: ModelSubscriptionSmFileFilterInput
    $owner: String
  ) {
    onUpdateSmFile(filter: $filter, owner: $owner) {
      sfId__custom
      doPush__custom
      sobject__custom
      syncToOffline__custom
      localDeleteOnly__custom
      owner
      id
      Title
      Description
      VersionData
      LinkedEntityId
      linkedEntity__localId
      latestVersionId__custom
      fileExtension__custom
      doFetchFileData__custom
      isNew__custom
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSmFile = /* GraphQL */ `
  subscription OnDeleteSmFile(
    $filter: ModelSubscriptionSmFileFilterInput
    $owner: String
  ) {
    onDeleteSmFile(filter: $filter, owner: $owner) {
      sfId__custom
      doPush__custom
      sobject__custom
      syncToOffline__custom
      localDeleteOnly__custom
      owner
      id
      Title
      Description
      VersionData
      LinkedEntityId
      linkedEntity__localId
      latestVersionId__custom
      fileExtension__custom
      doFetchFileData__custom
      isNew__custom
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSmTimeEntryc = /* GraphQL */ `
  subscription OnCreateSmTimeEntryc(
    $filter: ModelSubscriptionSmTimeEntrycFilterInput
    $owner: String
  ) {
    onCreateSmTimeEntryc(filter: $filter, owner: $owner) {
      sfId__custom
      doPush__custom
      sobject__custom
      syncToOffline__custom
      localDeleteOnly__custom
      owner
      id
      Name
      OwnerId
      OwnerName
      CreatedDate
      CreatedById
      CreatedByName
      LastModifiedDate
      LastModifiedById
      LastModifiedByName
      pffsm__Is_Cancelled__c
      pffsm__Comments__c
      pffsm__Created_By_Manager__c
      pffsm__Daily_Time_Sheet__c
      timesheet__localId
      pffsm__Doubletime_Hours__c
      pffsm__Duration_h_min__c
      pffsm__Duration_h__c
      pffsm__End_Time__c
      pffsm__Flag_Timesheet__c
      pffsm__HoursWorked__c
      pffsm__Overtime_Hours__c
      pffsm__Plant_State__c
      pffsm__Regular_Hours__c
      pffsm__Start_Time__c
      pffsm__Time_Sheet_Status__c
      pffsm__Time_Type__c
      pffsm__Total_Hours__c
      pffsm__User__c
      userName__custom
      pffsm__WO_Assignment__c
      workAssignment__localId
      pffsm__Work_Order__c
      workorder__localId
      pffsm__Work_Date__c
      pffsm__Work_Order_Description__c
      pffsm__Work_Task_Description__c
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSmTimeEntryc = /* GraphQL */ `
  subscription OnUpdateSmTimeEntryc(
    $filter: ModelSubscriptionSmTimeEntrycFilterInput
    $owner: String
  ) {
    onUpdateSmTimeEntryc(filter: $filter, owner: $owner) {
      sfId__custom
      doPush__custom
      sobject__custom
      syncToOffline__custom
      localDeleteOnly__custom
      owner
      id
      Name
      OwnerId
      OwnerName
      CreatedDate
      CreatedById
      CreatedByName
      LastModifiedDate
      LastModifiedById
      LastModifiedByName
      pffsm__Is_Cancelled__c
      pffsm__Comments__c
      pffsm__Created_By_Manager__c
      pffsm__Daily_Time_Sheet__c
      timesheet__localId
      pffsm__Doubletime_Hours__c
      pffsm__Duration_h_min__c
      pffsm__Duration_h__c
      pffsm__End_Time__c
      pffsm__Flag_Timesheet__c
      pffsm__HoursWorked__c
      pffsm__Overtime_Hours__c
      pffsm__Plant_State__c
      pffsm__Regular_Hours__c
      pffsm__Start_Time__c
      pffsm__Time_Sheet_Status__c
      pffsm__Time_Type__c
      pffsm__Total_Hours__c
      pffsm__User__c
      userName__custom
      pffsm__WO_Assignment__c
      workAssignment__localId
      pffsm__Work_Order__c
      workorder__localId
      pffsm__Work_Date__c
      pffsm__Work_Order_Description__c
      pffsm__Work_Task_Description__c
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSmTimeEntryc = /* GraphQL */ `
  subscription OnDeleteSmTimeEntryc(
    $filter: ModelSubscriptionSmTimeEntrycFilterInput
    $owner: String
  ) {
    onDeleteSmTimeEntryc(filter: $filter, owner: $owner) {
      sfId__custom
      doPush__custom
      sobject__custom
      syncToOffline__custom
      localDeleteOnly__custom
      owner
      id
      Name
      OwnerId
      OwnerName
      CreatedDate
      CreatedById
      CreatedByName
      LastModifiedDate
      LastModifiedById
      LastModifiedByName
      pffsm__Is_Cancelled__c
      pffsm__Comments__c
      pffsm__Created_By_Manager__c
      pffsm__Daily_Time_Sheet__c
      timesheet__localId
      pffsm__Doubletime_Hours__c
      pffsm__Duration_h_min__c
      pffsm__Duration_h__c
      pffsm__End_Time__c
      pffsm__Flag_Timesheet__c
      pffsm__HoursWorked__c
      pffsm__Overtime_Hours__c
      pffsm__Plant_State__c
      pffsm__Regular_Hours__c
      pffsm__Start_Time__c
      pffsm__Time_Sheet_Status__c
      pffsm__Time_Type__c
      pffsm__Total_Hours__c
      pffsm__User__c
      userName__custom
      pffsm__WO_Assignment__c
      workAssignment__localId
      pffsm__Work_Order__c
      workorder__localId
      pffsm__Work_Date__c
      pffsm__Work_Order_Description__c
      pffsm__Work_Task_Description__c
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSmDailyTimeSheetc = /* GraphQL */ `
  subscription OnCreateSmDailyTimeSheetc(
    $filter: ModelSubscriptionSmDailyTimeSheetcFilterInput
    $owner: String
  ) {
    onCreateSmDailyTimeSheetc(filter: $filter, owner: $owner) {
      sfId__custom
      doPush__custom
      sobject__custom
      owner
      id
      Name
      OwnerId
      OwnerName
      CreatedDate
      CreatedById
      CreatedByName
      LastModifiedDate
      LastModifiedById
      LastModifiedByName
      pffsm__Am_I_the_approver__c
      pffsm__Approver_SFDC_ID__c
      pffsm__AutoClockOut__c
      pffsm__Clock_In_Time__c
      pffsm__Duration_h_min__c
      pffsm__Duration_h__c
      pffsm__Clock_Out_Time__c
      pffsm__Comment__c
      pffsm__Created_By_Manager__c
      pffsm__Email__c
      pffsm__Email_Auto_Approval_Status__c
      pffsm__Flag_Timesheet__c
      pffsm__Latest_Time_Entry__c
      pffsm__My_Time_Sheet_Approver__c
      pffsm__My_TimeSheet_Reviewer__c
      pffsm__Night_Shift__c
      pffsm__Overtime_Hours_Total__c
      pffsm__Per_Diem__c
      pffsm__Regular_Hours_Total__c
      pffsm__Reviewer__c
      reviewerName__custom
      pffsm__Status__c
      pffsm__Time_Entry_Variance__c
      pffsm__Time_Sheet_Approver__c
      pffsm__Time_Tracker_Approver__c
      timeTrackerApproverName__custom
      pffsm__TimeSheet_Auto_Clocked_Out__c
      pffsm__Timesheet_Date__c
      pffsm__Total_Time_Entry_Hours_h__c
      pffsm__User__c
      userName__custom
      pffsm__Work_Date__c
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSmDailyTimeSheetc = /* GraphQL */ `
  subscription OnUpdateSmDailyTimeSheetc(
    $filter: ModelSubscriptionSmDailyTimeSheetcFilterInput
    $owner: String
  ) {
    onUpdateSmDailyTimeSheetc(filter: $filter, owner: $owner) {
      sfId__custom
      doPush__custom
      sobject__custom
      owner
      id
      Name
      OwnerId
      OwnerName
      CreatedDate
      CreatedById
      CreatedByName
      LastModifiedDate
      LastModifiedById
      LastModifiedByName
      pffsm__Am_I_the_approver__c
      pffsm__Approver_SFDC_ID__c
      pffsm__AutoClockOut__c
      pffsm__Clock_In_Time__c
      pffsm__Duration_h_min__c
      pffsm__Duration_h__c
      pffsm__Clock_Out_Time__c
      pffsm__Comment__c
      pffsm__Created_By_Manager__c
      pffsm__Email__c
      pffsm__Email_Auto_Approval_Status__c
      pffsm__Flag_Timesheet__c
      pffsm__Latest_Time_Entry__c
      pffsm__My_Time_Sheet_Approver__c
      pffsm__My_TimeSheet_Reviewer__c
      pffsm__Night_Shift__c
      pffsm__Overtime_Hours_Total__c
      pffsm__Per_Diem__c
      pffsm__Regular_Hours_Total__c
      pffsm__Reviewer__c
      reviewerName__custom
      pffsm__Status__c
      pffsm__Time_Entry_Variance__c
      pffsm__Time_Sheet_Approver__c
      pffsm__Time_Tracker_Approver__c
      timeTrackerApproverName__custom
      pffsm__TimeSheet_Auto_Clocked_Out__c
      pffsm__Timesheet_Date__c
      pffsm__Total_Time_Entry_Hours_h__c
      pffsm__User__c
      userName__custom
      pffsm__Work_Date__c
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSmDailyTimeSheetc = /* GraphQL */ `
  subscription OnDeleteSmDailyTimeSheetc(
    $filter: ModelSubscriptionSmDailyTimeSheetcFilterInput
    $owner: String
  ) {
    onDeleteSmDailyTimeSheetc(filter: $filter, owner: $owner) {
      sfId__custom
      doPush__custom
      sobject__custom
      owner
      id
      Name
      OwnerId
      OwnerName
      CreatedDate
      CreatedById
      CreatedByName
      LastModifiedDate
      LastModifiedById
      LastModifiedByName
      pffsm__Am_I_the_approver__c
      pffsm__Approver_SFDC_ID__c
      pffsm__AutoClockOut__c
      pffsm__Clock_In_Time__c
      pffsm__Duration_h_min__c
      pffsm__Duration_h__c
      pffsm__Clock_Out_Time__c
      pffsm__Comment__c
      pffsm__Created_By_Manager__c
      pffsm__Email__c
      pffsm__Email_Auto_Approval_Status__c
      pffsm__Flag_Timesheet__c
      pffsm__Latest_Time_Entry__c
      pffsm__My_Time_Sheet_Approver__c
      pffsm__My_TimeSheet_Reviewer__c
      pffsm__Night_Shift__c
      pffsm__Overtime_Hours_Total__c
      pffsm__Per_Diem__c
      pffsm__Regular_Hours_Total__c
      pffsm__Reviewer__c
      reviewerName__custom
      pffsm__Status__c
      pffsm__Time_Entry_Variance__c
      pffsm__Time_Sheet_Approver__c
      pffsm__Time_Tracker_Approver__c
      timeTrackerApproverName__custom
      pffsm__TimeSheet_Auto_Clocked_Out__c
      pffsm__Timesheet_Date__c
      pffsm__Total_Time_Entry_Hours_h__c
      pffsm__User__c
      userName__custom
      pffsm__Work_Date__c
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateSmRecentItems = /* GraphQL */ `
  subscription OnCreateSmRecentItems(
    $filter: ModelSubscriptionSmRecentItemsFilterInput
    $owner: String
  ) {
    onCreateSmRecentItems(filter: $filter, owner: $owner) {
      id
      items
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateSmRecentItems = /* GraphQL */ `
  subscription OnUpdateSmRecentItems(
    $filter: ModelSubscriptionSmRecentItemsFilterInput
    $owner: String
  ) {
    onUpdateSmRecentItems(filter: $filter, owner: $owner) {
      id
      items
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteSmRecentItems = /* GraphQL */ `
  subscription OnDeleteSmRecentItems(
    $filter: ModelSubscriptionSmRecentItemsFilterInput
    $owner: String
  ) {
    onDeleteSmRecentItems(filter: $filter, owner: $owner) {
      id
      items
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateSmPicklistValues = /* GraphQL */ `
  subscription OnCreateSmPicklistValues(
    $filter: ModelSubscriptionSmPicklistValuesFilterInput
    $owner: String
  ) {
    onCreateSmPicklistValues(filter: $filter, owner: $owner) {
      fieldObject__custom
      owner
      id
      defaultValue
      label
      value
      controllerName
      validFor
      sobject
      field
      fieldLabel
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSmPicklistValues = /* GraphQL */ `
  subscription OnUpdateSmPicklistValues(
    $filter: ModelSubscriptionSmPicklistValuesFilterInput
    $owner: String
  ) {
    onUpdateSmPicklistValues(filter: $filter, owner: $owner) {
      fieldObject__custom
      owner
      id
      defaultValue
      label
      value
      controllerName
      validFor
      sobject
      field
      fieldLabel
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSmPicklistValues = /* GraphQL */ `
  subscription OnDeleteSmPicklistValues(
    $filter: ModelSubscriptionSmPicklistValuesFilterInput
    $owner: String
  ) {
    onDeleteSmPicklistValues(filter: $filter, owner: $owner) {
      fieldObject__custom
      owner
      id
      defaultValue
      label
      value
      controllerName
      validFor
      sobject
      field
      fieldLabel
      order
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onCreateAuthUser = /* GraphQL */ `
  subscription OnCreateAuthUser(
    $filter: ModelSubscriptionAuthUserFilterInput
    $owner: String
  ) {
    onCreateAuthUser(filter: $filter, owner: $owner) {
      id
      username
      name
      auth0UserId
      sfUserId
      orgId
      instanceUrl
      syncToInProgress
      fileDataSyncInProgress
      recentItemSyncInProgress
      describeSyncInProgress
      nextDescribeSync
      ignoreSyncToOffline
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateAuthUser = /* GraphQL */ `
  subscription OnUpdateAuthUser(
    $filter: ModelSubscriptionAuthUserFilterInput
    $owner: String
  ) {
    onUpdateAuthUser(filter: $filter, owner: $owner) {
      id
      username
      name
      auth0UserId
      sfUserId
      orgId
      instanceUrl
      syncToInProgress
      fileDataSyncInProgress
      recentItemSyncInProgress
      describeSyncInProgress
      nextDescribeSync
      ignoreSyncToOffline
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteAuthUser = /* GraphQL */ `
  subscription OnDeleteAuthUser(
    $filter: ModelSubscriptionAuthUserFilterInput
    $owner: String
  ) {
    onDeleteAuthUser(filter: $filter, owner: $owner) {
      id
      username
      name
      auth0UserId
      sfUserId
      orgId
      instanceUrl
      syncToInProgress
      fileDataSyncInProgress
      recentItemSyncInProgress
      describeSyncInProgress
      nextDescribeSync
      ignoreSyncToOffline
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateObjectTypeSetting = /* GraphQL */ `
  subscription OnCreateObjectTypeSetting(
    $filter: ModelSubscriptionObjectTypeSettingFilterInput
    $owner: String
  ) {
    onCreateObjectTypeSetting(filter: $filter, owner: $owner) {
      id
      objectType
      lastModifiedDate
      nextLastModifiedDate
      initialSyncDone
      syncFromInProgress
      nextUrls
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateObjectTypeSetting = /* GraphQL */ `
  subscription OnUpdateObjectTypeSetting(
    $filter: ModelSubscriptionObjectTypeSettingFilterInput
    $owner: String
  ) {
    onUpdateObjectTypeSetting(filter: $filter, owner: $owner) {
      id
      objectType
      lastModifiedDate
      nextLastModifiedDate
      initialSyncDone
      syncFromInProgress
      nextUrls
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteObjectTypeSetting = /* GraphQL */ `
  subscription OnDeleteObjectTypeSetting(
    $filter: ModelSubscriptionObjectTypeSettingFilterInput
    $owner: String
  ) {
    onDeleteObjectTypeSetting(filter: $filter, owner: $owner) {
      id
      objectType
      lastModifiedDate
      nextLastModifiedDate
      initialSyncDone
      syncFromInProgress
      nextUrls
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateObjectSyncTo = /* GraphQL */ `
  subscription OnCreateObjectSyncTo(
    $filter: ModelSubscriptionObjectSyncToFilterInput
    $owner: String
  ) {
    onCreateObjectSyncTo(filter: $filter, owner: $owner) {
      id
      externalId
      sfId
      operation
      recordTimestamp
      eventTimestamp
      objectType
      recordName
      changedValues
      lastSync
      errorMessage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onUpdateObjectSyncTo = /* GraphQL */ `
  subscription OnUpdateObjectSyncTo(
    $filter: ModelSubscriptionObjectSyncToFilterInput
    $owner: String
  ) {
    onUpdateObjectSyncTo(filter: $filter, owner: $owner) {
      id
      externalId
      sfId
      operation
      recordTimestamp
      eventTimestamp
      objectType
      recordName
      changedValues
      lastSync
      errorMessage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onDeleteObjectSyncTo = /* GraphQL */ `
  subscription OnDeleteObjectSyncTo(
    $filter: ModelSubscriptionObjectSyncToFilterInput
    $owner: String
  ) {
    onDeleteObjectSyncTo(filter: $filter, owner: $owner) {
      id
      externalId
      sfId
      operation
      recordTimestamp
      eventTimestamp
      objectType
      recordName
      changedValues
      lastSync
      errorMessage
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      owner
      __typename
    }
  }
`;
export const onCreateSmFileLocal = /* GraphQL */ `
  subscription OnCreateSmFileLocal(
    $filter: ModelSubscriptionSmFileLocalFilterInput
  ) {
    onCreateSmFileLocal(filter: $filter) {
      id
      versionData
      linkedFileId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onUpdateSmFileLocal = /* GraphQL */ `
  subscription OnUpdateSmFileLocal(
    $filter: ModelSubscriptionSmFileLocalFilterInput
  ) {
    onUpdateSmFileLocal(filter: $filter) {
      id
      versionData
      linkedFileId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const onDeleteSmFileLocal = /* GraphQL */ `
  subscription OnDeleteSmFileLocal(
    $filter: ModelSubscriptionSmFileLocalFilterInput
  ) {
    onDeleteSmFileLocal(filter: $filter) {
      id
      versionData
      linkedFileId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
