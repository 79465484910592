import { useEffect, useState } from "react";
import { DataStore } from "aws-amplify";
import { useParams, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from "recoil";

import moment from "moment";

import { dynamoTables } from "../../../dbTables/syncObjects";
import { globalNavbarOptions } from "../../State/Global";
import { useNewRecentItems } from "../../Hooks/Hooks";
import { pageRoutes } from "../../../routes/routes";

import DetailView from "../../Utils/Detail/DetailContent";
import Spinner from "../../Utils/Loading/Spinner";

export default function OperationalEventDetailView () {
    useNewRecentItems();
    const navigate = useNavigate();

    const setNavbarOptions = useSetRecoilState(globalNavbarOptions);

    const { id } = useParams();
    const [ flattenedOE, setFlattenedOE ] = useState();
    const [ isLoading, setIsLoading ] = useState(true);

    const oeTable = dynamoTables.find((table) => table.ref === "pffsm__Operational_Event__c");
    const woTable = dynamoTables.find((table) => table.ref === "pffsm__smWork_Order__c");
    const woNav = pageRoutes.find((route) => route.id === "work-order-page")?.nav;

    const getOperationalEvent = async () =>{
        const oe = await DataStore.query(oeTable.model, id);
        const wo = await DataStore.query(woTable.model, oe.workorder__localId);
        let ackTime = "";
        if(oe.pffsm__Acknowledged_Time__c){
            ackTime =  moment(oe.pffsm__Acknowledged_Time__c).format("yyyy-MM-DD h:mm A")
        }
        setFlattenedOE([
            {
                label: "General",
                values: [{
                    label: "Name",
                    value: oe.Name
                }, {
                    label: "Work Order",
                    idValue: oe.workorder__localId,
                    isLookup: true,
                    lookupObj: woNav,
                    value: wo?.Name
                }, {
                    label: "Event Type",
                    value: oe.pffsm__Event_Type__c
                }, {
                    label: "Acknowledged Time",
                    value: ackTime
                }, {
                    label: "Acknowledged By",
                    value: oe.pffsm__Acknowledged_By_Name__c
                }]
            }
        ]);

        setIsLoading(false);
    }

    useEffect(() => {
        setNavbarOptions({
            title: "Operational Event Detail",
            page: "operational-event-detail-page",
            hasLeft: true,
            hasRight: true
          });
    }, []);

    useEffect(() => {
        getOperationalEvent();
    }, [ id ]);

    return (
        <div className= "py-1 pl-2 pr-2 h-full mt-8">
            { isLoading ?
                <Spinner /> :
                <>
                    <DetailView flattenedObject={ flattenedOE } />
                </>
            }
        </div>
    );
}