const assetData = (asset, plant, parent, assNav) => [
    {
        label: "General",
        values: [{
            label: "Name",
            value: asset.Name
        }, {
            label: "Parent",
            idValue: asset.parent__localId,
            isLookup: true,
            lookupObj: assNav,
            value: parent?.Name
        }, {
            label: "Asset Description",
            value: asset.pffsm__Description__c,
        }, {
            label: "Asset Class",
            value: asset.pffsm__Asset_Class__c,
        }, {
            label: "Asset Subclass",
            value: asset.pffsm__Asset_SubClass__c,
        }, {
            label: "Plant",
            idValue: asset.plant__localId,
            isLookup: true,
            lookupObj: assNav,
            value: plant?.Name
        }]
    }, {
        label: "Nameplate Data",
        values: [{
            label: "AC Capacity",
            value: asset.pffsm__AC_Capacity__c
        }, {
            label: "DC Capacity",
            value: asset.pffsm__DC_Capacity__c
        }, {
            label: "Manufacturer",
            value: asset.pffsm__Manufacturer_Name__c
        }, {
            label: "Model Number",
            value: asset.pffsm__Model_Number__c
        }, {
            label: "Serial Number",
            value: asset.pffsm__Serial_Number__c
        },{
            label: "Mfg Date",
            value: asset.pffsm__Mfg_Date__c
        },{
            label: "Warranty",
            value: asset.pffsm__Warranty__c
        },{
            label: "Warranty Start Date",
            value: asset.pffsm__Warranty_Start_Date__c
        },{
            label: "Warranty Expiration Date",
            value: asset.pffsm__Warranty_Expiration_Date__c
        }]
    },{
        label: "Location",
        values: [{
            label: "Latitude",
            value: asset.pffsm__Latitude__c
        }, {
            label: "Longitude",
            value: asset.pffsm__Longitude__c
        }, {
            label: "Street",
            value: asset.pffsm__Street__c
        }, {
            label: "City",
            value: asset.pffsm__City__c
        }, {
            label: "State/Province",
            value: asset.pffsm__State_Province__c
        },{
            label: "Zip/Postal Code",
            value: asset.pffsm__Zip_Postal_Code__c
        },{
            label: "Country",
            value: asset.pffsm__Country__c
        }]
    },{
        label: "Site Information",
        values: [{
            label: "Site Instructions",
            value: asset.siteInstructions__custom,
            isLargeText: true
        }, {
            label: "Gate Combo Codes",
            value: asset.gateComboCodes__custom,
            isLargeText: true
        }, {
            label: "Site Documentation",
            value: asset.siteDocumentation__custom,
            isLargeText: true
        }, {
            label: "System Size",
            value: asset.systemSize__custom
        }]
    }
]
export default assetData;