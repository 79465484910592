import { Block, BlockTitle, List, ListItem } from "konsta/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import moment from "moment";

import { useSearchResults } from "../../Hooks/Hooks";
import Spinner from "../Loading/Spinner"

export default function SearchResults({ nav, searchFields, queryString, fieldLabels, notDisplayFields, timeFields}){
    const [ searchables ] = useSearchResults(queryString ?? '', 10, 0, searchFields);
    const [ isLoading, setIsLoading ] = useState(true);

    useEffect(() => {
        if (searchables) {
            setIsLoading(false);
        }
    }, [ searchables ])

    useEffect(() => {
        setIsLoading(true);
    }, [ queryString ])

    return (
        <div className="relative hairline-t hairline-gray-200">
            { isLoading ?
                <Spinner /> :
                searchables.map(table =>
                    <div key={table.Name} className="relative hairline-b hairline-gray-500">
                        <BlockTitle withBlock={false}>{table.label}</BlockTitle>
                        <Block>
                            { table?.results?.length > 0 ? table.results.map((result) =>
                                <div key={result.id}>
                                    <BlockTitle>
                                        <Link to={`${nav}/detail/${result.id}`}>{result.Name}</Link>
                                    </BlockTitle>

                                    <List key={result.id}>
                                        { table.fields.map((field) =>
                                            (field !== 'Name' && (typeof(notDisplayFields) == 'undefined' || notDisplayFields == null || !notDisplayFields.includes(field))) ?
                                             (typeof(timeFields) != 'undefined' && timeFields != null && timeFields.includes(field)) ? 
                                                <ListItem title={fieldLabels?.[field]} after={result?.[field] ? moment(result[field]).format("yyyy-MM-DD h:mm A") : ''} key={field}/> :
                                                result[field]?.length < 20 ?
                                                    <ListItem title={fieldLabels?.[field]} after={result?.[field] ? result[field] : ''} key={field}/> :
                                                        <div>
                                                            <div className="shrink pl-4 text-list-title-ios">
                                                                <label>{fieldLabels?.[field]}</label>
                                                            </div>
                                                            <div className="col-span-2 shrink pl-4">
                                                                <textarea defaultValue={result[field]} rows="2" className="shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md dark:text-gray-300 dark:bg-gray-700" readOnly></textarea>
                                                            </div>
                                                        </div>
                                                : null
                                            )
                                        }
                                    </List>
                                </div>) :
                                <div key={table.Name} className="pl-6 pb-2"> <em>0 results found</em></div>
                            }
                        </Block>
                    </div>
            )}
        </div>
    );
}