import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";

const { persistAtom } = recoilPersist();

export const APPLYOFFLINESYNCKEY = "Apply_Offline_Sync";

export const userLocalRecentItems = atom({
    key: 'userLocalRecentItems',
    default: [],
    effects_UNSTABLE: [ persistAtom ]
});

export const globalSearchFocusState = atom({
    key: 'globalSearchFocusState',
    default: false,
    effects_UNSTABLE: [ persistAtom ]
});

export const leftPaneOpenState = atom({
    key: 'leftPaneOpenState',
    default: false,
    effects_UNSTABLE: [ persistAtom ]
});

export const debugState = atom({
    key: 'debugState',
    default: false,
    effects_UNSTABLE: [ persistAtom ]
});

export const darkThemeState = atom({
    key: 'darkThemeState',
    default: false,
    effects_UNSTABLE: [ persistAtom ]
});

export const globalNavbarOptions = atom({
    key: "globalNavbarOptions",
    default: {
        title: "Drive O&M",
        page: "home-page",
        hasLeft: false,
        hasRight: false
    },
    effects_UNSTABLE: [ persistAtom ]
});

export const networkState = atom({
    key: "globalNetworkState",
    default: true,
    effects_UNSTABLE: [ persistAtom ]
});

export const loginPrompt = atom({
    key: "loginPrompt",
    default: null,
    effects_UNSTABLE: [ persistAtom ]
});

export const fileAdded = atom({
    key: "fileAdded",
    default: false,
    effects_UNSTABLE: [ persistAtom ]
});