import { useEffect, useState } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import { DataStore } from 'aws-amplify';
import { MdAccessTime, MdEdit} from 'react-icons/md';
import { useSetRecoilState } from "recoil";
import { Toast, Fab, Tabbar, TabbarLink, Page, Button, Toolbar, Link, Icon } from 'konsta/react';

import moment from "moment";

import { dynamoTables } from "../../../dbTables/syncObjects";
import { globalNavbarOptions } from "../../State/Global";
import { pageRoutes } from "../../../routes/routes";
import { useNewRecentItems } from "../../Hooks/Hooks";

import DetailView from "../../Utils/Detail/DetailContent";
import Spinner from "../../Utils/Loading/Spinner";

export default function WorkAssignmentDetail() {
    const { id } = useParams();

    const [ flattenedWA, setFlattenedWA ] = useState();
    const [ startTime, setStartTime ] = useState();
    const [ stopTime, setStopTime ] = useState();

    const [ isEditing, setIsEditing ] = useState(false);
    const [ toastShowed, setToastShowed ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(true);

    const woNav = pageRoutes.find((route) => route.id === "work-order-page")?.nav;
    const wtNav = pageRoutes.find((route) => route.id === "work-task-page")?.nav;

    const waTable = dynamoTables.find((table) => table.ref === "pffsm__WO_Assignment__c");
    const woTable = dynamoTables.find((table) => table.ref === "pffsm__smWork_Order__c");
    const wtTable = dynamoTables.find((table) => table.ref === "pffsm__smWO_Task__c");

    const setNavbarOptions = useSetRecoilState(globalNavbarOptions);
    const navigate = useNavigate();

    useNewRecentItems();

    const closeToast = () => {
        setToastShowed(false);
    }

    const cancel = async () => {
        setIsEditing(false);
    };

    const openTimeTracker = () => {
        navigate(`/time-tracker-page?workAssignId=${id}`);
    }

    const updateWorkAssignment = async () => {
        let startTime1 = new Date(startTime);
        let stopTime1 = new Date(stopTime);
        const validStartTime = moment(startTime1).utc().format("yyyy-MM-DDTHH:mm:00.000Z");
        const validStopTime = moment(stopTime1).utc().format("yyyy-MM-DDTHH:mm:00.000Z");

        const queriedWorkAssignment = await DataStore.query(waTable.model, id);

        await DataStore.save(
            waTable.model.copyOf(queriedWorkAssignment, updated => {
                updated.pffsm__Scheduled_Start_Time__c = validStartTime;
                updated.pffsm__Scheduled_Stop_Time__c = validStopTime;
                updated.doPush__custom = true;
            })
        );

        flattenedWA[0].values[5].value = moment(validStartTime).format("yyyy-MM-DD h:mm A");
        flattenedWA[0].values[5].editValue = startTime;

        flattenedWA[0].values[6].value = moment(validStopTime).format("yyyy-MM-DD h:mm A");
        flattenedWA[0].values[6].editValue = stopTime;

        getWorkAssignments();
        setToastShowed(true);
        setIsEditing(false);
    };

    useEffect(() => {
        setNavbarOptions({
            title: "Work Assignment Detail",
            page: "work-assignment-detail-page",
            hasLeft: true,
            hasRight: true
        });
    }, []);

    useEffect(() => {
        getWorkAssignments();
    }, [id]);

    const getWorkAssignments = async () => {
        setIsLoading(true);
        const workAssignment = await DataStore.query(waTable.model, id);

        let eventStartFormat, eventStopFormat, eventStartValue, eventStopValue;
        if (workAssignment.pffsm__Scheduled_Start_Time__c) {
            setStartTime(workAssignment.pffsm__Scheduled_Start_Time__c);
            const momentValue = moment(workAssignment.pffsm__Scheduled_Start_Time__c);
            eventStartFormat = momentValue.format("yyyy-MM-DD h:mm A");
            eventStartValue = momentValue.format("yyyy-MM-DDTHH:mm");
        }

        if (workAssignment.pffsm__Scheduled_Stop_Time__c) {
            setStopTime(workAssignment.pffsm__Scheduled_Stop_Time__c);
            const momentValue = moment(workAssignment.pffsm__Scheduled_Stop_Time__c);
            eventStopFormat = momentValue.format("yyyy-MM-DD h:mm A");
            eventStopValue = momentValue.format("yyyy-MM-DDTHH:mm");
        }

        let workOrderId = workAssignment.workorder__localId;
        let workOrderName = '';
        let assetDescription = '';
        if (workOrderId !== undefined) {
            const queriedWorkOrder = await DataStore.query(woTable.model, workOrderId);
            workOrderName = queriedWorkOrder !== undefined ? queriedWorkOrder.Name : '';
            assetDescription = queriedWorkOrder !== undefined ? queriedWorkOrder.pffsm__Equip_Description__c : '';
        }

        let workTaskId = workAssignment.woTask__localId;
        let workTaskName = '';
        if (workTaskId !== undefined) {
            const workTasks = await DataStore.query(wtTable.model, workTaskId);
            workTaskName = workTasks !== undefined ? workTasks.Name :'';
        }

        setFlattenedWA([
            {
                label: "General",
                values: [{
                    label: "Name",
                    value: workAssignment.Name
                }, {
                    label: "Assigned User",
                    value: workAssignment.AssignedUserName
                }, {
                    label: "WO Description",
                    value: workAssignment.pffsm__WO_Description__c,
                    isLargeText: true
                }, {
                    label: "Asset Description",
                    value: assetDescription,
                    isLargeText: true
                },{
                    label: "Assignment Due Date",
                    value: workAssignment.pffsm__Assignment_Due_Date__c,
                }, {
                    label: "Scheduled Start Time",
                    value: eventStartFormat,
                    editValue: eventStartValue,
                    isEditable: true,
                    isRequired: true,
                    type: "datetime-local",
                    handler: (e) => setStartTime(e.target.value)
                }, {
                    label: "Scheduled Stop Time",
                    value: eventStopFormat,
                    editValue: eventStopValue,
                    isEditable: true,
                    isRequired: true,
                    type: "datetime-local",
                    handler: (e) => setStopTime(e.target.value)
                }, {
                    label: "WA Status",
                    value: workAssignment.pffsm__Assignment_Status__c,
                }, {
                    label: "Work Order",
                    idValue: workAssignment.workorder__localId,
                    isLookup: true,
                    lookupObj: woNav,
                    value: workOrderName
                }, {
                    label: "Work Task",
                    idValue: workAssignment.woTask__localId,
                    isLookup: true,
                    lookupObj: wtNav,
                    value: workTaskName
                }]
            }
        ]);

        setIsLoading(false);
    }

    return (
        <div className="mt-12 w-full" >
            { isLoading ?
                <Spinner /> :
                <>
                    {
                        isEditing ?
                        <Tabbar className="left-0 top-10 fixed bg-gray-600 font-bold text-neutral-900 save-cancel-menu">
                            <TabbarLink
                                onClick={ () => cancel() }
                                label="Cancel"
                            />
                            <TabbarLink
                                onClick={ () => updateWorkAssignment() }
                                label="Save"
                            />
                        </Tabbar>: null
                    }
                    {
                        isEditing ? null :
                        <Toolbar top>
                            <Link toolbar iconOnly>
                                <Icon ios={ <MdAccessTime className="h-7 w-7" /> } onClick={() => openTimeTracker()} material={ <MdAccessTime className="h-7 w-7" /> } />
                            </Link>
                        </Toolbar>
                    }
                    {
                        <div className="pl-2 pr-2 pb-18 h-full">
                            {
                                isEditing ? null :
                                <Fab className="fixed right-6-safe bottom-18-safe z-20" icon={ <MdEdit /> } onClick={() => setIsEditing(true)}/>
                            }
                            <DetailView flattenedObject={ flattenedWA } isEditing={ isEditing }/>
                        </div>
                    }

                    <Page>
                        <Toast position="center" opened={toastShowed}
                            button={
                                <Button clear inline onClick={() => closeToast()}>
                                Close
                                </Button>
                            }>

                            <div className="shrink">This record has been updated successfully!</div>
                        </Toast>
                    </Page>
                </>
            }
        </div>
    );
}