import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify, DataStore, Predicates, Cache, syncExpression } from "aws-amplify";
import { BrowserRouter as Router } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import { RecoilRoot } from 'recoil';

import { dynamoTables } from './dbTables/syncObjects';
import { APPLYOFFLINESYNCKEY } from './Components/State/Global';

import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import reportWebVitals from './reportWebVitals';
import awsExports from "./aws-exports";

import { getConfig } from "./config";

Amplify.configure(awsExports);

DataStore.configure({
  maxRecordsToSync: 100000,
  syncExpressions: [
    syncExpression(dynamoTables.find(table => table.ref === "pffsm__smEquipment__c").model, () => { return syncPredicate(true) }),
    syncExpression(dynamoTables.find(table => table.ref === "pffsm__smWork_Order__c").model, () => { return syncPredicate(false) }),
    syncExpression(dynamoTables.find(table => table.ref === "pffsm__smWO_Task__c").model, () => { return syncPredicate(false) }),
    syncExpression(dynamoTables.find(table => table.ref === "pffsm__WO_Assignment__c").model, () => { return syncPredicate(false) }),
    syncExpression(dynamoTables.find(table => table.ref === "pffsm__Work_Task_Step__c").model, () => { return syncPredicate(false) }),
    syncExpression(dynamoTables.find(table => table.ref === "pffsm__Time_Entry__c").model, () => { return syncPredicate(false) }),
    syncExpression(dynamoTables.find(table => table.ref === "pffsm__Operational_Event__c").model, () => { return syncPredicate(false) }),
    syncExpression(dynamoTables.find(table => table.ref === "ContentVersion").model, () => { return syncPredicate(false) })
  ]
});

const syncPredicate = (isAsset) => {
  const applySyncToOfflineFilter = Cache.getItem(APPLYOFFLINESYNCKEY);

  if (applySyncToOfflineFilter === null) {
    return r => r.id.eq(null);
  } else if (applySyncToOfflineFilter) {
    if (isAsset) {
      return r => r.pffsm__Sync_To_Offline__c.eq(true);
    } else {
      return r => r.syncToOffline__custom.eq(true);
    }
  }

  return Predicates.ALL;
}

window.Buffer = window.Buffer || require('buffer').Buffer;

ReactDOM.render(
  <Auth0Provider { ...getConfig() } redirectUri={ window.location.origin }>
    <React.StrictMode>
      <RecoilRoot>
        <Router>
          <App />
        </Router>
      </RecoilRoot>
    </React.StrictMode>
  </Auth0Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
