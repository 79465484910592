import { DataStore } from "aws-amplify";
import { Card } from "konsta/react";
import { WiCloudRefresh } from "react-icons/wi";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { dynamoTables } from "../../../dbTables/syncObjects";
import { labels } from "../../../dbTables/labels";

export default function CardInfo(props){
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState({})
    const item = props?.item
    const itemType = props?.type
    const fieldMapping = {
        pffsm__smWork_Order__c : {title : 'Name', body : ['pffsm__Description__c','pffsm__Priority__c', 'pffsm__Equip_Description__c'], footer: '' },
        pffsm__smEquipment__c : {title : 'Name', body : ['pffsm__Parent_ID_Text__c','pffsm__Description__c', 'pffsm__PlantName__c'], footer: '' },
        pffsm__WO_Assignment__c : {title : 'Name', body : ['pffsm__WO_Description__c', 'pffsm__Plant_Name__c','pffsm__WO_Task_Description__c', 'pffsm__Assignment_Status__c'], footer: '' },
        pffsm__smWO_Task__c : {title : 'Name', body: ['pffsm__WO_description__c', 'pffsm__Comments__c', 'pffsm__Asset_Description__c', 'craftName__custom', 'pffsm__Planned_Hours__c'], footer: '' }
    }

    useEffect(() =>{
        setLoading(true)
        async function getData(){
            const recentItemTble = dynamoTables.find( table => table.ref === itemType)
            const data = await DataStore.query(recentItemTble.model, row => row.or(row => [
                row.sfId__custom.eq(item.Id),
                row.sfId__custom.eq(item.id),
                row.id.eq(item.Id),
                row.id.eq(item.id)
            ]));

            setData(data[0])
            setLoading(false)
        }
        getData();
    }, [item])

    const renderCardBody = (item) => {
        return <table className="w-full">
            <tbody>
                { fieldMapping?.[itemType]?.body?.map( field =>
                    <tr key={field} className="flex justify-between w-full">
                        <td className="font-bold ">{labels?.[itemType][field]}: </td><td>{item?.[field] ? item[field].length > 20 ? item[field].substring(0,20) + '...' : item[field] : '' }</td>
                    </tr>)}

            </tbody>
        </table>
    }

    const selectRecent = async(item) =>{
        if (item.id) {
            const objTable = dynamoTables.find( table => table.ref === itemType)

            const record = await DataStore.query(objTable.model, item.id)
            if (record) {
                navigate(`${objTable.nav}${item.id}`)
            } else {
                alert("Record not available");
            }
        }
    }

    return (
         data ?
            <Card header={(!loading) ? data?.[fieldMapping?.[itemType]?.title] ?? item?.Name : <div className="rounded-full animate-pulse bg-gray-400 h-4 w-full"></div>}
                            footer={data?.[fieldMapping?.[itemType]?.footer] ?? ''}
                            className="hover:bg-gray-200 cursor-pointer" onClick={() => selectRecent(data)}>
                { !loading ?
                    renderCardBody(data)
                    : <div className="flex"> <div className="rounded-full animate-pulse bg-gray-400 h-4 w-full"></div> </div>
                }
            </Card>
        : null
    )
}