import './Main.css'
import { Route, Routes } from "react-router-dom";
import { pageRoutes } from '../../../routes/routes';

export default function Main(){

    return (
        <section className="pl-2 pr-2 h-full mt-18 w-full">
            <Routes>
                {
                    pageRoutes.map( page =>
                        <Route key={page.id} exact path={page.nav} element={page.cmp} />
                        )
                }
            </Routes>
        </section>
    )
}