import { useState, useEffect } from "react"
import Searchbar from "../Searchbar/Searchbar";
import {useSearchResults} from "../../../Components/Hooks/Hooks";
import { Badge, Block } from 'konsta/react';
import { useRecoilValue } from "recoil";
import { globalSearchFocusState } from "../../State/Global";
import { dynamoTables } from "../../../dbTables/syncObjects";
import { useNavigate } from "react-router-dom";

export default function GlobalSearch({setSelected, ...props}){
    let navigate = useNavigate();
    const {searchButtonClick, keyInputChange, userInputChange} = props;
    const showQrySuggest = props?.qrysuggest ?? false;
    const dropLength = props?.dropsize ?? 5;
    const badgeLabel= props?.badge?.field ?? 'label';
    const dropDisplayField = props?.displayField ?? 'name';
    const dropSubtextField = props?.subtextField;
    const showDropdown = props?.showdrop ?? true;
    const [query, setQuery] = useState(props?.query ?? '');
    const [dropItems, setDropItems] = useState([]);
    const [dropDownQry, setDropDownQuery] = useState('');
    const [searchables, setSearchables] = useSearchResults(query, props?.dropsize, 0, props?.searchObjects);
    const hasFocus = useRecoilValue(globalSearchFocusState)

    useEffect(()=>{
        if(props?.query) setQuery(props?.query); setDropItems([]);
    }, [ props?.query ])

    useEffect(() =>{
        const checkResults = checkIfResults(searchables)
        if(checkResults){
            let dropUniqueIds = [], dropToDisplay = []
            for (let dropItemsLength = 0; dropItemsLength < dropLength; dropItemsLength++) {
                for(const mod of searchables){
                    if(mod.results.length > 0 && mod.results[dropItemsLength]?.Name && !dropUniqueIds.includes(mod.results[dropItemsLength].id)){
                            dropUniqueIds.push(mod.results[dropItemsLength].id)
                            dropToDisplay.push({...mod.results[dropItemsLength], label: mod.label })
                    }
                }
            }
            setDropItems(dropToDisplay);
        }
    }, [searchables, dropLength])

    const checkIfResults = (allSearchables) =>{
        let combined = [];
        let hasResults = false;

        if (allSearchables) {
            for(const item of allSearchables){
                combined = combined.concat(item.results)
                hasResults = combined.concat(item.results).length > 0 ? true : false;
            }
        }
        return hasResults;
    }
    const dropSelect = (e) =>{
        const dropItem = dropItems.find(item => item.id === e.currentTarget.dataset.id)
        setSelected(dropItem);
        setDropDownQuery(dropItem.Name);
        const navConstruct = dynamoTables.find( table => table.ref === dropItem?.sobject__custom)?.nav
        navigate(`${navConstruct}${dropItem?.id}`)
    }

    return (
            <Searchbar userInputChange={userInputChange} keyInputChange={keyInputChange} searchButtonClick={searchButtonClick} dropdownQry={dropDownQry}>
                {(dropItems.length > 0 && showQrySuggest && showDropdown && hasFocus) ?
                    <div  className="origin-top-right absolute left-0 mt-1 w-full rounded-md shadow-lg bg-white dark:bg-gray-600 ring-1 ring-black ring-opacity-5 focus:outline-none z-50" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                        <div className="py-1 z-50 relative" role="none">
                            { dropItems.map( item =>
                                <div key={item.id} data-id={item.id} data-sobject={item.sobject__custom} onClick={(e)=> dropSelect(e)} className="py-2 hover:bg-gray-400 z-50 block">
                                    <div className="flex items-center cursor-pointer justify-between break-all">
                                        <span key={item.id + "title"} className="text-gray-700 block px-4 text-base font-medium  dark:text-gray-400" role="menuitem" tabIndex="-1">{item[dropDisplayField]}</span>
                                        { (props.badge) ? <Badge className="w-18 mr-1 truncate">{item[badgeLabel]}</Badge> : null }
                                    </div>
                                    { dropSubtextField ? <span key={item.id + "subText"} className="text-gray-600 px-5 text-sm">{item[dropSubtextField]}</span> : null }
                                </div>
                            )}
                        </div>
                    </div>
                    : null
                }
            </Searchbar>
    )
}