import { DataStore, SortDirection } from "aws-amplify";
import { dynamoTables } from "../../dbTables/syncObjects";

const getBase64 = (file) => {
    return new Promise(resolve => {
        const reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result);
        };

        reader.readAsDataURL(file);
    });
}

const getPicklistOptions = async(setPicklistOptions, object, field, controllingValue, controllingField) => {
    const picklistValueTable = dynamoTables.find(table => table.ref === "sm_picklist_values");

    let controllingValueIndex;
    if (controllingField) {
        const controlOptions = await DataStore.query(picklistValueTable.model, p => p.and(p => [
            p.sobject.eq(object),
            p.field.eq(controllingField)
        ]), {
            sort: p => p.order(SortDirection.ASCENDING)
        });

        controllingValueIndex = controlOptions.indexOf(controlOptions.find(item => controllingValue === item.value));
    }

    const options = await DataStore.query(picklistValueTable.model, p => p.and(p => [
        p.sobject.eq(object),
        p.field.eq(field)
    ]), {
        sort: p => p.order(SortDirection.ASCENDING)
    });

    let labelValueOptions = [{ label: "--None--", value: "" }];
    for (const option of options) {
        let addOption = true;

        if (option.controllerName && option.controllerName === controllingField) {
            if (option.validFor && controllingValueIndex !== -1) {
                const controllingBit = window.atob(option.validFor);
                addOption = !!(controllingBit.charCodeAt(controllingValueIndex >> 3) & (128 >> (controllingValueIndex % 8)));
            } else {
                addOption = false;
            }
        }

        if (addOption) {
            labelValueOptions.push({ label: option.label, value: option.value });
        }
    }

    setPicklistOptions(labelValueOptions);
}

export { getBase64, getPicklistOptions }