
export const labels = {
    pffsm__smWO_Task__c: {
      Name: 'Name',
      pffsm__WO_description__c: 'WO Description',
      pffsm__WO_Description__c: 'WO Description',
      pffsm__Comments__c: 'Comments',
      pffsm__Asset_ID__c: 'Asset',
      pffsm__Asset_Description__c: 'Asset Description',
      craftName__custom: 'Craft',
      pffsm__Planned_Hours__c: 'Planned Hours'
    },
    pffsm__Operational_Event__c: {
      Name: 'Name',
      pffsm__Event_Type__c: 'Event Type',
      pffsm__Acknowledged_Time__c: 'Acknowledged Time',
      pffsm__Acknowledged_By_Name__c: 'Acknowledged By'
    },
    pffsm__smWork_Order__c: {
      Name: 'Name',
      pffsm__Description__c: 'WO Description',
      pffsm__Equip_Description__c: 'Asset Description',
      pffsm__Status__c: 'Status',
      pffsm__Priority__c: 'Priority'
    },
    pffsm__WO_Assignment__c: {
      Name: 'Name',
      pffsm__WO_Task_Description__c: 'WT Description',
      pffsm__Plant_Name__c: 'Plant',
      pffsm__WO_Description__c: 'WO Description',
      pffsm__Plant_Description__c: 'Plant Description',
      pffsm__Assignment_Status__c: 'Status',
    },
    pffsm__smEquipment__c: {
      Name: 'Name',
      pffsm__Parent_ID_Text__c: 'Parent',
      pffsm__PlantName__c: 'Plant',
      pffsm__PlantID__c: 'Plant Id',
      pffsm__Description__c: 'Description',
      pffsm__Plant_Description__c: 'Plant Description',
      pffsm__Status__c: 'Status',
      pffsm__Dept__c: 'Dept',
      pffsm__ID_Description__c: 'ID Description'
    },
    pffsm__Work_Task_Step__c: {
      Name: 'Name'
    },
    ContentVersion: {
      Title: "Title"
    },
    TimeEntry: {
      Name: 'Name'
    }
  }