import './App.css';

import { App as MobileApp, KonstaProvider, Page, Navbar } from 'konsta/react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useEffect, useState, } from "react";
import { Hub, API, Auth, DataStore, Predicates, Storage, graphqlOperation, Analytics, Cache } from "aws-amplify";
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import { onUpdateSmAssetTagc, onUpdateSmWorkOrderc, onUpdateSmWorkTaskc, onUpdateSmWorkTaskStepc,
  onUpdateSmWorkAssignmentc, onUpdateSmTimeEntryc, onUpdateSmOperationalEventc, onUpdateSmFile } from "./graphql/subscriptions"

import Main from './Components/Layout/Main/Main';
import DockedFooter from './Components/Layout/DockedFooter/DockedFooter';
import LeftMenuPanel from './Components/Layout/LeftMenuPanel/LeftMenuPanel';
import Spinner from './Components/Utils/Loading/Spinner';

import { globalNavbarOptions, networkState, loginPrompt, fileAdded, APPLYOFFLINESYNCKEY } from './Components/State/Global';
import { useDarkTheme, useDebugLogs, useSignedInUser } from './Components/Hooks/Hooks';
import { pageRoutes } from './routes/routes';

import { getConfig } from "./config.js";
import { dynamoTables } from './dbTables/syncObjects';

const config = getConfig();

function App() {
  useDarkTheme();
  useDebugLogs();

  let applySyncToOfflineFilter = false;

  const localFileTable = dynamoTables.find(table => table.ref === "sm_file_local");
  const objectTypeSettingTable = dynamoTables.find(table => table.ref === "object_type_setting");

  const auth0 = useAuth0();
  const navigate = useNavigate();
  const [ user, setUser ] = useSignedInUser();

  const [ isLoading, setIsLoading ] = useState(true);
  const [ isLoadingData, setIsLoadingData ] = useState(false);
  const [ isAuthenticated, setIsAuthenticated ] = useState(false);
  const [ dataStoreReady, setDataStoreReady ] = useState(false);

  const [ isFileExecuting, setIsFileExecuting ] = useState(false);
  const [ doInitialFileUpload, setDoInitialFileUpload ] = useState(true);

  const [ equipmentSynced, setEquipmentSynced ] = useState(false);
  const [ timeSheetSynced, setTimeSheetSynced ] = useState(false);
  const [ timeEntrySynced, setTimeEntrySynced ] = useState(false);
  const [ wtStepSynced, setWTStepSynced ] = useState(false);
  const [ workOrderSynced, setWorKOrderSynced ] = useState(false);
  const [ woAssignmentSynced, setWOAssignmentSynced ] = useState(false);
  const [ woTaskSynced, setWOTaskSynced ] = useState(false);
  const [ operationalEventSynced, setOperationalEventSynced ] = useState(false);

  const [ hasFileAdded, setHasFileAdded ] = useRecoilState(fileAdded);
  const [ isOnline, setIsOnline ] = useRecoilState(networkState)
  const [ loginPromptVal, setLoginPromptVal ] = useRecoilState(loginPrompt);
  const { title, page, hasLeft, hasRight } = useRecoilValue(globalNavbarOptions);

  Hub.listen("datastore", async (eventData) => {
    const { event, data } = eventData.payload;

    if (event === "networkStatus") {
      setIsOnline(data.active);
    } else if (event === "ready") {
      setDataStoreReady(true);
    }
  });

  useEffect(() => {
    authenticateCognito();

    setInterval(() => {
      if (!isFileExecuting) {
        setIsFileExecuting(true);
      }
    }, 600000);
  }, [])

  useEffect(() => {
    if (isOnline && user && (isFileExecuting || (doInitialFileUpload && dataStoreReady)|| hasFileAdded)) {
      executeFileUpload();

      if (doInitialFileUpload) {
        setDoInitialFileUpload(false)
      }
    }

    if (hasFileAdded) {
      setHasFileAdded(false);
    }

    setIsFileExecuting(false);
  }, [ isFileExecuting, isOnline, user, hasFileAdded, dataStoreReady ])

  useEffect(() => {
    if (!auth0.isLoading) {
      if (auth0.isAuthenticated) {
        authenticateCognito();
      } else if (!isAuthenticated && isOnline) {
        auth0.loginWithRedirect({ prompt: loginPromptVal });
      }
    }
  }, [auth0, isOnline])

  useEffect(() => {
    if (isAuthenticated) {
      if (!isLoadingData) {
        setIsLoadingData(true);
        setLoginPromptVal(null);

        loadInitialData();
      }
    }
  }, [ isAuthenticated ])

  useEffect(() => {
    if (timeSheetSynced && timeEntrySynced && equipmentSynced && wtStepSynced
        && workOrderSynced && woAssignmentSynced && woTaskSynced && operationalEventSynced) {
          setIsLoadingData(false);
          setIsLoading(false);
      }
  }, [timeSheetSynced, timeEntrySynced, equipmentSynced, wtStepSynced, workOrderSynced, woAssignmentSynced, woTaskSynced, operationalEventSynced])

  const executeFileUpload = async() => {
    DataStore.query(localFileTable.model).then(async(files) => {
      for (const file of files) {
        (async() => {
          try {
            await Storage.put(user.sfUserId + "/" + file.linkedFileId, new Blob([file.versionData]));
            DataStore.delete(file);
          } catch (e) {}
        })();
      }
    });
  }

  const authenticateCognito = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      setIsAuthenticated(true);
    } catch (e) {
      if (auth0.isAuthenticated) {
        const accessToken = await auth0.getAccessTokenSilently({ detailedResponse: true });
        const idToken = await auth0.getIdTokenClaims();

        await Auth.federatedSignIn(config.provider, {  token: accessToken.id_token, expires_at: idToken.exp * 1000 }, auth0.user);
        setIsAuthenticated(true);
      }
    }
  }

  const loadInitialData = async () => {
    const cognitoUser = await Auth.currentAuthenticatedUser();

    const authUserModel = dynamoTables.find( table => table.ref === 'auth_user' );
    const authUsers = await DataStore.query(authUserModel.model);

    try {
      await DataStore.stop();

      const response = await API.post("login", "/fsm/login", {
        body: {
          id_token: cognitoUser.token,
          user_id: cognitoUser.sub
        }
      });

      if ((authUsers.length > 0 && authUsers[0].auth0UserId !== cognitoUser.sub) || response.ignoreSyncToOffline !== response.oldIgnoreSyncToOffline) {
        await DataStore.clear();
      }

      applySyncToOfflineFilter = !response.ignoreSyncToOffline;
      Cache.setItem(APPLYOFFLINESYNCKEY, applySyncToOfflineFilter, { expires: new Date().getTime() +  31556952000 });

      Analytics.autoTrack("session", {
        enable: true,
        attributes: {
          name: response.name,
          username: response.username,
          userId: response.auth0UserId
        }
      }, "AWSPinpoint");

      setUser(response);
    } catch (e) {
      if (isOnline) {
        alert("Failed to load all data.");
      }
    }

    await DataStore.start();

    DataStore.observeQuery(objectTypeSettingTable.model, Predicates.ALL).subscribe(msg => {
      for (const item of msg.items) {
        setInitialDone(item);
      }
    });

    initiateSyncToOfflineGraphQLOperation(onUpdateSmAssetTagc, "onUpdateSmAssetTagc", "pffsm__smEquipment__c", "pffsm__Sync_To_Offline__c");
    initiateSyncToOfflineGraphQLOperation(onUpdateSmWorkOrderc, "onUpdateSmWorkOrderc", "pffsm__smWork_Order__c", "syncToOffline__custom");
    initiateSyncToOfflineGraphQLOperation(onUpdateSmWorkTaskc, "onUpdateSmWorkTaskc", "pffsm__smWO_Task__c", "syncToOffline__custom");
    initiateSyncToOfflineGraphQLOperation(onUpdateSmWorkTaskStepc, "onUpdateSmWorkTaskStepc", "pffsm__Work_Task_Step__c", "syncToOffline__custom");
    initiateSyncToOfflineGraphQLOperation(onUpdateSmWorkAssignmentc, "onUpdateSmWorkAssignmentc", "pffsm__WO_Assignment__c", "syncToOffline__custom");
    initiateSyncToOfflineGraphQLOperation(onUpdateSmTimeEntryc, "onUpdateSmTimeEntryc", "pffsm__Time_Entry__c", "syncToOffline__custom");
    initiateSyncToOfflineGraphQLOperation(onUpdateSmOperationalEventc, "onUpdateSmOperationalEventc", "pffsm__Operational_Event__c", "syncToOffline__custom");
    initiateSyncToOfflineGraphQLOperation(onUpdateSmFile, "onUpdateSmFile", "ContentVersion", "syncToOffline__custom");
  }

  const setInitialDone = (data) => {
    if (data.objectType === "pffsm__smWO_Task__c") {
      setWOTaskSynced(data.initialSyncDone);
    } else if (data.objectType === "pffsm__Work_Task_Step__c") {
      setWTStepSynced(data.initialSyncDone);
    } else if (data.objectType === "pffsm__smEquipment__c") {
      setEquipmentSynced(data.initialSyncDone);
    } else if (data.objectType === "pffsm__Time_Entry__c") {
      setTimeEntrySynced(data.initialSyncDone);
    } else if (data.objectType === "pffsm__smWork_Order__c") {
      setWorKOrderSynced(data.initialSyncDone);
    } else if (data.objectType === "pffsm__WO_Assignment__c") {
      setWOAssignmentSynced(data.initialSyncDone);
    } else if (data.objectType === "pffsm__Daily_Time_Sheet__c") {
      setTimeSheetSynced(data.initialSyncDone);
    } else if (data.objectType === "pffsm__Operational_Event__c") {
      setOperationalEventSynced(data.initialSyncDone);
    }
  }

  const initiateSyncToOfflineGraphQLOperation = (operation, operationName, tableName, syncToOfflineField) => {
    const table = dynamoTables.find(table => table.ref === tableName);

    API.graphql(
      graphqlOperation(operation)
    ).subscribe({
      next: (data) => {
        processSyncToOffline(data.value.data[operationName], table, syncToOfflineField);
      }
    });
  }

  const processSyncToOffline = async(data, table, syncToOfflineField)  => {
    const queriedData = await DataStore.query(table.model, data.id);

    if (queriedData) {
      // Need to set timer or the delete mutation doesn't execute properly.
      setTimeout(() => {
        if (data.localDeleteOnly__custom) {
          DataStore.delete(queriedData);
        }
      }, 5000)

      if (queriedData[syncToOfflineField] !== data[syncToOfflineField]
        && !data[syncToOfflineField]
        && applySyncToOfflineFilter) {

        DataStore.save(table.model.copyOf(queriedData, u => {
          u.localDeleteOnly__custom = true
        }));
      }
    }
  }

  const renderLeft = () => {
    let currentPage = hasLeft ? page : "home-page";
    return pageRoutes.find(route => route.id === currentPage)?.renderNavbarLeft?.(navigate);
  }

  const renderRight = () => {
    let currentPage = hasRight ? page : "home-page";
    return pageRoutes.find(route => route.id === currentPage)?.renderNavbarRight?.(navigate);
  }

  return (
      <KonstaProvider theme="ios">
        <MobileApp theme="ios" className="k-ios">
          <Page className="h-screen">
              <Navbar style={{ zIndex: "30" }} className="fixed" title={ title } left={ renderLeft() } right={ renderRight() }/>

              { isLoading ? <Spinner /> : <Main />}

              <LeftMenuPanel />
              <DockedFooter />
          </Page>
        </MobileApp>
      </KonstaProvider>
  );
}

export default App;