/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_mobile_analytics_app_id": "806e2f03c49c43418a728aaac7a1a5b9",
    "aws_mobile_analytics_app_region": "us-east-1",
    "Analytics": {
        "AWSPinpoint": {
            "appId": "806e2f03c49c43418a728aaac7a1a5b9",
            "region": "us-east-1"
        }
    },
    "aws_appsync_graphqlEndpoint": "https://cfdj7qscqjfolirs6fo767p3vy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "OPENID_CONNECT",
    "aws_cloud_logic_custom": [
        {
            "name": "login",
            "endpoint": "https://ujd7wq0dm1.execute-api.us-east-1.amazonaws.com/main",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:f243f6bd-9175-4318-85d5-19b764883a5b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_2cJvI0MVQ",
    "aws_user_pools_web_client_id": "44tot5ursvp8ubvvi8iirdt6pa",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "fsmobileappc10efdd5635b45ed93e34d90be849207155830-main",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
