import WorkOrderContent from "./WorkOrderContent";

export default function WorkOrderScheduled({ currentUserId }) {
    const queryPredicates = (w => w.and(w => [
        w.pffsm__Status__c.notContains("Closed"),
        w.pffsm__Status__c.notContains("Completed"),
        w.pffsm__Status__c.notContains("Canceled"),
        w.pffsm__Status__c.notContains("Rejected"),
        w.OwnerId.eq(currentUserId)
    ]));

    return (<WorkOrderContent queryPredicates={ queryPredicates }/>);
}