import { useState, useEffect, useRef } from "react"
import { useLoseFocus } from "../../Hooks/Hooks";
import { globalSearchFocusState } from '../../State/Global';
import { useSetRecoilState } from 'recoil';
import { useSearchParams } from "react-router-dom";

export default function Searchbar(props){
    let [searchParams] = useSearchParams();
    const {searchButtonClick, keyInputChange, userInputChange} = props;
    const [query, setQuery] = useState(searchParams?.get('q') ?? '');
    const elmRef = useRef(null);
    const setHasFocus = useSetRecoilState(globalSearchFocusState)
    
    useLoseFocus(elmRef);

    useEffect(() => {
        const timer = setTimeout(() => {
            if(props?.dropdownQry) setQuery(props?.dropdownQry);
        }, 500);
        return () => clearTimeout(timer);
    }, [props?.dropdownQry])

    const handleInputChange = (e) =>{
            setQuery(e.target.value);
            setHasFocus(true);
    }

    return (
        <div className="relative inline-block w-full" ref={elmRef} >
            <div className="flex justify-center">
                <input className="right-0 w-56 text-black py-1 px-1 rounded-l-md rounded-r-none focus:outline-none ring-1 ring-black dark:bg-gray-600" value={query} onChange={(e)=> handleInputChange(e)}  type="search" placeholder="Search.." onKeyPress={keyInputChange} onInput={userInputChange} />
                <button className="flex items-center justify-center px-4 ring-1 ring-black bg-gray-400 hover:bg-gray-200 rounded-r-md dark:bg-gray-400 dark:hover:bg-gray-400" onClick={searchButtonClick}>
                    <svg className="w-4 h-4 text-gray-600" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                        <path d="M16.32 14.9l5.39 5.4a1 1 0 0 1-1.42 1.4l-5.38-5.38a8 8 0 1 1 1.41-1.41zM10 16a6 6 0 1 0 0-12 6 6 0 0 0 0 12z" />
                    </svg>
                </button>
            </div>
            { (query?.trim().length > 2) ? props.children : null }
        </div>
    )
}