import { useState, useEffect, useRef } from "react"
import {useSearchResults} from "../../../Components/Hooks/Hooks";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { globalSearchFocusState } from "../../State/Global";
import { dynamoTables, globalSearchTables } from '../../../dbTables/syncObjects';
import { useLoseFocus } from "../../Hooks/Hooks";
import { DataStore } from "aws-amplify";

export default function Lookup(props){
    const { keyInputChange, userInputChange, selectItem } = props;
    const searchableProps = globalSearchTables.filter( prop => prop.ref === props?.sobject);
    const lookupModel = searchableProps?.[0].model;
    const dropLength = props?.dropsize ?? 5;
    const dropDisplayField = props?.displayField ?? 'Name';
    const recordId = props?.id ?? '';
    const [query, setQuery] = useState('');
    const [dropItems, setDropItems] = useState([]);
    const [selected, setSelected] = useState({})
    const [dropDownQry, setDropDownQuery] = useState('');
    const [searchables, setSearchables] = useSearchResults(query, props?.dropsize, 0, searchableProps);
    const hasFocus = useRecoilValue(globalSearchFocusState)
    const elmRef = useRef(null);
    const setHasFocus = useSetRecoilState(globalSearchFocusState)
    useLoseFocus(elmRef);

    useEffect(() => {
        async function getRelated(){
            const currentRecord = await DataStore.query(lookupModel, recordId)
            setSelected(currentRecord)
            setQuery(currentRecord?.Name ?? "")
        }
        getRelated();
    },[recordId, lookupModel])

    useEffect(() =>{
        const checkResults = checkIfResults(searchables)
        if(checkResults){
            let dropToDisplay = []
            for (let dropItemsLength = 0; dropItemsLength < dropLength; dropItemsLength++) {
                for(const mod of searchables){
                    if(mod.results.length > 0 && mod.results[dropItemsLength]?.Name){
                            dropToDisplay.push({...mod.results[dropItemsLength], label: mod.label })
                    }
                }
            }
            setDropItems(dropToDisplay);
        }
    }, [searchables, dropLength])

    const checkIfResults = (allSearchables) =>{
        let combined = [];
        let hasResults = false;

        if (allSearchables) {
            for(const item of allSearchables){
                combined = combined.concat(item.results)
                hasResults = combined.concat(item.results).length > 0 ? true : false;
            }
        }

        return hasResults;
    }
    const dropSelect = (e) =>{
        const dropItem = dropItems.find(item => item.id === e.currentTarget.dataset.id)
        setQuery(dropItem.Name)
        setSelected(dropItem);
        selectItem(dropItem)
        setDropDownQuery(dropItem.Name);
        setHasFocus(false);
    }
    const handleInputChange = (e) =>{
        setQuery(e.target.value)
        setHasFocus(true);
    }

    return (
        <div className="relative inline-block w-full py-2" ref={elmRef} >
            <div className="flex">
                <input className="right-0 w-64 text-black py-1 px-1 rounded-md focus:outline-none dark:bg-gray-600" value={query} onChange={(e)=> handleInputChange(e)}  type="text" placeholder="Search records" onKeyPress={keyInputChange} onInput={userInputChange} />

            </div>
            {(dropItems.length > 0  && hasFocus) ?
                <div  className="origin-top-right absolute left-0 mt-1 w-56 rounded-md shadow-lg bg-white dark:bg-gray-600 ring-1 ring-black ring-opacity-5 focus:outline-none z-50" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
                    <div className="py-1 z-50 relative" role="none">
                        { dropItems.map( item =>
                            <div key={item.id} data-id={item.id} onClick={(e)=> dropSelect(e)} className="py-2 hover:bg-gray-400 z-50 block">
                                <div className="flex items-center cursor-pointer justify-between break-all">
                                    <span key={item.id + "title"} className="text-gray-700 block px-4 text-base font-medium  dark:text-gray-400" role="menuitem" tabIndex="-1">{item[dropDisplayField]}</span>
                                </div>

                            </div>
                        )}
                    </div>
                </div>
                : null
            }
        </div>
    )
}