import moment from "moment";

import WorkAssignmentContent from "./WorkAssignmentContent";

export default function WorkAssignmentActive() {
    const last30Days = moment.utc().subtract(30, "days").toISOString();
    const queryPredicates = (w => w.and( w => [
        w.pffsm__Assignment_Status__c.eq("Completed"),
        w.pffsm__Scheduled_Start_Time__c.ge(last30Days),
    ]));

    return (<WorkAssignmentContent queryPredicates={ queryPredicates } />);
}