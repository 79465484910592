import { useEffect, useState } from "react";
import { DataStore } from "aws-amplify";
import InfiniteScroll from "react-infinite-scroll-component";

import { pageRoutes } from '../../../routes/routes';
import { dynamoTables } from "../../../dbTables/syncObjects";

import DetailView from "../../Utils/Detail/DetailContent";

export default function WorkAssignmentContent({ queryPredicates}) {
    const [ workAssignments, setWorkAssignments ] = useState([]);
    const [ page, setPage ] = useState(0);
    const [ hasMore, setHasMore ] = useState(true);

    const waNav = pageRoutes.find((route) => route.id === "work-assignment-page")?.nav;
    const workAssignmentTable = dynamoTables.find(table => table.ref === "pffsm__WO_Assignment__c");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const queriedWorkAssignments = await DataStore.query(workAssignmentTable.model, queryPredicates, {
            page: page,
            limit: 5
        });

        setWorkAssignments(workAssignments.concat(getFlattenedWorkAssignments(queriedWorkAssignments)));

        setPage(page + 1);
        setHasMore(queriedWorkAssignments.length === 5);
    }

    const getFlattenedWorkAssignments = (workAssignments) => {
        let flattenedWorkAssignments = [];
        for (let workAssignment of workAssignments) {
            flattenedWorkAssignments.push({
                label: "",
                values:[{
                    label: "Name",
                    idValue: workAssignment.id,
                    isLookup: true,
                    lookupObj: waNav,
                    value: workAssignment.Name
                }, {
                    label: "Work Order Description",
                    value: workAssignment.pffsm__WO_Description__c,
                    isLargeText: true
                }, {
                    label: "Plant Description",
                    value: workAssignment.pffsm__Plant_Description__c,
                    isLargeText: true
                }, {
                    label: "Work Task Description",
                    value: workAssignment.pffsm__WO_Task_Description__c,
                    isLargeText: true
                }, {
                    label: "WA Status",
                    value: workAssignment.pffsm__Assignment_Status__c
                }]
            })
        }

        return flattenedWorkAssignments;
    }

    return (
        workAssignments.length === 0 ?
            <div className="text-center mt-1.5">No Records Found</div> :
            <InfiniteScroll
                dataLength={ workAssignments.length }
                next={ fetchData }
                hasMore={ hasMore }
                loader={<h4>Loading...</h4>}
                scrollableTarget="scrollable">

                <DetailView flattenedObject={ workAssignments } isEditing={ false }/>

            </InfiniteScroll>
    )
}