// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';



const { SmWorkOrderc, SmAssetTagc, SmWorkTaskc, SmWorkTaskStepc, SmOperationalEventc, SmWorkAssignmentc, SmFile, SmTimeEntryc, SmDailyTimeSheetc, SmRecentItems, SmPicklistValues, AuthUser, ObjectTypeSetting, ObjectSyncTo, SmFileLocal } = initSchema(schema);

export {
  SmWorkOrderc,
  SmAssetTagc,
  SmWorkTaskc,
  SmWorkTaskStepc,
  SmOperationalEventc,
  SmWorkAssignmentc,
  SmFile,
  SmTimeEntryc,
  SmDailyTimeSheetc,
  SmRecentItems,
  SmPicklistValues,
  AuthUser,
  ObjectTypeSetting,
  ObjectSyncTo,
  SmFileLocal
};