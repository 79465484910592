import { useState, useEffect } from "react"
import { Page, Block } from 'konsta/react';
import { useNavigate } from "react-router-dom";
import { useSetRecoilState } from "recoil";

import Greeting from '../../Utils/Greeting/Greeting';
import GlobalSearch from "../../Utils/GlobalSearch/GlobalSearch";
import RecentItems from "../../Utils/RecentItems/RecentItems";

import { globalSearchTables } from "../../../dbTables/syncObjects";
import { globalNavbarOptions } from "../../State/Global";

const searchableProps = globalSearchTables;

export default function Home() {
    let navigate = useNavigate();

    const [query, setQueryString] = useState('');
    const [dropSelect, setSelected] = useState();
    const [toggleDrop, setToggleDrop] = useState(true);
    const setNavbarOptions = useSetRecoilState(globalNavbarOptions);

    useEffect(() => {
      setNavbarOptions({
        title: "Drive O&M",
        page: "home-page",
        hasLeft: true,
        hasRight: true
      });
    }, []);

    useEffect(()=>{
      if(typeof dropSelect?.Name !== 'undefined') setSelected(dropSelect?.Name); setToggleDrop(false)
    },[dropSelect])

    const searchButtonClick = () => {
      handleSearch()
    }
    const keyInputChange = (e) => {
      if(e.which === 13){
        handleSearch()
        }
    }
    const userInputChange = (e) => {
      setQueryString(e.target.value)
      setToggleDrop(true)
    }
    const handleSearch = () => {
      setToggleDrop(false)
      navigate(`/global-search?q=${query}`)
    }

   return ( <section className="h-full">
              <Page className="mt-6 flex flex-col">
                <Block className="self-center flex flex-col">
                  <GlobalSearch searchObjects={searchableProps} query={query} searchButtonClick={searchButtonClick} userInputChange={userInputChange}
                                keyInputChange={keyInputChange} displayField='Name' badge={{field: 'label'}} setSelected={setSelected} showdrop={toggleDrop} qrysuggest/>
                </Block>
                <div className="self-center flex flex-col">
                  <div className="self-center align-center z-0"> <Greeting />  </div>
                </div>
                <div className="z-0">
                 <RecentItems type={["pffsm__smWO_Task__c","pffsm__smWork_Order__c", "pffsm__WO_Assignment__c", "pffsm__smEquipment__c" ]}/>
                </div>
              </Page>
    </section>
   )
}