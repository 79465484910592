import { useState, useEffect } from "react"
import { Page, Block} from "konsta/react";
import { useSetRecoilState } from "recoil";

import GlobalSearch from "../../Utils/GlobalSearch/GlobalSearch";
import SearchResults from "../../Utils/SearchResults/SearchResults";
import RecentItems from "../../Utils/RecentItems/RecentItems";

import { globalSearchTables } from "../../../dbTables/syncObjects";
import { labels } from "../../../dbTables/labels";
import { pageRoutes } from '../../../routes/routes';
import { globalNavbarOptions } from "../../State/Global";

export default function OperationalEventHome(){
    const oeFieldLabels = labels.pffsm__Operational_Event__c;
    const oeNav = pageRoutes.find((route) => route.id === "operational-event-page")?.nav;

    const oeTable = globalSearchTables.filter(searchTable => searchTable.ref === "pffsm__Operational_Event__c");
    const oeSearchFields = oeTable.map(x=>{return {...x, fields: [
        "Name",
        "pffsm__Event_Type__c",
        "pffsm__Acknowledged_Time__c",
        "pffsm__Acknowledged_By_Name__c"
    ]}});

    const [ query, setQuery ] = useState('');
    const setNavbarOptions = useSetRecoilState(globalNavbarOptions);

    useEffect(() => {
        setNavbarOptions({
            title: "Operational Events",
            page: "operatioanl-event-page",
            hasLeft: false,
            hasRight: false
        });
    }, []);

    const userInputChange = (e) => {
        setQuery(e.target.value);
    }

    return (
        <Page className="mt-2 flex flex-col">
            <Block className="w-full items-center flex flex-col hairline-b p-4 hairline-gray-200">
                <GlobalSearch query={query} userInputChange={userInputChange} />
            </Block>
            { query.trim().length > 2 ?
                <SearchResults nav={ oeNav } searchFields={oeSearchFields} queryString = {query} fieldLabels={oeFieldLabels}/> :
                <RecentItems type={["pffsm__Operational_Event__c"]}/>
            }
        </Page>
    );
}