import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { useSearchParams } from 'react-router-dom';
import { DataStore , SortDirection } from 'aws-amplify';
import { Block, BlockTitle, Fab, List, ListInput, ListItem, Button, Toast } from "konsta/react";
import { MdAdd, MdOutlineFactCheck } from "react-icons/md";

import moment from "moment";

import Spinner from "../../Utils/Loading/Spinner";

import { globalNavbarOptions } from "../../State/Global";
import { dynamoTables } from "../../../dbTables/syncObjects";
import { useSignedInUser } from '../../Hooks/Hooks';
import { getPicklistOptions } from "../../Utils/Util";

export default function TimeTracker(){
    const [ timeEntries, setTimeEntries ] = useState([]);
    const [ timeSheets, setTimeSheets ] = useState({})
    const [ waStatus, setWaStatus ] = useState();
    const [ waStartTime, setWaStartTime ] = useState();

    const [ startTime, setStartTime ] = useState();
    const [ EndTime, setEndTime ] = useState();
    const [ type, setType ] = useState();
    const [ comments, setComments ] = useState();

    const [ editId,  setEditId ] = useState(false);
    const [ editStartTime, setEditStartTime ] = useState();
    const [ editEndTime, setEditEndTime ] = useState();
    const [ editComments, setEditComments ] = useState();
    const [ editType, setEditType ] = useState();

    const [ flattenedWA, setFlattenedWA ] = useState([]);
    const [ isLoading, setIsLoading ] = useState([]);
    const [ showAddTime, setShowAddTime ] = useState(false);
    const [ toastShowed, setToastShowed ] = useState(false);
    const [ toastMessage, setToastMessage ] = useState();
    const [ timesheetDate, setTimesheetDate ] = useState(moment(new Date()).format("YYYY-MM-DD"));
    const [ showApproveBtn, setShowApproveBtn ] = useState(false);
    const [ assignedUser, setAssignedUser ] = useState();

    const [ timeTypeOptions, setTimeTypeOptions ] = useState([]);

    const [ user ] = useSignedInUser();
    const [ searchParams ] = useSearchParams();

    const setNavbarOptions = useSetRecoilState(globalNavbarOptions);

    const waTable = dynamoTables.find((table) => table.ref === "pffsm__WO_Assignment__c");
    const timeEntryTable = dynamoTables.find( table => table.ref === 'pffsm__Time_Entry__c' );
    const timeSheetTable = dynamoTables.find( table => table.ref === 'pffsm__Daily_Time_Sheet__c' );

    const waId = searchParams?.get('workAssignId');

    const divStyle = {
        width: '100%',
        height: '90%',
        overflow: 'scroll',
        margin: 'auto'
    };

    const handleStartTime = (e) => {
        if (e.target.value != null && e.target.value !== undefined && e.target.value !== '') {
            const momentValue = moment(timesheetDate + 'T' + e.target.value);
            const eventStartFormat = momentValue.utc().format("YYYY-MM-DDTHH:mm:00.000Z");
            setStartTime(eventStartFormat);
        }
    }

    const handleEndTime = (e) => {
        if (e.target.value != null && e.target.value !== undefined && e.target.value !== '') {
            const momentValue = moment(timesheetDate + 'T' + e.target.value);
            const eventEndFormat = momentValue.utc().format("YYYY-MM-DDTHH:mm:00.000Z");
            setEndTime(eventEndFormat);
        }
    }

    const handleEditStartTime = (e) => {
        if (e.target.value != null && e.target.value !== undefined && e.target.value !== '') {
            setEditStartTime(e.target.value);
        }
    }

    const handleEditEndTime = (e) => {
        if (e.target.value != null && e.target.value !== undefined && e.target.value !== '') {
            setEditEndTime(e.target.value);
        }
    }

    const handleType = (e) => {
        setType(e.target.value);
    }

    const handleEditType = (e) => {
        setEditType(e.target.value);
    }

    const handleComments = (e) => {
        setComments(e.target.value);
    }

    const handleWorkDate = async(e) => {
        let dt = new Date();
        dt.setDate(dt.getDate() - 10);
        dt = moment(dt).format("YYYY-MM-DD");

        if (e.target.value <= dt) {
            setToastShowed(true);
            setToastMessage('You can only add entries for dates within last 10 days');
        } else if (e.target.value > moment(new Date()).format("YYYY-MM-DD")){
            setToastShowed(true);
            setToastMessage('You cannot add entries for a future date.');
        } else {
            setToastShowed(false);
            setTimesheetDate(e.target.value);
            let workDate = e.target.value;

            getTimeEntryRecords(c => c.and( c => [
                c.workAssignment__localId.eq(waId),
                c.pffsm__Start_Time__c.contains(workDate)
            ]));
        }
    }

    const handleEditComments = (e) => {
        setEditComments(e.target.value);
    }

    const closeToast = () => {
        setToastShowed(false);
        setToastMessage('');
    }

    const handleApproval = async () => {
        if (timesheetDate !== null && timesheetDate !== undefined && timesheetDate !== '') {
            const timesheets = await DataStore.query(timeSheetTable.model, w => w.and(w => [
                w.pffsm__Work_Date__c.eq(timesheetDate),
                w.pffsm__User__c.eq(user.sfUserId)
            ]));

            if (timesheets.length > 0) {
                for (const sheet of timesheets) {
                    if (sheet.pffsm__Status__c !== 'Clocked Out' && sheet.pffsm__Status__c  !== 'Approved') {
                        await DataStore.save(
                            timeSheetTable.model.copyOf(sheet, updated => {
                                updated.pffsm__Status__c = 'Clocked Out';
                                updated.doPush__custom = true;
                            }
                        ));

                        setToastShowed(true);
                        setToastMessage('Records submitted for approval');
                    } else {
                        setToastShowed(true);
                        setToastMessage('No records for Approval');
                    }
                }
            } else {
                setToastShowed(true);
                setToastMessage('No records for Approval');
            }

            getTimeEntryRecords();
        } else {
            setToastShowed(true);
            setToastMessage('Select Work Date to submit for Approval');
        }
    }

    const handleSave = async () => {
        if (startTime === undefined || EndTime === undefined || (type === undefined || type === '')) {
            setToastShowed(true);
            setToastMessage('Start Time , End Time and Type must have a value.')
            return;
        } else if (startTime >= EndTime){
            setToastShowed(true);
            setToastMessage('The start time should be before the end time.');
            return;
        } else {
            const hrs = validateStartEndTime("", startTime, EndTime);
            if (hrs < 0) {
                return;
            }

            // Find existing time sheet, create new time sheet if none exist.
            let timeSheet;
            const timeSheets = await DataStore.query(timeSheetTable.model, t => t.and( t => [
                t.pffsm__Work_Date__c.eq(timesheetDate),
                t.pffsm__User__c.eq(user.sfUserId)
            ]));

            if (timeSheets.length === 0) {
                const timeSheetName = (timeEntries.length >= 99 ? "TS-TMP" : timeEntries.length >= 9 ? "TS-TMP0" : "TS-TMP00") + (timeEntries.length + 1);
                timeSheet = await DataStore.save(new timeSheetTable.model({
                    Name: timeSheetName,
                    doPush__custom : true,
                    pffsm__Work_Date__c : timesheetDate,
                    pffsm__Status__c : 'Clocked In',
                    pffsm__User__c: user.sfUserId,
                    userName__custom: user.name,
                    sobject__custom: timeSheetTable.ref,
                }));

                setTimeSheets({...timeSheets, [timeSheet.id]: timeSheet})
            } else {
                timeSheet = timeSheets[0];
            }

            // Create time entry
            const timeEntryName = (timeEntries.length >= 99 ? "TE-TMP" : timeEntries.length >= 9 ? "TE-TMP0" : "TE-TMP00") + (timeEntries.length + 1);
            const addedTE = await DataStore.save(new timeEntryTable.model({
                pffsm__Time_Type__c: type,
                Name :timeEntryName,
                pffsm__Start_Time__c: startTime,
                pffsm__End_Time__c: EndTime,
                pffsm__Comments__c: comments,
                workAssignment__localId : waId,
                pffsm__Work_Date__c : timesheetDate,
                pffsm__Duration_h_min__c : hrs.toString(),
                pffsm__User__c: user.sfUserId,
                userName__custom: user.name,
                doPush__custom : true,
                sobject__custom: timeEntryTable.ref,
                timesheet__localId: timeSheet.id
            }));

            setTimeEntries([ addedTE, ...timeEntries ])
            setShowAddTime(false);
        }
    }

    const handleEditCancel = async (e) => {
        setEditId('');
        setShowAddTime(false);
        getTimeEntryRecords();
    }

    const handleEditSave = async (e) => {
        if (editStartTime === undefined || editEndTime === undefined) {
            setToastShowed(true);
            setToastMessage('Start Time , End Time must have a value.');
            return;
        } else if (editStartTime >= editEndTime){
            setToastShowed(true);
            setToastMessage('The start time should be before the end time.');
            return;
        } else {
            const momentStartValue = moment(timesheetDate + 'T' + editStartTime);
            const eventStartEditFormat = momentStartValue.utc().format("YYYY-MM-DDTHH:mm:00.000Z");

            const momentEndValue = moment(timesheetDate + 'T' + editEndTime);
            const eventEndEditFormat = momentEndValue.utc().format("YYYY-MM-DDTHH:mm:00.000Z");

            const hrs = validateStartEndTime(e, eventStartEditFormat, eventEndEditFormat);
            if (hrs < 0) {
                return;
            }

            const queriedTE = await DataStore.query(timeEntryTable.model, e)
            await DataStore.save(
                timeEntryTable.model.copyOf(queriedTE, updated => {
                    updated.pffsm__Start_Time__c = eventStartEditFormat;
                    updated.pffsm__End_Time__c = eventEndEditFormat;
                    updated.pffsm__Comments__c = editComments;
                    updated.pffsm__Time_Type__c = editType;
                    updated.pffsm__Duration_h_min__c = hrs.toString();
                    updated.doPush__custom = true;
                })
            );
        }

        setEditId('');
        setShowAddTime(false);
        getTimeEntryRecords();
    }

    const handleCancel = async () => {
        setShowAddTime(false);
    }

    const handleDelete = async (e) => {
        const entry = await DataStore.query(timeEntryTable.model, e);

        const updatedEntry = await DataStore.save(
            timeEntryTable.model.copyOf(entry, updated => {
                updated.doPush__custom = true;
                updated.timesheet = null;
                updated.timesheet__localId = null;
                updated.workAssignment = null;
                updated.workAssignment__localId = null;
            })
        );

        // Need to set timer or the delete mutation doesn't execute properly.
        setTimeout(() => {
            DataStore.delete(updatedEntry);
            getTimeEntryRecords();
        }, 5000);
    }

    const handleEditTime =  async (e) => {
        setEditId(e);
        const entry = await DataStore.query(timeEntryTable.model, e);

        let startTime = entry.pffsm__Start_Time__c;
        let localStartTime = moment.utc(new Date(startTime)).toDate();
        let eventStartEditFormat = moment(localStartTime).format('HH:mm');
        setEditStartTime(eventStartEditFormat);

        let endTime = entry.pffsm__End_Time__c;
        let localEndTime = moment.utc(new Date(endTime)).toDate();
        let eventEndEditFormat = moment(localEndTime).format('HH:mm');
        setEditEndTime(eventEndEditFormat);

        setEditComments(entry.pffsm__Comments__c);
        setEditType(entry.pffsm__Time_Type__c);
    }

    useEffect(() => {
        setNavbarOptions({
            title: "Time Tracker",
            page: "time-tracker-page",
            hasLeft: true,
            hasRight: true
        });

        getPicklistOptions(setTimeTypeOptions, "pffsm__Time_Entry__c", "pffsm__Time_Type__c", null, null)

        getWorkAssignments();
        getTimeEntryRecords();
    }, []);

    const dateTimeHelper  = (dateTimeValue) => {
        if (dateTimeValue !== undefined && dateTimeValue !== null) {
            let momentValue = moment(dateTimeValue);
            let eventStartFormat = momentValue.format("yyyy-MM-DD h:mm A");
            return eventStartFormat;
        }
    }

    const validateStartEndTime = (timeEntryId, start, end) => {
        let tempStartTime = new Date(start);
        let tempEndTime = new Date(end);

        for(let i = 0; i < timeEntries.length; i++){
            let timeEntry = timeEntries[i];
            if(timeEntryId === timeEntry.id) {
                continue;
            }

            let startTime = new Date(timeEntry.pffsm__Start_Time__c);
            let endTime = new Date(timeEntry.pffsm__End_Time__c);
            if((tempStartTime > startTime && tempStartTime <  endTime)
                || (tempEndTime > startTime && tempEndTime <  endTime)
                || (tempStartTime <= startTime && tempEndTime >= endTime)){
                setToastShowed(true);
                setToastMessage('There is an existing time entry within this range.');
                return -1;
            }
        };

        if ((tempEndTime.getTime() - tempStartTime.getTime()) > 0) {
            return Math.round(((tempEndTime.getTime() - tempStartTime.getTime())/(1000*60*60))*100)/100;
        }

        return 0;
    }

    const getWorkAssignments = async () => {
        setIsLoading(true);
        const workAssignment = await DataStore.query(waTable.model, waId);

        let eventStartFormat, eventStopFormat, assignmentStopTime
        if (workAssignment.pffsm__Scheduled_Start_Time__c) {
            const momentValue = moment(workAssignment.pffsm__Scheduled_Start_Time__c);
            eventStartFormat = momentValue.format("yyyy-MM-DD h:mm A");

            const startTime = momentValue.format("yyyy-MM-DD");
            setWaStartTime(startTime);
        }

        if (workAssignment.pffsm__Scheduled_Stop_Time__c) {
            const momentValue = moment(workAssignment.pffsm__Scheduled_Stop_Time__c);
            eventStopFormat = momentValue.format("yyyy-MM-DD h:mm A");
            assignmentStopTime = momentValue.format("yyyy-MM-DD");
        }

        const validateDateTime = moment(new Date()).format("yyyy-MM-DD");
        if (assignmentStopTime < validateDateTime) {
            setShowApproveBtn(true);
        } else {
            setShowApproveBtn(true);
        }

        setWaStatus(workAssignment.pffsm__Assignment_Status__c);
        setAssignedUser(workAssignment.pffsm__Assigned_User__c);

        setFlattenedWA([
            { value: workAssignment.Name, label: 'Name' , isLargeText: false},
            { value: workAssignment.pffsm__Assignment_Status__c, label: 'Status', isLargeText: false},
            { value: workAssignment.pffsm__WO_Description__c, label: 'WO Description' , isLargeText: true},
            { value: eventStartFormat , label: 'Scheduled Start Time', isLargeText: false },
            { value: eventStopFormat, label: 'Scheduled Stop Time', isLargeText: false },
        ]);
    }

    const getTimeEntryRecords = async(predicates) => {
        if (!predicates) {
            predicates = (c => c.workAssignment__localId.eq(waId))
        }

        const tes = await DataStore.query(timeEntryTable.model, predicates, { sort: s=> s.Name(SortDirection.DESCENDING) });

        let tsMap = {}
        for (const te of tes) {
            const ts = await DataStore.query(timeSheetTable.model, te.timesheet__localId);
            tsMap[te.timesheet__localId] = ts;
        }

        setTimeEntries(tes);
        setTimeSheets(tsMap)
        setIsLoading(false);
    }

    const addTimeEntries = async () =>{
        if (user.sfUserId !== assignedUser) {
            alert('Only Assigned User can add Time Entries to this WA');
        } else {
            let dtTime = moment(new Date()).format("yyyy-MM-DD");

            if (waStatus !== 'Schedule Confirmed') {
                setToastShowed(true);
                setToastMessage('No time entries can be added to this work assignment');
                return;
            } else if (dtTime < waStartTime) {
                setToastShowed(true);
                setToastMessage('You cannot add entries before the scheduled start time of an assignment');
                return;
            } else if (timesheetDate === null || timesheetDate === undefined || timesheetDate === '') {
                setToastShowed(true);
                setToastMessage('Select Work Date to create Time Entries');
                return;
            } else {
                setShowAddTime(true);
            }
        }
    }

    return (
        <div style={divStyle}>
            { isLoading ?
                <Spinner /> :
                <>
                    {
                        <Block className="space-y-6">
                            <BlockTitle className="text-2xl detail-text">Work Assignment Info</BlockTitle>
                            <List>
                                { flattenedWA.map((wa) =>(
                                        wa.isLargeText === false ?
                                        <ListItem title={wa.label} after={wa.value}/>:
                                        <div>
                                            <div className="shrink pl-4 text-list-title-ios">
                                                <label>{wa.label}</label>
                                            </div>
                                            <div className="col-span-2 shrink pl-4">
                                                <textarea defaultValue={wa.value}
                                                rows="2"
                                                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1
                                                block w-full sm:text-sm border border-gray-300 rounded-md
                                                dark:text-gray-300 dark:bg-gray-700" readOnly></textarea>
                                            </div>
                                        </div>
                                    ))
                                }
                                </List>
                        </Block>
                    }
                    {
                        showApproveBtn ?
                            <Fab className="fixed right-6-safe top-16-safe z-20" icon={ <MdAdd />} onClick={() => addTimeEntries()} /> :
                            null
                    }
                    {
                        <Fab className="fixed left-1/2 top-16-safe translate-x-15 z-20" icon={ <MdOutlineFactCheck />} onClick={() => handleApproval()} />
                    }

                    <Block className="space-y-6">
                        <div>
                            <List>
                                <ListInput label="Work Date"
                                            type="date" onChange={handleWorkDate} value={timesheetDate}/>
                            </List>
                        </div>
                    </Block>

                    {
                        showAddTime ?
                            <Block className="space-y-6">
                                <BlockTitle className="text-2xl detail-text">Add Time Entries</BlockTitle>
                                <List>
                                <ListInput
                                    label="Start Time"
                                    type="time" onChange={handleStartTime}
                                />
                                <ListInput
                                    label="End Time"
                                    type="time" onChange={handleEndTime}
                                />
                                <ListInput
                                    label="Type"
                                    type="select"
                                    onChange={handleType}
                                    dropdown>
                                        {timeTypeOptions.map((item) =>( <option value={item.value}>{item.label}</option>) )}
                                </ListInput>
                                <ListInput
                                    label="Comments"
                                    type="text"
                                    onChange={handleComments}
                                />
                                <div className="grid grid-cols-2 gap-3.5">
                                    <Button outline onClick={handleCancel}>Cancel</Button>
                                    <Button outline onClick={ handleSave }>Save</Button>
                                </div>
                                </List>
                            </Block>
                            : null
                    }

                    <Block className="space-y-6">
                        <BlockTitle className="text-2xl detail-text">Time Entries</BlockTitle>
                        { timeEntries && timeEntries.length > 0 ? timeEntries.map((wts) =>
                            <List>
                                <ListItem title="Name" after= {wts['Name']}/>
                                <ListItem title="Time Sheet" key ={wts['id']} after= {timeSheets[wts['timesheet__localId']]['Name']}/>
                                <ListItem title="TS Status" after= {timeSheets[wts['timesheet__localId']]['pffsm__Status__c']}/>
                                <ListItem title="Hours" after= {wts['pffsm__Duration_h_min__c']}/>

                                { editId === wts['id'] ?
                                    <>
                                        <ListInput label="Start Time" type="time" value={editStartTime} onChange={handleEditStartTime} />
                                        <ListInput label="End Time" type="time" value={editEndTime} onChange={handleEditEndTime}/>
                                        <ListInput label="Type" type="select" defaultValue={wts["pffsm__Time_Type__c"]} value={editType} onChange={handleEditType} dropdown>
                                            {timeTypeOptions.map((item) =>( <option value={item.value}>{item.label}</option>))}
                                        </ListInput>

                                        <div>
                                            <div className="shrink pl-4 text-list-title-ios">
                                                <label>
                                                Comments
                                                </label>
                                            </div>
                                            <div className="col-span-2 shrink pl-4">
                                                <textarea defaultValue={wts['pffsm__Comments__c']}
                                                onInput={handleEditComments} id="comments" name="comments"
                                                rows="2"
                                                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md dark:text-gray-300 dark:bg-gray-700" ></textarea>
                                            </div>
                                        </div>

                                        <Button outline onClick={() => handleEditCancel(wts['id'])}>Cancel</Button>
                                        <Button outline onClick={() => handleEditSave(wts['id'])}>Save</Button>
                                    </> :
                                    <>
                                        <ListItem title="Start Time" after= {dateTimeHelper(wts['pffsm__Start_Time__c'])} />
                                        <ListItem title="End Time" after= {dateTimeHelper(wts['pffsm__End_Time__c'])} />
                                        <ListItem title="Type" after= {wts['pffsm__Time_Type__c']} />

                                        <div>
                                            <div className="shrink pl-4 text-list-title-ios">
                                                <label>Comments</label>
                                            </div>
                                            <div className="col-span-2 shrink pl-4">
                                                <textarea value={wts['pffsm__Comments__c']}
                                                rows="2"
                                                className="shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md dark:text-gray-300 dark:bg-gray-700" readOnly></textarea>
                                            </div>
                                        </div>

                                        <Button outline onClick={() => handleEditTime(wts['id'])}>Edit</Button>
                                        <Button outline onClick={() => handleDelete(wts['id'])}>Delete</Button>
                                    </>
                                }
                            </List>) :
                            <div className="text-center mt-1.5">No Records Found</div>
                        }
                    </Block>

                    <Toast
                        position="center"
                        opened={toastShowed}
                        button={
                            <Button clear inline onClick={() => closeToast()}>
                                Close
                            </Button>}>
                        <div className="shrink">{toastMessage}</div>
                    </Toast>
                </>
            }
        </div>
    );
}