import { BlockTitle, Block } from "konsta/react";
import { useState } from "react";

import CardInfo from "../CardInfo/CardInfo";

import { useRecentItems } from "../../Hooks/Hooks";

export default function RecentItems(props) {
    const recentItems = useRecentItems(props?.type);
    const title = useState(props?.title ?? 'Recent Records');

    return(
        <Block>
            { recentItems?.length > 0 ?
                <>
                    <BlockTitle withBlock={false}>{title}</BlockTitle>
                    {recentItems.map(item => <CardInfo item={item} type={item?.attributes?.type} key={item.Id}/>)}
                </> :
                    <Block>
                    <BlockTitle>You do not have any recent items</BlockTitle>
                </Block>
            }
        </Block>
    )
}