import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Toast, Button, Page, Navbar, Popup, Block, Link } from 'konsta/react';
import { DataStore, SortDirection } from "aws-amplify";

import { dynamoTables } from '../../../dbTables/syncObjects';
import { pageRoutes } from "../../../routes/routes";
import { getPicklistOptions } from "../../Utils/Util";
import { useSignedInUser } from "../../Hooks/Hooks";

import Lookup from "../../Utils/Lookup/Lookup";
import Spinner from "../../Utils/Loading/Spinner";

const recordTypeName = 'Work Request';
const status = "Request";

export default function WorkOrderNewInfo() {
    const [ searchParams ] = useSearchParams();
    const assetId = searchParams?.get('assetId');
    const assetLocalId = searchParams?.get("assetLocalId")
    const assetName = searchParams?.get('assetName');
    const parentWOId = searchParams?.get('parentWOId');
    const parentLocalWOId = searchParams?.get("parentLocalWOId");
    const parentWOName = searchParams?.get('parentWOName');

    const [ assetTagName, setAssetTagName ] = useState(assetName ? assetName : '');
    const [ assetTagId, setAssetTagId ] = useState(assetId ? assetId : '');
    const [ assetTagLocalId, setAssetTagLocalId ] = useState(assetLocalId ? assetLocalId : "");
    const [ description, setDescription ] = useState('');
    const [ detailComments, setDetailComments ] = useState('');
    const [ woType, setWOType ] = useState('Corrective');
    const [ subType, setSubType ] = useState('');
    const [ priority, setPriority ] = useState('Medium');
    const [ dueDate, setDueDate ] = useState('');
    const [ workOrderName, setWorkOrderName ] = useState('');
    const [ newWoId, setNewWoId ] = useState('');

    const [ toastShowed, setToastShowed ] = useState(false);
    const [ popupOpened, setPopupOpened ] = useState(false);
    const [ isSaving, setIsSaving ] = useState(false);

    const [ woTypeOptions, setWOTypeOptions ] = useState([]);
    const [ woPriorityOptions, setWOPriorityOptions ] = useState([]);
    const [ woSubTypeOptions, setWOSubTypeOptions ] = useState([]);

    const navigate = useNavigate();
    const [ user ] = useSignedInUser();

    const woNav = pageRoutes.find((route) => route.id === "work-order-page")?.nav;

    const workOrderTable = dynamoTables.find( table => table.ref === 'pffsm__smWork_Order__c' );
    const assTable = dynamoTables.find((table) => table.ref === "pffsm__smEquipment__c");

    useEffect(() => {
      getPicklistOptions(setWOTypeOptions, "pffsm__smWork_Order__c", "pffsm__WO_Type__c", null, null, null);
      getPicklistOptions(setWOPriorityOptions, "pffsm__smWork_Order__c", "pffsm__Priority__c", null, null, null);
      getPicklistOptions(setWOSubTypeOptions, "pffsm__smWork_Order__c", "pffsm__Sub_Type__c", null, null, null);
    }, [])

    const saveWorkOrder = async () => {
        setIsSaving(true);

        if(dueDate == null || dueDate.trim().length === 0
          || assetTagName == null || assetTagName.trim().length === 0
          || description == null || description.trim().length === 0
          || detailComments == null || detailComments.trim().length === 0) {

          setToastShowed(true);
          setIsSaving(false);
          return;
        }

        const workOrders = await DataStore.query(workOrderTable.model, c => c.Name.beginsWith("WO-TMP"), {
          sort: s=> s.Name(SortDirection.DESCENDING)
        });

        const assets = await DataStore.query(assTable.model, asst => asst.or(asst => [
          asst.id.eq(assetTagLocalId),
          asst.sfId__custom.eq(assetTagId)
        ]));

        let assetDescription = '';
        let assetStreet = '';
        let assetCity = '';
        let assetState = '';
        let assetPostal = '';
        if (assets.length > 0) {
            const asset = assets[0];
            assetDescription = asset.pffsm__Description__c;
            assetStreet = asset.pffsm__Street__c;
            assetCity = asset.pffsm__City__c;
            assetState = asset.pffsm__State_Province__c;
            assetPostal = asset.pffsm__Zip_Postal_Code__c;
        }

        const woName = (workOrders.length >= 99 ? "WO-TMP" : workOrders.length >= 9 ? "WO-TMP0" : "WO-TMP00") + (workOrders.length + 1);
        const newWO = await DataStore.save(new workOrderTable.model({
            Name : woName,
            pffsm__Equipment__c : assetTagId,
            pffsm__Equip_Description__c : assetDescription,
            pffsm__Street_Address__c : assetStreet,
            pffsm__City__c : assetCity,
            pffsm__State_Province__c : assetState,
            pffsm__Zip_Postal_Code__c : assetPostal,
            pffsm__Asset_ID_Text__c : assetTagName,
            pffsm__Parent_Work_Order__c : parentWOId,
            parentWorkOrder__localId: parentLocalWOId,
            OwnerName : user.name,
            OwnerId : user.sfUserId,
            pffsm__Work_Order_Record_Type__c: recordTypeName,
            pffsm__Description__c: description,
            pffsm__Detailed_Comments__c: detailComments,
            pffsm__WO_Type__c: woType,
            pffsm__Sub_Type__c: subType,
            pffsm__Status__c: status,
            pffsm__Priority__c: priority,
            pffsm__Due_Date__c: dueDate,
            equipment__localId: assetTagLocalId,
            sobject__custom: workOrderTable.ref,
            doPush__custom: true
        }));

        setNewWoId(newWO.id);
        setWorkOrderName(woName);
        setIsSaving(false);
        setPopupOpened(true);
    }

    const closeToast = () => {
      setToastShowed(false);
    }

    const closePopup = async() => {
      setPopupOpened(false);
      navigate(`${woNav}/detail/${newWoId}`);
    }

    const cancel = () => {
      goBack();
    }

    const goBack = () => {
      navigate(-1);
    }

    const selectItem = (record) => {
      setAssetTagName(record.Name);
      setAssetTagId(record.sfId__custom);
      setAssetTagLocalId(record.Id);
    }

    return (
      <div>
        { isSaving ? <Spinner /> : null }
        <div className="grid grid-cols-4 gap-3">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Record Type:
            </label>
          </div>
          <div className="col-span-3">
            {recordTypeName}
          </div>
        </div>
        <div className="grid grid-cols-4 gap-3">
          <div>
            <label htmlFor="assetTag" className="block text-sm font-medium text-gray-700">
              Asset Tag:
            </label>
          </div>
          <div className="col-span-3">
            <Lookup sobject="pffsm__smEquipment__c" id={assetLocalId} selectItem={selectItem}/>
          </div>
        </div>
        {parentWOName?
        <div className="grid grid-cols-4 gap-3">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Parent WO:
            </label>
          </div>
          <div className="col-span-3">
            {parentWOName}
          </div>
        </div>
        : null}
        <div className="hidden sm:block" aria-hidden="true">
          <div className="py-5">
            <div className="border-t border-gray-200"></div>
          </div>
        </div>
        <div className="grid grid-cols-4 gap-3">
          <div>
            <label htmlFor="dueDate" className="block text-sm font-medium text-gray-700">
              Due Date:
            </label>
          </div>
          <div className="col-span-3">
            <input type="date" required value={dueDate} onInput={(e)=> setDueDate(e.target.value)} name="dueDate" id="dueDate" className="focus:ring-blue-500 focus:border-blue-500 flex-1 block w-full rounded-none rounded-r-md sm:text-sm border-gray-300 dark:text-gray-300 dark:bg-gray-700" />
          </div>
        </div>
        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">
            Description:
          </label>
          <div className="mt-1">
            <textarea value={description}  onInput={(e) => setDescription(e.target.value) } id="description" name="description" rows="3" className="shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md dark:text-gray-300 dark:bg-gray-700" placeholder="Ex: Weekly Site Inspection"></textarea>
          </div>
        </div>
        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">
          Prob. Desc./Scope of Work:
          </label>
          <div className="mt-1">
            <textarea value={detailComments}  onInput={(e) => setDetailComments(e.target.value) } id="description" name="description" rows="3" className="shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md dark:text-gray-300 dark:bg-gray-700" placeholder="Ex: Weekly Site Inspection"></textarea>
          </div>
        </div>
        <div>
          <label htmlFor="woType" className="block text-sm font-medium text-gray-700">
            WO type
          </label>
          <div className="mt-1">
            <select value={woType}  onChange={(e) => setWOType(e.target.value) } id="woType" name="woType"  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md dark:text-gray-300 dark:bg-gray-700" >
              {woTypeOptions.map((item) =>(<option value={item.value}>{item.label}</option>) )}
            </select>
          </div>
        </div>
        <div>
          <label htmlFor="subType" className="block text-sm font-medium text-gray-700">
            Sub Type
          </label>
          <div className="mt-1">
            <select value={subType}  onChange={(e) => setSubType(e.target.value) } id="subType" name="subType"  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md dark:text-gray-300 dark:bg-gray-700" >
              {woSubTypeOptions.map((item) =>(<option value={item.value}>{item.label}</option>) )}
            </select>
          </div>
        </div>
        <div>
          <label htmlFor="status" className="block text-sm font-medium text-gray-700">
            Status
          </label>
          <div className="mt-1">
            <select value={status} disabled id="status" name="status"  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md dark:text-gray-300 dark:bg-gray-700" >
              <option value={status}>Request</option>
            </select>
          </div>
        </div>
        <div>
          <label htmlFor="priority" className="block text-sm font-medium text-gray-700">
            Priority
          </label>
          <div className="mt-1">
            <select value={priority}  onChange={(e) => setPriority(e.target.value) } id="priority" name="priority"  className="shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md dark:text-gray-300 dark:bg-gray-700" >
            {woPriorityOptions.map((item) =>(<option value={item.value}>{item.label}</option>) )}
            </select>
          </div>
        </div>
        <div className="px-4 py-3 bg-gray-50 dark:bg-gray-600  text-right sm:px-6 flex justify-end">
          <button onClick={()=> saveWorkOrder()} className="mx-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            <span>Save</span>
          </button>
          <button onClick={()=>cancel()} className="mx-2 items-center inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
            <span>Cancel</span>
          </button>
        </div>

        <Popup opened={popupOpened} onBackdropClick={() => setPopupOpened(false)} >
          <Page>
            <Navbar
              title="Successful"
              right={
                <Link navbar onClick={() => closePopup()}>
                  Close
                </Link>
              }
            />
            <Block className="space-y-4">
              <p>
              This {workOrderName} has been created successfully!
              </p>
            </Block>
          </Page>
        </Popup>
        <Toast
          position="center"
          opened={toastShowed}
          button={
            <Button clear inline onClick={() => closeToast()}>
              Close
            </Button>
          }
        >
          <div className="shrink">Asset Tag, Due Date, Description and Prob. Desc./Scope of Work must have a value.</div>
        </Toast>
    </div>

    )
}