import { useState, useEffect } from "react"
import { Page, Block} from "konsta/react";
import { useSetRecoilState } from "recoil";

import GlobalSearch from "../../Utils/GlobalSearch/GlobalSearch";
import SearchResults from "../../Utils/SearchResults/SearchResults";
import RecentItems from "../../Utils/RecentItems/RecentItems";

import { globalSearchTables } from "../../../dbTables/syncObjects";
import { labels } from "../../../dbTables/labels";
import { pageRoutes } from '../../../routes/routes';
import { globalNavbarOptions } from "../../State/Global";

export default function WorkTaskHome(){
    const workTaskFieldLabels = labels.pffsm__smWO_Task__c;
    const wtNav = pageRoutes.find((route) => route.id === "work-task-page")?.nav;

    const workTaskTable = globalSearchTables.filter(searchTable => searchTable.ref === "pffsm__smWO_Task__c");
    const wtSearchFields = workTaskTable.map(x=>{return {...x, fields: [
        "Name",
        "pffsm__WO_Description__c",
        "pffsm__Comments__c",
        "pffsm__Asset_ID__c",
        "pffsm__Asset_Description__c",
        "pffsm__Planned_Hours__c"
    ]}});

    const [ query, setQuery ] = useState('');
    const setNavbarOptions = useSetRecoilState(globalNavbarOptions);

    useEffect(() => {
        setNavbarOptions({
            title: "Work Tasks",
            page: "work-task-page",
            hasLeft: false,
            hasRight: false
        });
    }, []);

    const userInputChange = (e) => {
        setQuery(e.target.value);
    }

    return (
        <Page className="mt-2 flex flex-col">
            <Block className="w-full items-center flex flex-col hairline-b p-4 hairline-gray-200">
                <GlobalSearch query={query} userInputChange={userInputChange} />
            </Block>
            { query.trim().length > 2 ?
                <SearchResults nav={ wtNav } searchFields={wtSearchFields} queryString = {query} fieldLabels={workTaskFieldLabels}/> :
                <RecentItems type={["pffsm__smWO_Task__c"]}/>
            }
        </Page>
    );
}