import { useEffect } from "react"
import { Page } from 'konsta/react';
import { useSetRecoilState } from "recoil";

import { globalNavbarOptions } from "../../State/Global";

export default function Notifications(){

  const setNavbarOptions = useSetRecoilState(globalNavbarOptions);

  useEffect(() => {
    setNavbarOptions({
      title: "Notifications",
      page: "notification-page",
      hasLeft: false,
      hasRight: false
    });
  }, []);

  return (
    <Page className="mt-8">
    </Page>
  )
}