import { SmWorkOrderc, SmWorkAssignmentc, SmAssetTagc, SmOperationalEventc, SmWorkTaskc, SmRecentItems, AuthUser, SmWorkTaskStepc, SmFile, SmTimeEntryc, SmDailyTimeSheetc, ObjectTypeSetting, SmFileLocal, SmPicklistValues, ObjectSyncTo} from '../models'
import { labels } from './labels';

const dynamoTables= [
    {name: SmWorkOrderc.Name, model: SmWorkOrderc, fields: ['pffsm__Description__c', 'Name'], labels: labels.pffsm__smWork_Order__c, ref: 'pffsm__smWork_Order__c', nav: '/work-order/detail/', isModifiable: true},
    {name: SmAssetTagc.Name, model: SmAssetTagc, fields: ['pffsm__Description__c', 'Name'], labels: labels.pffsm__smEquipment__c, ref: 'pffsm__smEquipment__c', nav: '/asset/detail/', isModifiable: false},
    {name: SmWorkAssignmentc.Name, model: SmWorkAssignmentc, fields: ['Name'], labels: labels.pffsm__WO_Assignment__c, ref: 'pffsm__WO_Assignment__c', nav: '/work-assignment/detail/', isModifiable: true},
    {name: SmOperationalEventc.Name, model: SmOperationalEventc, fields: ['Name'], labels: labels.pffsm__Operational_Event__c, ref: 'pffsm__Operational_Event__c', nav: '/operational-event/detail/', isModifiable: false},
    {name: SmWorkTaskc.Name, model: SmWorkTaskc, fields: ['pffsm__Comments__c'], labels: labels.pffsm__smWO_Task__c, ref: 'pffsm__smWO_Task__c', nav: '/work-task/detail/', isModifiable: true},
    {name: SmWorkTaskStepc.Name, model: SmWorkTaskStepc, fields: ['Name'], labels: labels.pffsm__Work_Task_Step__c, ref: 'pffsm__Work_Task_Step__c', isModifiable: true},
    {name: SmFile.Name, model: SmFile, fields: ['Title'], labels: labels.ContentVersion, ref: 'ContentVersion', isModifiable: true},
    {name: SmTimeEntryc.Name, model: SmTimeEntryc, fields: ['Name'], labels: labels.TimeEntry, ref: 'pffsm__Time_Entry__c', isModifiable: true},
    {name: SmDailyTimeSheetc.Name, model: SmDailyTimeSheetc, fields: ['Name'], labels: labels.TimeSheet, ref: 'pffsm__Daily_Time_Sheet__c', isModifiable: true},
    {name: SmRecentItems.Name, model: SmRecentItems, fields: ['Name'], ref: 'recent_items', isModifiable: false},
    {name: AuthUser.Name, model: AuthUser, fields: ['Name'], ref: 'auth_user', isModifiable: false},
    {name: ObjectTypeSetting.Name, model: ObjectTypeSetting, fields: [], labels: {}, ref: "object_type_setting", isModifiable: false},
    {name: SmFileLocal.Name, model: SmFileLocal, fields: [], labels: {}, ref: "sm_file_local", isModifiable: false},
    {name: SmPicklistValues.Name, model: SmPicklistValues, fields: [], labels: {}, ref: "sm_picklist_values", isModifiable: false},
    {name: ObjectSyncTo.Name, model: ObjectSyncTo, fields: [], labels: {}, ref: "object_sync_to", isModifiable: false}
]

const globalSearchTables = [
    {name: SmWorkOrderc.name, model: SmWorkOrderc, fields: ['pffsm__Description__c', 'Name', 'id', 'sfId__custom', 'pffsm__Equip_Description__c'], operator: 'contains', results: [], label:'Work Orders', ref: 'pffsm__smWork_Order__c' },
    {name: SmAssetTagc.name, model: SmAssetTagc, fields: ['pffsm__Description__c', 'Name', 'id', 'sfId__custom'], operator: 'contains', results: [], label:'Assets', ref: 'pffsm__smEquipment__c'  },
    {name: SmWorkAssignmentc.name, model: SmWorkAssignmentc, fields: ['Name', 'pffsm__WO_Task_Description__c', 'id', 'sfId__custom'], operator: 'contains', results: [], label:'Work Assignments', ref: 'pffsm__WO_Assignment__c'  },
    {name: SmWorkTaskc.name, model: SmWorkTaskc, fields: ['pffsm__Comments__c', 'Name', 'id', 'sfId__custom'], operator: 'contains', results: [], label:'Work Tasks', ref: 'pffsm__smWO_Task__c' },
    {name: SmOperationalEventc.name, model: SmOperationalEventc, fields: ['Name', 'id', 'sfId__custom'], operator: 'contains', results: [], label:'Operational Events', ref: 'pffsm__Operational_Event__c' },
    
  ]

export { dynamoTables, globalSearchTables }
