import { Toolbar, Tabbar, TabbarLink, Icon } from 'konsta/react';
import { leftPaneOpenState } from '../../State/Global'
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { dockedFooterRoutes } from '../../../routes/routes';
export default function DockedFooter(){
  let navigate = useNavigate();
  const isTop = false ;
  const setLeftPanelOpened = useSetRecoilState(leftPaneOpenState);

  const action = (nav) => {
    (nav==='menu') ? setLeftPanelOpened(true) : navigate(`/${nav}`);
    
  }
    return (
        <footer className="ui sticky bottom z-10" aria-label="Utility Bar">
          <Toolbar top={isTop} className={`left-0 ${ isTop
            ? 'ios:top-11-safe material:top-14-safe sticky'
            : 'bottom-0 fixed' } w-full`} >
              <Tabbar labels className="left-0 bottom-0 fixed flex flex-row">
              {
                dockedFooterRoutes.map( route => 
                  <TabbarLink
                  key={route.nav}
                    onClick={(e)=> action(`${route.nav}`) }
                    icon={
                      <Icon
                        ios={route.icon}
                        material={route.icon}
                      />
                    }
                    label={route.label}
                  />
                  )
              }
              </Tabbar>
          </Toolbar>
</footer>
    )
}