import { useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { DataStore, SortDirection } from "aws-amplify";
import { useParams } from 'react-router-dom';
import { Block, BlockTitle, List, ListButton, ListItem, Card, Fab} from "konsta/react";
import { confirm } from "react-confirm-box";
import { FcEmptyFilter } from "react-icons/fc";
import { MdDelete } from 'react-icons/md';

import './InspectionForm.css';

import Spinner from "../../Utils/Loading/Spinner"

import { globalNavbarOptions, fileAdded } from "../../State/Global";
import { dynamoTables } from "../../../dbTables/syncObjects";
import { getBase64, getPicklistOptions } from "../../Utils/Util";
import DOMPurify from "dompurify";

export default function InspectionForm(){
    const { id } = useParams();

    const setNavbarOptions = useSetRecoilState(globalNavbarOptions);
    const setFileAdded = useSetRecoilState(fileAdded);

    const wtStepTable = dynamoTables.find((table) => table.ref === "pffsm__Work_Task_Step__c");
    const wtTable = dynamoTables.find((table) => table.ref === "pffsm__smWO_Task__c");
    const cvTable = dynamoTables.find( table => table.ref === 'ContentVersion' );
    const localFileTable = dynamoTables.find( table => table.ref === 'sm_file_local' );

    const divStyle = {
        width: '90%',
        height: '90%',
        overflow: 'scroll',
        margin: 'auto'
    };

    const [ showFilter, setShowFilter ] = useState(false);
    const [ data, setData ] = useState([]);
    const [ showFile, setShowFile ] = useState([]);
    const [ fileResults, setFileResults ] = useState([]);
    const [ selectedFiles, setSelectedFiles ] = useState([]);

    const [ isLoading, setIsLoading ] = useState(true);

    const [ assembly, setAssembly ] = useState('');
    const [ subAssembly, setSubAssembly ] = useState('');
    const [ notComplete, setNotComplete ] = useState(false);

    const [ assemblyOptions, setAssemblyOptions ] = useState([]);
    const [ subAssemblyOptions, setSubAssemblyOptions ] = useState([]);
    const [ statusOptions, setStatusOptions ] = useState([]);

    const changeAssemblyAction = async(e) => {
        setIsLoading(true);
        setAssembly(e.target.value);
        setSubAssembly("");
    }

    const changeSubAssemblyAction = async(e) => {
        setIsLoading(true);
        setSubAssembly(e.target.value);
    }

    const setCompleteFilter = async(checked) => {
        setIsLoading(true);
        setNotComplete(checked);
    }

    const loadRecord =  async(assembly, subAssembly, notComplete) => {
        let conditions = [{ field: "workTask__localId", predicate: "eq", value: id}];
        if (assembly !== "") {
            conditions.push({field: "pffsm__Assembly__c", predicate: "eq", value: assembly });
        }

        if (subAssembly !== "") {
            conditions.push({field: "pffsm__Sub_Assembly__c", predicate: "eq", value: subAssembly });
        }

        if (notComplete) {
            conditions.push({field: "pffsm__Complete__c", predicate: "eq", value: !notComplete });
        }

        const criteria = (wts => conditions.map(condition => wts[condition.field][condition.predicate](condition.value)));
        const results = await DataStore.query(wtStepTable.model, wts => wts.and(criteria), { sort: s => s.pffsm__Sequence__c(SortDirection.ASCENDING) });

        let updatedResults = [];
        for (const result of results) {
            if (result.pffsm__Additional_Details__c) {
                let updatedResult = { ...result }
                updatedResult.pffsm__Additional_Details__c = DOMPurify.sanitize(result.pffsm__Additional_Details__c);

                updatedResults.push(updatedResult);
            } else {
                updatedResults.push(result);
            }
        }

        setData(updatedResults);

        if (assembly === "") {
            setAssemblyOptions(getAssemblyList("pffsm__Assembly__c", results));
            setSubAssemblyOptions(getAssemblyList("pffsm__Sub_Assembly__c", []));
        }

        if (assembly !== "" && subAssembly === "") {
            setSubAssemblyOptions(getAssemblyList("pffsm__Sub_Assembly__c", results));
        }

        let files = []
        let showFileVals = [];
        let selectedFileVals = [];

        for (const item of results) {
            files.push(await DataStore.query(cvTable.model, wts => wts.linkedEntity__localId.eq(item.id), {
                sort: s => s.updatedAt(SortDirection.DESCENDING)
            }));

            showFileVals.push(false);
            selectedFileVals.push([]);
        }

        setShowFile(showFileVals);
        setFileResults(files);
        setSelectedFiles(selectedFileVals);

        setIsLoading(false);
    }

    useEffect(() => {
        setNavbarOptions({
            title: "Inspection Form",
            page: "inspection-form-page",
            hasLeft: true,
            hasRight: true
        });

        getPicklistOptions(setStatusOptions, "pffsm__Work_Task_Step__c", "pffsm__Status__c", null, null);
    }, [])

    useEffect(() => {
        loadRecord(assembly, subAssembly, notComplete)
    }, [ assembly, subAssembly, notComplete ])

    useEffect(() => {
        loadRecord("", "", false)
    }, [ id ])

    const getAssemblyList = (field, items) => {
        let assemblyChecker = [ "" ];
        let assemblies = [{value: "", label: "--None--"}];

        for (const item of items) {
            const val = item[field];

            if (val && !assemblyChecker.includes(val)) {
                assemblyChecker.push(val);
                assemblies.push({ value: val, label: val });
            }
        }

        return assemblies;
    }

    const setCompleteValue = async(info, idx) => {
        const wtStep = await DataStore.query(wtStepTable.model, data[idx].id);
        const updatedWtStep = await DataStore.save(
            wtStepTable.model.copyOf(wtStep, updated => {
                updated.pffsm__Complete__c = info;
                updated.doPush__custom = true;
            })
        );

        updateData(idx, updatedWtStep);

        let completeCount = 0;
        for (const dataVal of data) {
            if ((dataVal.id === updatedWtStep.id && info) ||
                (dataVal.id !== updatedWtStep.id && dataVal.pffsm__Complete__c)) {
                completeCount++;
            }
        }

        const workTask = await DataStore.query(wtTable.model, id);
        await DataStore.save(
            wtTable.model.copyOf(workTask, updated => {
                updated.pffsm__Percent_Complete__c = (completeCount / data.length) * 100
                updated.doPush__custom = true
            })
        );
    }

    const setCommentsValue = async(info, idx) => {
        const wtStep = await DataStore.query(wtStepTable.model, data[idx].id);
        const updatedWtStep = await DataStore.save(
            wtStepTable.model.copyOf(wtStep, updated => {
                updated.pffsm__Comments__c = info;
                updated.doPush__custom = true;
            })
        );

        updateData(idx, updatedWtStep);
    }

    const setStatus = async(info, idx) => {
        const wtStep = await DataStore.query(wtStepTable.model, data[idx].id);
        const updatedWtStep = await DataStore.save(
            wtStepTable.model.copyOf(wtStep, updated => {
                updated.pffsm__Status__c = info;
                updated.doPush__custom = true;
            })
        );

        updateData(idx, updatedWtStep);
    }

    const updateData = (idx, updatedWtStep) => {
        let wtStep = { ...updatedWtStep }
        wtStep.pffsm__Additional_Details__c = DOMPurify.sanitize(updatedWtStep.pffsm__Additional_Details__c);

        let updatedData = [ ...data ];
        updatedData[idx] = wtStep;

        setData(updatedData);
    }

    const changeHandler = (event, idx) => {
        let updatedSelectedFiles = [ ...selectedFiles ]
        updatedSelectedFiles[idx] = [ ...selectedFiles[idx], ...event.target.files ];

        setSelectedFiles(updatedSelectedFiles);
	};

    const handleSubmission = async (idx) => {
        setIsLoading(true);

        let addedFiles = [];
        for (const file of selectedFiles[idx]) {
            const fileResult = await getBase64(file);
            const cvFile = await DataStore.save(new cvTable.model({
                doPush__custom: true,
                sobject__custom: cvTable.ref,
                Title: file.name,
                linkedEntity__localId: data[idx].id,
                doFetchFileData__custom: false,
            }));

            await DataStore.save(new localFileTable.model({
                versionData: fileResult,
                linkedFileId: cvFile.id
            }));

            addedFiles.push(cvFile);
        }

        let updatedFileResults = [ ...fileResults ];
        updatedFileResults[idx] = [ ...addedFiles, ...updatedFileResults[idx] ];
        setFileResults(updatedFileResults);

        let updatedSelectedFiles = [ ...selectedFiles ];
        updatedSelectedFiles[idx] = [];
        setSelectedFiles(updatedSelectedFiles);

        setFileAdded(true);
        setIsLoading(false);
	};

    const handleDelete = async(e, idx, fileIdx) => {
        const deleteAction = await confirm("Are you sure to delete this file?");
        if (!deleteAction) return;

        const file = await DataStore.query(cvTable.model, e);
        const localFiles = await DataStore.query(localFileTable.model, f => f.linkedFileId.eq(file.id));

        const updatedFile = await DataStore.save(cvTable.model.copyOf(file, u => {
            u.doPush__custom = true
        }));

        // Need to set timer or the delete mutation doesn't execute properly.
        setTimeout(() => {
            DataStore.delete(updatedFile);
        }, 5000);

        for (const localFile of localFiles) {
            DataStore.delete(localFile);
        }

        let updatedFileResults = [ ...fileResults ];
        updatedFileResults[idx].splice(fileIdx, 1);
        setFileResults(updatedFileResults);
    };

    const showUploadFiles = (idx) => {
        let updatedShowFile = [ ...showFile ];
        updatedShowFile[idx] = !showFile[idx];
        setShowFile(updatedShowFile);
    }

    const showFilterOption = () => {
        setShowFilter(!showFilter);
    }

    return (
        <div style={divStyle}>
            { isLoading ? <Spinner /> : null}
            { showFilter ?
                <div className="searchForm py-2">
                    <div className="grid grid-cols-3 gap-3 inspectionItem">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Assembly:
                            </label>
                        </div>
                        <div className="col-span-2">
                            <select value={assembly} onChange={(e) => changeAssemblyAction(e)} className="shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md dark:text-gray-300 dark:bg-gray-700 pl-4-safe shrink font-semibold">
                                {assemblyOptions.map((item) => <option value={item.value}>{item.label}</option> )}
                            </select>
                        </div>
                    </div>

                    <div className="grid grid-cols-3 gap-3 inspectionItem">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Sub Assembly:
                            </label>
                        </div>
                        <div className="col-span-2">
                            <select value={subAssembly} onChange={(e) => changeSubAssemblyAction(e)} className="shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md dark:text-gray-300 dark:bg-gray-700 pl-4-safe shrink font-semibold">
                                {subAssemblyOptions.map((item) => <option value={item.value}>{item.label}</option> )}
                            </select>
                        </div>
                    </div>

                    <div className="grid grid-cols-3 gap-3 inspectionItem">
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                    Not Completed:
                            </label>
                        </div>
                        <div className="col-span-2">
                            <input type="checkbox" onChange={(e) => setCompleteFilter(e.target.checked)}  />
                        </div>
                    </div>
                </div> : null
            }

            <Fab className="fixed right-4-safe top-12-safe z-20" icon={ <FcEmptyFilter /> } onClick={() => showFilterOption()} />

            { data?.length > 0 ? data.map((wts,idx) =>
                <div>
                    <Block>
                        <Card className="inspectionfrom">
                            <div className="grid grid-cols-3 gap-3 inspectionItem">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Sequence
                                    </label>
                                </div>
                                <div className="col-span-2">
                                    {wts['pffsm__Sequence__c']}
                                </div>
                            </div>
                            <div className="grid grid-cols-3 gap-3 inspectionItem">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Work Task Step
                                    </label>
                                </div>
                                <div className="col-span-2">
                                    {wts['Name']}
                                </div>
                            </div>
                            <div className="grid grid-cols-3 gap-3 inspectionItem">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Complete
                                    </label>
                                </div>
                                <div className="col-span-2">
                                    <input type="checkbox" onChange={(e) => setCompleteValue(e.target.checked, idx)} checked={ wts["pffsm__Complete__c"] ?? false }/>
                                </div>
                            </div>
                            <div className="grid grid-cols-3 gap-3 inspectionItem">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Critical Inspection
                                    </label>
                                </div>
                                <div className="col-span-2">
                                    <input type="checkbox"  checked={wts['pffsm__Critical_Inspection__c']} disabled/>
                                </div>
                            </div>
                            <div className="grid grid-cols-3 gap-3 inspectionItem">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Status
                                    </label>
                                </div>
                                <div className="col-span-2">
                                    <select value={wts["pffsm__Status__c"] ?? ""} onChange={(e) => setStatus(e.target.value, idx)} className="shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md dark:text-gray-300 dark:bg-gray-700 pl-4-safe shrink font-semibold"  >
                                        {statusOptions.map((item) => <option value={item.value}>{item.label}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="grid grid-cols-3 gap-3 inspectionItem">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Additional Details
                                    </label>
                                </div>
                                <div className="col-span-2">
                                    <div dangerouslySetInnerHTML={{__html: wts['pffsm__Additional_Details__c']}} />
                                </div>
                            </div>
                            <div className="grid grid-cols-3 gap-3 inspectionItem">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Comments
                                    </label>
                                </div>
                                <div className="col-span-2">
                                    <textarea value={wts['pffsm__Comments__c'] ?? ""}  onChange={(e) => setCommentsValue(e.target.value, idx)} id={'comments' + wts['id']} rows="2" className="shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md dark:text-gray-300 dark:bg-gray-700" ></textarea>
                                </div>
                            </div>
                            <div className="grid grid-cols-3 gap-3 inspectionItem">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Assembly
                                    </label>
                                </div>
                                <div className="col-span-2">
                                    {wts['pffsm__Assembly__c']}
                                </div>
                            </div>
                            <div className="grid grid-cols-3 gap-3 inspectionItem">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Sub Assembly
                                    </label>
                                </div>
                                <div className="col-span-2">
                                    {wts['pffsm__Sub_Assembly__c']}
                                </div>
                            </div>
                            <div className="grid inspectionItem">
                                <input type="file" name="file" multiple="multiple" onChange={(e) => changeHandler(e, idx)} />
                                    { selectedFiles[idx]?.length > 0 ?
                                        <div>
                                            { selectedFiles[idx].map((item) => (
                                                <div>
                                                    <p>Filename: {item.name}</p>
                                                    <p>Size in bytes: {item.size}</p>
                                                </div>
                                            ))}

                                            <button onClick={() => handleSubmission(idx)} className="mx-2 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                                                <span>Upload</span>
                                            </button>
                                        </div> :
                                        <p>Select a file to show details</p>
                                    }
                            </div>

                            <BlockTitle className="grid inspectionItem" withBlock={false}>Files Uploaded</BlockTitle>
                            <Card>
                                <List nested className="-m-4">
                                    <ListButton colors={{ text: 'text-black-500', activeBg: 'active:bg-black-500', touchRipple: 'touch-ripple-black-500', }} onClick={() => showUploadFiles(idx)}>
                                        { showFile[idx] ? "Hide Uploaded Files" : "Show Uploaded Files" }
                                    </ListButton>
                                    { showFile[idx] ?
                                        fileResults[idx]?.length > 0 ?
                                            fileResults[idx].map((result, fileIdx) => (
                                                <ListItem title={result.Title} media={<MdDelete/>} onClick={() => handleDelete(result.id, idx, fileIdx)}/>
                                            )) :
                                            <p>No file has been uploaded to this Work Task Step</p> :
                                        null
                                    }
                                </List>
                            </Card>
                        </Card>
                    </Block>
                </div>) :
                <div className="text-center mt-4">No Work Task Steps found.</div>
            }
        </div>
    );
}