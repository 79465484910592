import { PersonCircleFill } from 'framework7-icons/react';
import { MdPerson } from 'react-icons/md';
import { Page, Navbar, Panel, Link, BlockTitle, List, ListItem, Icon } from 'konsta/react';
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';

import { useSignedInUser } from '../../Hooks/Hooks';
import { leftPaneOpenState, networkState, loginPrompt } from '../../State/Global';
import { leftMenuPanelRoutes } from '../../../routes/routes';
import { Auth } from 'aws-amplify';
import { useAuth0 } from '@auth0/auth0-react';

export default function LeftMenuPanel(){
    const navigate = useNavigate();
    const isOnline = useRecoilValue(networkState);
    const setLoginPrompt = useSetRecoilState(loginPrompt);
    const auth = useAuth0();

    const [ user ] = useSignedInUser();
    const [ leftPanelOpened, setLeftPanelOpened ] = useRecoilState(leftPaneOpenState);

    const logout = async () => {
      Auth.signOut({ global: true}).then(() => {
        if (auth.isAuthenticated) {
          setLeftPanelOpened(false);
          setLoginPrompt("login");

          auth.logout({ returnTo: window.location.origin });
        }
      });
    }

    return (
        <Panel
        side="left"
        opened={leftPanelOpened}
        onBackdropClick={() => setLeftPanelOpened(false)}
      >
        <Page>
          <Navbar
            title=""
            left={
              <Link navbar iconOnly>
                <Icon
                  ios={<PersonCircleFill className="w-7 h-7" />}
                  material={<MdPerson className="w-6 h-6" />}
                  badgeColors={{ bg: 'bg-red-500' }}
                />
              </Link>
            }
            right={
              isOnline ?
                <Link navbar onClick={() => logout()}>
                  Logout
                </Link> :
                <div />
            }
          />
          <BlockTitle>{user?.name}</BlockTitle>
          {
              leftMenuPanelRoutes.map( route =>
                <List key={route.type}>
                    {
                        route.items.map( item =>
                            <ListItem key={item.id} media={item.icon} title={item.label} onClick={()=> {navigate(item.nav); setLeftPanelOpened(false)}} link />
                            )
                    }
                </List>
                )
          }
        </Page>

      </Panel>
    )
}