import { useEffect, useState } from "react"
import { useSetRecoilState } from "recoil";
import { Page, Block, List, ListItem, Badge  }  from 'konsta/react';
import { DataStore, Predicates, SortDirection } from "aws-amplify";
import { AiOutlineRight, AiOutlineDown } from "react-icons/ai"
import { BiError } from "react-icons/bi"

import moment from "moment/moment";

import { globalNavbarOptions } from "../../State/Global";
import { dynamoTables } from "../../../dbTables/syncObjects";

import Spinner from "../../Utils/Loading/Spinner";

export default function DataSync() {
    const [ syncRecords, setSyncRecords ] = useState([]);

    const [ isLoading, setIsLoading ] = useState(true);
    const setNavbarOptions = useSetRecoilState(globalNavbarOptions);

    const syncToTable = dynamoTables.find((table) => table.ref === "object_sync_to");

    useEffect(() => {
      setNavbarOptions({
        title: "Data Sync",
        page: "sync-page",
        hasLeft: false,
        hasRight: false
      });

      getSyncTo();
    }, []);

    const getSyncTo = async() => {
        let combinedRecordMap = {};
        const syncToRecords = await DataStore.query(syncToTable.model, Predicates.ALL, {
            sort: s => s.recordTimestamp(SortDirection.ASCENDING)
        });

        for (const syncToRecord of syncToRecords) {
            let record = combinedRecordMap[syncToRecord.externalId];
            if (!record) {
                record = {
                    recordName: syncToRecord.recordName,
                    changes: [],
                    expand: false
                }

                combinedRecordMap[syncToRecord.externalId] = record;
            }

            record.changes.push({
                errorMessage: syncToRecord.errorMessage,
                lastSync: syncToRecord.lastSync ? moment(syncToRecord.lastSync).format("yyyy-MM-DD h:mm A") : null,
                changeDate: moment(syncToRecord.recordTimestamp).format("yyyy-MM-DD h:mm A"),
                expand: false
            });
        }

        for (const table of dynamoTables) {
            if (table.isModifiable) {
                const modifiedRecords = await DataStore.query(table.model, t => t.doPush__custom.eq(true));
                for (const modifiedRecord of modifiedRecords) {
                    if (!combinedRecordMap[modifiedRecord.id]) {
                        combinedRecordMap[modifiedRecord.id] = {
                            recordName: modifiedRecord.Name ?? modifiedRecord.Title,
                            changes: [],
                            expand: false
                        }
                    }
                }
            }
        }

        setSyncRecords(Object.values(combinedRecordMap));
        setIsLoading(false);
    }

    const displaySyncErrorMessage = (syncIndex, changeIndex) => {
        const updatedChanges = [ ...syncRecords[syncIndex].changes ];
        updatedChanges[changeIndex].expand = !syncRecords[syncIndex].changes[changeIndex].expand;

        syncRecords[syncIndex].changes = updatedChanges;
        setSyncRecords([ ...syncRecords ]);
    }

    const expandSyncs = (index) => {
        syncRecords[index].expand = !syncRecords[index].expand;
        setSyncRecords([ ...syncRecords ]);
    }

    return (
        <Page className="mt-2">
            { isLoading ? <Spinner /> : null }
            <Block margin="mt-2" className="w-full flex flex-col hairline-b p-4 hairline-gray-200">
                <List>
                    {
                        syncRecords.map((syncRecord, syncIndex) => {
                            return <ListItem title={syncRecord.recordName} strongTitle
                                text={
                                    syncRecord.expand ?
                                        <List margin="mt-2">
                                            {
                                                syncRecord.changes.map((change, changeIndex) => {
                                                    return <ListItem title={change.changeDate} strongTitle
                                                        text={
                                                            change.expand ? change.errorMessage : null
                                                        }
                                                        after={
                                                            change.errorMessage ?
                                                            <>
                                                                <BiError color="red" />
                                                                { change.expand ?
                                                                    <AiOutlineDown onClick={() => displaySyncErrorMessage(syncIndex, changeIndex)} /> :
                                                                    <AiOutlineRight onClick={() => displaySyncErrorMessage(syncIndex, changeIndex)} />
                                                                }
                                                            </> :
                                                            <Spinner isIcon />
                                                        }
                                                    />
                                                })
                                            }
                                        </List> : null
                                }
                                after={
                                    <>
                                        <Badge>{ syncRecord.changes.length }</Badge>
                                        { syncRecord.changes.length > 0 ?
                                            (syncRecord.expand ?
                                                <AiOutlineDown onClick={() => expandSyncs(syncIndex) } /> :
                                                <AiOutlineRight onClick={() => expandSyncs(syncIndex) }/>)
                                            : null
                                        }
                                    </>
                                }
                            />
                        })
                    }
                </List>
            </Block>
        </Page>
    )
}