import { useEffect, useState } from "react";
import { DataStore } from "aws-amplify";

import { pageRoutes } from '../../../routes/routes';
import { dynamoTables } from "../../../dbTables/syncObjects";

import DetailView from "../../Utils/Detail/DetailContent";
import InfiniteScroll from "react-infinite-scroll-component";

export default function WorkAssignmentContent({ queryPredicates}) {
    const [ workOrders, setWorkOrders ] = useState([]);
    const [ page, setPage ] = useState(0);
    const [ hasMore, setHasMore ] = useState(true);

    const woNav = pageRoutes.find((route) => route.id === "work-order-page")?.nav;
    const woTable = dynamoTables.find(table => table.ref === "pffsm__smWork_Order__c");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        const queriedWorkOrders = await DataStore.query(woTable.model, queryPredicates, {
            page: page,
            limit: 5
        });

        setWorkOrders(workOrders.concat(getFlattenedWorkOrders(queriedWorkOrders)));

        setPage(page + 1);
        setHasMore(queriedWorkOrders.length === 5);
    }

    const getFlattenedWorkOrders = (workorders) => {
        let flattenedWorkOrders = [];
        for (let workorder of workorders) {
            flattenedWorkOrders.push({
                label: "",
                values:[{
                    label: "Name",
                    idValue: workorder.id,
                    isLookup: true,
                    lookupObj: woNav,
                    value: workorder.Name
                }, {
                    label: "WO Description",
                    value: workorder.pffsm__Description__c,
                    isLargeText: true
                }, {
                    label: "Priority",
                    value: workorder.pffsm__Priority__c,
                    isLargeText: true
                }, {
                    label: "Asset Description",
                    value: workorder.pffsm__Equip_Description__c,
                    isLargeText: true
                }]
            })
        }

        return flattenedWorkOrders;
    }

    return (
        workOrders.length === 0 ?
            <div className="text-center mt-1.5">No Records Found</div> :
            <InfiniteScroll
                dataLength={ workOrders.length }
                next={ fetchData }
                hasMore={ hasMore }
                loader={<h4>Loading...</h4>}
                scrollableTarget="scrollable">

                <DetailView flattenedObject={ workOrders } isEditing={ false }/>
            </InfiniteScroll>
    )
}