import { useEffect, useState } from "react";
import { DataStore } from "aws-amplify";
import { useParams, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from "recoil";
import { Fab } from "konsta/react";
import { FcInspection } from "react-icons/fc";

import { dynamoTables } from "../../../dbTables/syncObjects";
import { globalNavbarOptions } from "../../State/Global";
import { useNewRecentItems } from "../../Hooks/Hooks";
import { pageRoutes } from "../../../routes/routes";

import DetailView from "../../Utils/Detail/DetailContent";
import Spinner from "../../Utils/Loading/Spinner";

export default function WorkTaskDetailView () {
    useNewRecentItems();

    const navigate = useNavigate();
    const setNavbarOptions = useSetRecoilState(globalNavbarOptions);

    const { id } = useParams();
    const [ flattenedWorkTask, setFlattenedWorkTask ] = useState();
    const [ isLoading, setIsLoading ] = useState(true);

    const wtTable = dynamoTables.find((table) => table.ref === "pffsm__smWO_Task__c");
    const woTable = dynamoTables.find((table) => table.ref === "pffsm__smWork_Order__c");

    const woNav = pageRoutes.find((route) => route.id === "work-order-page")?.nav;

    const getWorkTasks = async () =>{
        const workTask = await DataStore.query(wtTable.model, id);
        const workorder = await DataStore.query(woTable.model, workTask.workorder__localId);

        let percentCmpltValue = 0;
        if (workTask.pffsm__Percent_Complete__c) {
            percentCmpltValue = Math.round(workTask.pffsm__Percent_Complete__c);
        }

        let stdTask = workTask.pffsm__Std_Task__c;
        let stdTaskName = '';
        if (stdTask !== undefined) {
            const workTasks = await DataStore.query(wtTable.model, stdTask);
            stdTaskName = workTasks !== undefined ? workTasks.Name :'';
        }

        setFlattenedWorkTask([
            {
                label: "General",
                values: [{
                    label: "Name",
                    value: workTask.Name
                }, {
                    label: "Work Order",
                    idValue: workTask.workorder__localId,
                    isLookup: true,
                    lookupObj: woNav,
                    value: workorder?.Name
                }, {
                    label: "WO Description",
                    value: workTask.pffsm__WO_Description__c,
                    isLargeText: true
                }, {
                    label: "WT Description",
                    value: workTask.pffsm__Comments__c,
                    isLargeText: true
                }, {
                    label: "Asset Description",
                    value: workTask.pffsm__Asset_Description__c,
                    isLargeText: true
                }, {
                    label: "Planned Hours",
                    value: workTask.pffsm__Planned_Hours__c,
                }]
            }, {
                label: "Information",
                values: [{
                    label: "Due Date",
                    value: workTask.pffsm__Due_Date__c,
                }, {
                    label: "Std Task",
                    value:  stdTaskName,
                },{
                    label: "% Complete",
                    value: percentCmpltValue,
                    type: "percentage",
                }, {
                    label: "# People Required",
                    value: workTask.pffsm__People_Required__c
                }]
            }, {
                label: "Instructions",
                values: [{
                    label: "",
                    value: workTask.pffsm__Instructions__c,
                    isLargeText: true
                }]
            }
        ]);

        setIsLoading(false);
    }

    useEffect(() => {
        setNavbarOptions({
            title: "Work Task Detail",
            page: "work-task-detail-page",
            hasLeft: true,
            hasRight: true
          });
    }, []);

    useEffect(() => {
        getWorkTasks();
    }, [ id ]);

    const goToInspectionPage = () => {
        navigate(`/inspection-form/${id}`);
    }

    return (
        <div className= "py-1 pl-2 pr-2 h-full mt-8">
            { isLoading ?
                <Spinner /> :
                <>
                    <Fab className="fixed right-6-safe top-14-safe z-20" icon={ <FcInspection /> } onClick={() => goToInspectionPage()} />
                    <DetailView flattenedObject={ flattenedWorkTask } />
                </>
            }
        </div>
    );
}