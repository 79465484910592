import { useEffect, useState } from "react";
import { DataStore } from "aws-amplify";
import { useParams, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from "recoil";
import { Fab, Tabbar, TabbarLink, Page, Toast, Button } from "konsta/react";
import { MdFilePresent, MdAdd, MdEdit } from "react-icons/md"

import moment from "moment";

import { globalNavbarOptions } from "../../State/Global";
import { pageRoutes } from "../../../routes/routes";
import { globalSearchTables, dynamoTables } from "../../../dbTables/syncObjects";
import { labels } from "../../../dbTables/labels";
import { useNewRecentItems } from "../../Hooks/Hooks";
import { getPicklistOptions } from "../../Utils/Util";

import SearchResults from "../../Utils/SearchResults/SearchResults";
import DetailView from "../../Utils/Detail/DetailContent";
import Spinner from "../../Utils/Loading/Spinner";

export default function WorkOrderDetailView() {
    const woTable = dynamoTables.find((table) => table.ref === "pffsm__smWork_Order__c");

    const assNav = pageRoutes.find((route) => route.id === "asset-page")?.nav;
    const woNav = pageRoutes.find((route) => route.id === "work-order-page")?.nav;
    const wtNav = pageRoutes.find((route) => route.id === "work-task-page")?.nav;
    const oeNav = pageRoutes.find((route) => route.id === "operational-event-page")?.nav;

    const { id } = useParams();

    const [ completionNote, setCompletionNote ] = useState();
    const [ problemDesc, setProblemDesc ] = useState();
    const [ woType, setWoType ] = useState();
    const [ actCompletionDate , setActCompletionDate ] = useState();
    const [ actStartDate , setActStartDate ] = useState();
    const [ actionCode , setActionCode ] = useState();
    const [ causeCode, setCauseCode ] = useState();
    const [ failureCode, setFailureCode ] = useState();
    const [ delayNotes , setDelayNotes ] = useState();
    const [ delayType, setDelayType ] = useState();
    const [ parentWO, setParentWO ] = useState();

    const [ flattenedWorkorder, setFlattenedWorkorder ] = useState([]);
    const [ isEditing, setIsEditing ] = useState(false);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ toastShowed, setToastShowed ] = useState(false);
    const [ activeTab, setActiveTab ] = useState('WO-Details');
    const [ query, setQuery ] = useState('');
    const [ toastMessage, setToastMessage ] = useState();

    const [ woTypeOptions, setWOTypeOptions ] = useState([]);
    const [ woDelayTypeOptions, setWODelayTypeOptions ] = useState([]);
    const [ woCauseCodeOptions, setWOCauseCodeOptions ] = useState([]);
    const [ woFailureCodeOptions, setWOFailureCodeOptions ] = useState([]);
    const [ woActionCodeOptions, setWOActionCodeOptions ] = useState([]);

    const workTaskTable = globalSearchTables.filter(searchTable => searchTable.ref === "pffsm__smWO_Task__c");
    const oeTable = globalSearchTables.filter(searchTable => searchTable.ref === "pffsm__Operational_Event__c");
    const workOrderTable = globalSearchTables.filter(searchTable => searchTable.ref === "pffsm__smWork_Order__c");
    const workTaskFieldLabels = labels.pffsm__smWO_Task__c;
    const oeFieldLabels = labels.pffsm__Operational_Event__c;
    const workOrderFieldLabels = labels.pffsm__smWork_Order__c;

    const wtSearchFields = workTaskTable.map(x=>{return {...x, fields: [
        "Name",
        "pffsm__WO_Description__c",
        "pffsm__Comments__c",
        "pffsm__Asset_ID__c",
        "pffsm__Work_Order__c",
        "pffsm__Asset_Description__c",
        "pffsm__Planned_Hours__c"
    ]}});

    const oeSearchFields = oeTable.map(x=>{return {...x, fields: [
        "Name",
        "pffsm__Event_Type__c",
        "pffsm__Acknowledged_Time__c",
        "pffsm__Acknowledged_By_Name__c",
        "pffsm__Work_Order__c"
    ]}});

    const woSearchFields = workOrderTable.map(x=>{return {...x, fields: [
        "Name",
        "pffsm__Description__c",
        "pffsm__Equip_Description__c",
        "pffsm__Parent_Work_Order__c",
        "parentWorkOrder__localId",
        "pffsm__Priority__c"
    ]}});

    const wtNotDisplayFields = ["pffsm__Work_Order__c"];
    const oeNotDisplayFields = ["pffsm__Work_Order__c"];
    const woNotDisplayFields = ["parentWorkOrder__localId", "pffsm__Parent_Work_Order__c"];

    const oeTimeFields = ["pffsm__Acknowledged_Time__c"];

    const navigate = useNavigate();
    const setNavbarOptions = useSetRecoilState(globalNavbarOptions);

    useNewRecentItems();

    const cancel = async () => {
        setIsEditing(false);
    };

    const closeToast = () => {
        setToastMessage('')
        setToastShowed(false);
    }

    const updateWorkOrder = async () => {
        const queriedWorkOrder = await DataStore.query(woTable.model, id);

        await DataStore.save(
            woTable.model.copyOf(queriedWorkOrder, updated => {
                updated.doPush__custom = true;

                if (completionNote !== undefined) {
                    updated.pffsm__Completion_Notes__c = completionNote ;
                }
                if (actCompletionDate !== undefined) {
                    updated.pffsm__Completion_Date__c = actCompletionDate ;
                }

                if (actStartDate !== undefined) {
                    updated.pffsm__Actual_Start_Date__c = actStartDate ;
                }

                if (delayNotes !== undefined) {
                    updated.pffsm__Delay_Notes__c = delayNotes;
                }

                if( problemDesc!== undefined ){
                    updated.pffsm__Detailed_Comments__c = problemDesc;
                }

                if( woType!== undefined ){
                    updated.pffsm__WO_Type__c = woType;
                }

                if (delayType !== undefined) {
                    updated.pffsm__Delay_Type__c = delayType;
                }

                if (failureCode !== undefined) {
                    updated.pffsm__Failure_Code__c =  failureCode;
                }

                if (causeCode !== undefined) {
                    updated.pffsm__Cause_Code__c = causeCode;
                }

                if (actionCode !== undefined) {
                    updated.pffsm__Action_Code__c = actionCode;
                }
            })
        );

        flattenedWorkorder[3].values[9].value = completionNote;
        flattenedWorkorder[3].values[8].value = actCompletionDate;
        flattenedWorkorder[3].values[7].value = actStartDate;
        flattenedWorkorder[3].values[6].value = actionCode;
        flattenedWorkorder[3].values[5].value = causeCode;
        flattenedWorkorder[3].values[4].value = failureCode;
        flattenedWorkorder[2].values[0].value = delayType;
        flattenedWorkorder[2].values[1].value = delayNotes;
        flattenedWorkorder[0].values[10].value = problemDesc;
        flattenedWorkorder[0].values[8].value = woType;

        setFlattenedWorkorder(flattenedWorkorder);
        setToastShowed(true);
        setToastMessage('This record has been updated successfully!')
        setIsEditing(false);
    };

    useEffect(() => {
        setNavbarOptions({
            title: "Work Order Detail",
            page: "work-order-detail-page",
            hasLeft: true,
            hasRight: false
          });

      }, []);

    useEffect(() => {
        DataStore.query(woTable.model, id).then((wo) => {
            getPicklistOptions(setWOTypeOptions, "pffsm__smWork_Order__c", "pffsm__WO_Type__c", null, null);
            getPicklistOptions(setWODelayTypeOptions, "pffsm__smWork_Order__c", "pffsm__Delay_Type__c", null, null);
            getPicklistOptions(setWOCauseCodeOptions, "pffsm__smWork_Order__c", "pffsm__Cause_Code__c",  wo.pffsm__Energy_Source__c, "pffsm__Energy_Source__c");
            getPicklistOptions(setWOFailureCodeOptions, "pffsm__smWork_Order__c", "pffsm__Failure_Code__c",  wo.pffsm__Energy_Source__c, "pffsm__Energy_Source__c");
            getPicklistOptions(setWOActionCodeOptions, "pffsm__smWork_Order__c", "pffsm__Action_Code__c",  wo.pffsm__Energy_Source__c, "pffsm__Energy_Source__c");
        });
    }, [ id ])

    useEffect(() => {
        if (woTypeOptions.length > 0 && woDelayTypeOptions.length > 0 && woCauseCodeOptions.length > 0 &&
            woFailureCodeOptions.length > 0 && woActionCodeOptions.length > 0) {
            getWorkOrder();
        }
    }, [ id, woTypeOptions, woDelayTypeOptions, woCauseCodeOptions, woFailureCodeOptions, woActionCodeOptions ])

    const getWorkOrder = async () => {
        const workorder = await DataStore.query(woTable.model, id);
        setQuery(workorder.sfId__custom);


        let eventStart = "";
        let eventEnd = "";
        let problemDescValue = "";
        let woTypeValue = "";
        let actStartDateValue ="";
        let actionCodeValue ="";
        let causeCodeValue ="";
        let failureCodeValue ="";
        let delayTypeValue ="";
        let delayNotesValue ="";
        let cmpNotesValue ="";
        let actCmpltDateValue ="";
        let energySrcValue ="";

        let parentWOCId = null;
        let parentWOCName = "";
        if(workorder.pffsm__Parent_Work_Order__c){
            const parentWOList = await DataStore.query(woTable.model, wo => wo.or(wo => [
                wo.id.eq(workorder.parentWorkOrder__localId),
                wo.sfId__custom.eq(workorder.pffsm__Parent_Work_Order__c)
            ]));

            if (parentWOList.length > 0) {
                const parentWOCurrent = parentWOList[0];
                parentWOCId = parentWOCurrent.id;
                parentWOCName = parentWOCurrent.Name;
            }
        }

        if (workorder.pffsm__Event_Start__c) {
            eventStart = moment(workorder.pffsm__Event_Start__c).format("yyyy-MM-DD h:mm A");
        }

        if (workorder.pffsm__Event_End__c) {
            eventEnd = moment(workorder.pffsm__Event_End__c).format("yyyy-MM-DD h:mm A");
        }

        if(workorder.pffsm__Detailed_Comments__c){
            setProblemDesc(workorder.pffsm__Detailed_Comments__c);
            problemDescValue = workorder.pffsm__Detailed_Comments__c;
        }

        if(workorder.pffsm__WO_Type__c){
            setWoType(workorder.pffsm__WO_Type__c);
            woTypeValue = workorder.pffsm__WO_Type__c;
        }

        if (workorder.pffsm__Actual_Start_Date__c) {
            setActStartDate(workorder.pffsm__Actual_Start_Date__c);
            actStartDateValue = workorder.pffsm__Actual_Start_Date__c;
        }

        if (workorder.pffsm__Action_Code__c) {
            setActionCode(workorder.pffsm__Action_Code__c);
            actionCodeValue = workorder.pffsm__Action_Code__c;
        }

        if (workorder.pffsm__Cause_Code__c) {
            setCauseCode(workorder.pffsm__Cause_Code__c);
            causeCodeValue = workorder.pffsm__Cause_Code__c;
        }

        if (workorder.pffsm__Failure_Code__c) {
            setFailureCode(workorder.pffsm__Failure_Code__c);
            failureCodeValue = workorder.pffsm__Failure_Code__c;
        }

        if (workorder.pffsm__Delay_Type__c) {
            setDelayType(workorder.pffsm__Delay_Type__c);
            delayTypeValue = workorder.pffsm__Delay_Type__c;
        }

        if (workorder.pffsm__Delay_Notes__c) {
            setDelayNotes(workorder.pffsm__Delay_Notes__c);
            delayNotesValue = workorder.pffsm__Delay_Notes__c;
        }

        if (workorder.pffsm__Completion_Notes__c) {
            setCompletionNote(workorder.pffsm__Completion_Notes__c);
            cmpNotesValue = workorder.pffsm__Completion_Notes__c;
        }

        if (workorder.pffsm__Completion_Date__c) {
            setActCompletionDate(workorder.pffsm__Completion_Date__c);
            actCmpltDateValue = workorder.pffsm__Completion_Date__c;
        }

        if (workorder.pffsm__Energy_Source__c) {
            energySrcValue = workorder.pffsm__Energy_Source__c;
        }

        setParentWO(workorder);

        setFlattenedWorkorder([
            {
                label: "General",
                values: [{
                    label: "Name",
                    value: workorder.Name
                }, {
                    label: "WO Description",
                    value: workorder.pffsm__Description__c,
                    isLargeText: true
                }, {
                    label: "Asset Description",
                    value: workorder.pffsm__Equip_Description__c,
                    isLargeText: true
                }, {
                    label: "Owner",
                    value: workorder.OwnerName,
                }, {
                    label: "Priority",
                    value: workorder.pffsm__Priority__c
                }, {
                    label: "Due Date",
                    value: workorder.pffsm__Due_Date__c
                }, {
                    label: "Asset Tag",
                    idValue: workorder.equipment__localId,
                    isLookup: true,
                    lookupObj: assNav,
                    value: workorder.pffsm__Asset_ID_Text__c
                },{
                    label: "Parent Work Order",
                    idValue: parentWOCId,
                    isLookup: true,
                    lookupObj: woNav,
                    value: parentWOCName
                }, {
                    label: "WO Type",
                    value: woTypeValue,
                    isEditable: true,
                    isPicklist: true,
                    picklistValues : woTypeOptions,
                    handler: (e) => setWoType(e.target.value)
                }, {
                    label: "Status",
                    value: workorder.pffsm__Status__c
                }, {
                    label: "Prob. Desc./Scope of Work",
                    value: problemDescValue,
                    isEditable: true,
                    isLargeText: true,
                    handler: (e) => setProblemDesc(e.target.value)
                }, {
                    label: "Initial Estimated Hours",
                    value: workorder.pffsm__Initial_Est_Hours__c
                }]
            }, {
                label: "Site Address",
                values: [{
                    label: "Street Address",
                    value: workorder.pffsm__Street_Address__c
                }, {
                    label: "City",
                    value: workorder.pffsm__City__c
                }, {
                    label: "State/Province",
                    value: workorder.pffsm__State_Province__c
                }, {
                    label: "Zip/Postal Code",
                    value: workorder.pffsm__Zip_Postal_Code__c
                }]
            }, {
                label: "WO Delay Codes",
                values: [{
                    label: "Delay Type",
                    value: delayTypeValue,
                    isEditable: true,
                    isRequired: true,
                    isPicklist: true,
                    picklistValues : woDelayTypeOptions,
                    handler: (e) => setDelayType(e.target.value)
                }, {
                    label: "Delay Notes",
                    value: delayNotesValue,
                    isLargeText: true,
                    type:"text",
                    isEditable: true,
                    isRequired: true,
                    handler: (e) => setDelayNotes(e.target.value)
                }, {
                    label: "Delay Code Due Date",
                    value: workorder.pffsm__Delay_Code_Due_Date__c
                }]
            }, {
                label: "Completion Information",
                values: [{
                    label: "Event Start",
                    value: eventStart
                }, {
                    label: "Event End",
                    value: eventEnd
                }, {
                    label: "Event Duration",
                    value: workorder.pffsm__Event_Duration__c
                },
                {
                    label: "Energy source",
                    value: energySrcValue,
                    isRequired: true,
                    isPicklist: true
                },{
                    label: "Failure Code",
                    value: failureCodeValue,
                    isEditable: true,
                    isRequired: true,
                    isPicklist: true,
                    picklistValues : woFailureCodeOptions,
                    handler: (e) => setFailureCode(e.target.value)
                }, {
                    label: "Cause Code",
                    value: causeCodeValue,
                    isEditable: true,
                    isRequired: true,
                    isPicklist: true,
                    picklistValues : woCauseCodeOptions,
                    handler: (e) => setCauseCode(e.target.value)
                }, {
                    label: "Action Code",
                    value: actionCodeValue,
                    isEditable: true,
                    isRequired: true,
                    isPicklist: true,
                    picklistValues : woActionCodeOptions,
                    handler: (e) => setActionCode(e.target.value)
                }, {
                    label: "Actual Start Date",
                    value: actStartDateValue,
                    isEditable: true,
                    isRequired: true,
                    type:"date",
                    handler: (e) => setActStartDate(e.target.value)
                }, {
                    label: "Field Work completion date",
                    value: actCmpltDateValue,
                    isEditable: true,
                    isRequired: true,
                    type:"date",
                    handler: (e) => setActCompletionDate(e.target.value)
                }, {
                    label: "Completion Notes",
                    value: cmpNotesValue,
                    type:"text",
                    isLargeText: true,
                    isEditable: true,
                    isRequired: true,
                    handler: (e) => setCompletionNote(e.target.value)
                }]
            }
        ]);

        setIsLoading(false);
    }

    const openFileUpload = () => {
        navigate(`/work-order-upload-file?workOrderId=${id}`);
    }

    const createNewWorkOrder = () => {
        navigate(`/work-order-new-info?assetId=${parentWO.pffsm__Equipment__c}&assetLocalId=${parentWO.equipment__localId}&assetName=${parentWO.pffsm__Asset_ID_Text__c}&parentWOId=${parentWO.sfId__custom}&parentLocalWOId=${id}&parentWOName=${parentWO.Name}`)
    }

    return (
        <div className="w-full mt-12">
            { isLoading ?
                <Spinner /> :
                <>
                    <Tabbar labels={false} className="left-0 top-10 fixed bg-gray-500 asset-tabs">
                        <TabbarLink
                            active={activeTab === 'WO-Details'}
                            onClick={() => setActiveTab('WO-Details')}
                            label="Details" />
                        <TabbarLink
                            active={activeTab === 'WT-Related'}
                            onClick={() => setActiveTab('WT-Related')}
                            label="WTs" />
                        <TabbarLink
                            active={activeTab === 'OE-Related'}
                            onClick={() => setActiveTab('OE-Related')}
                            label="OEs" />
                        <TabbarLink
                            active={activeTab === 'Child-WOs'}
                            onClick={() => setActiveTab('Child-WOs')}
                            label="Child WOs" />
                    </Tabbar>

                    { activeTab === 'WO-Details' && (
                        <div className="py-1 pl-2 pr-2 mt-4 h-full">
                            { isEditing ?
                                <>
                                    <Tabbar className="left-0 top-10 fixed bg-gray-600 font-bold text-neutral-900 save-cancel-menu">
                                        <TabbarLink
                                            onClick={ () => cancel() }
                                            label="Cancel" />
                                        <TabbarLink
                                            onClick={ () => updateWorkOrder() }
                                            label="Save" />
                                    </Tabbar>
                                </> :
                                <>
                                    <Fab className="fixed top-24-safe right-4-safe z-20" icon={ <MdFilePresent />} onClick={() => openFileUpload()} />
                                    <Fab className="fixed right-20-safe bottom-18-safe z-20" icon={ <MdAdd /> } onClick={() => createNewWorkOrder()} />
                                    <Fab className="fixed right-6-safe bottom-18-safe z-20" icon={ <MdEdit /> } onClick={() => setIsEditing(true)}/>
                                </>
                            }

                            <DetailView flattenedObject={ flattenedWorkorder } isEditing={ isEditing }/>
                        </div>
                    )}

                    { activeTab === 'WT-Related' && (
                        <div className="py-1 pl-2 pr-2 h-full mt-4">
                            <div>
                                <SearchResults nav={ wtNav} searchFields={wtSearchFields} queryString = {query}
                                    fieldLabels={workTaskFieldLabels} notDisplayFields={wtNotDisplayFields}/>
                            </div>
                        </div>
                    )}

                    { activeTab === 'OE-Related' && (
                        <div className="py-1 pl-2 pr-2 h-full mt-4">
                            <div>
                                <SearchResults nav={ oeNav} searchFields={oeSearchFields} queryString = {query}
                                    fieldLabels={oeFieldLabels} notDisplayFields={oeNotDisplayFields} timeFields={oeTimeFields}/>
                            </div>
                        </div>
                    )}

                    { activeTab === 'Child-WOs' && (
                        <div className="py-1 pl-2 pr-2 h-full mt-4">
                            <div>
                                <SearchResults nav={ woNav } searchFields={woSearchFields} queryString = {id}
                                    fieldLabels={workOrderFieldLabels} notDisplayFields={woNotDisplayFields}/>
                            </div>
                        </div>
                    )}

                    <Page>
                        <Toast position="center" opened={toastShowed}
                            button={ <Button clear inline onClick={() => closeToast()}>Close</Button> }>
                            <div className="shrink">{toastMessage}</div>
                        </Toast>
                    </Page>
                </>
            }
        </div>
    );
}