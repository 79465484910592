import { useState, useEffect } from 'react';
import { Amplify, DataStore, SortDirection, Predicates } from "aws-amplify";
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { darkThemeState, debugState, globalSearchFocusState, userLocalRecentItems } from '../State/Global';
import { dynamoTables } from '../../dbTables/syncObjects';
import { useLocation, useParams } from 'react-router-dom';

const useNewRecentItems = () => {
    let location = useLocation();
    let { id } = useParams();
    const [recentItems, setRecentItems] = useRecoilState(userLocalRecentItems);
    const getItem = async (table, itemId) => {
        const recentItem = await DataStore.query(table.model, itemId)
        if (recentItem) {
            const newRecent = {Id: recentItem.id, Name: recentItem.Name, attributes: {type: table.ref}, lastViewed: Date.now()}

            let updateRecent = recentItems.filter(item => item.Id !== id)
            updateRecent.unshift(newRecent)

            const UniqueItemsByName = [...new Map(updateRecent.map(item => [item['Name'], item])).values()];
            setRecentItems(UniqueItemsByName)
        }
    }
    useEffect(() =>{
        const table = dynamoTables.find( table => table.nav.includes(location.pathname.split('/')[1]))
        getItem(table, id)

    }, [])
}

const useRecentItems = (type) => {
    const [recentItems, setRecentItems] = useState();
    const localRecentItems = useRecoilValue(userLocalRecentItems);
    const recentTable = dynamoTables.find( table => table.ref === 'recent_items' );
    const itemTypes = type ?? [];

    const getRecentByType = async () => {
        const dbRecentItems = await DataStore.query(recentTable.model)

        let remoteRecents = [];
        if(dbRecentItems?.length > 0){
            const recentItemsSF = JSON.parse(dbRecentItems[0].items)
            const localIds = localRecentItems.map( rec => rec.Id)
            remoteRecents = recentItemsSF.filter( item => itemTypes.includes(item?.attributes?.type) && !localIds.includes(item.Id))
        }

        const localRecents = localRecentItems.filter(item => itemTypes.includes(item?.attributes?.type))
        const allRecents = localRecents.concat(remoteRecents).slice(0, 15).sort((a,b) => b?.lastViewed - a?.lastViewed);
        return [...new Map(allRecents.map(item =>  [item['Name'], item])).values()];
    }

    useEffect(() =>{
        const subscription = DataStore.observeQuery(recentTable.model, Predicates.ALL).subscribe(() => {
            getRecentItems();
        });

        async function getRecentItems(){
            const recentItems = await getRecentByType();
            setRecentItems(recentItems)
        }

        getRecentItems();

        return () => subscription.unsubscribe();
    }, [recentTable.model, itemTypes])

    return recentItems
}

const useSearchResults = (query, size, offset, searchableItems, defaultAll) => {
    const [searchables, setSearchables] = useState();
    const showAll = defaultAll ?? false;

    useEffect(() =>{
        async function handleSearch(){
            if((query?.trim().length > 2 && searchableItems) || (showAll === true && searchableItems)){
                let updateSearchables = searchableItems?.map(res => res);
                const queryUpperCase = query.toUpperCase();
                const queryLowerCase = query.toLowerCase();
                const queryFUCase = query.charAt(0).toUpperCase() + query.slice(1).toLowerCase();
                for(const item of searchableItems) {
                    if(item.fields.length > 0){
                        let uniqueResults = [];
                        for(const field of item.fields){
                            const results = (!showAll) ? await DataStore.query(item.model,
                                c => c.or(c => [
                                    c[field][item.operator](query),
                                    c[field][item.operator](queryUpperCase),
                                    c[field][item.operator](queryLowerCase),
                                    c[field][item.operator](queryFUCase)]), {
                                        sort: s => s.updatedAt(SortDirection.DESCENDING),
                                page: offset,
                                limit: size ?? 5
                              })
                            : await DataStore.query(item.model)
                            const uniqueIds = new Set(uniqueResults.map(item => item.id));
                            uniqueResults = [...uniqueResults, ...results.filter(d => !uniqueIds.has(d.id))];
                            // eslint-disable-next-line no-loop-func
                            updateSearchables = updateSearchables.map(res => { return {...res, results: (res.name === item.name) ? uniqueResults : res?.results }});
                            setSearchables(updateSearchables);
                        }
                    }
                  }
            }
        }

        handleSearch();

        return () => {
            setSearchables([])
        }

    }, [query, offset, size])

    return [searchables, setSearchables]
}

const useLoseFocus = (ref) => {
    const setHasFocus = useSetRecoilState(globalSearchFocusState)
    useEffect(() => {
        const handleClickOutside = (e) => {
            if (ref.current && !ref.current.contains(e.target)) {
                setHasFocus(false)
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, setHasFocus]);
}

const useDarkTheme = () => {
    const dark = useRecoilValue(darkThemeState);
    useEffect(() =>{
        const htmlPage = document.querySelector('html');
        (dark === true) ? htmlPage.classList.add('dark') : htmlPage.classList.remove('dark');
    }, [dark])
}

const useDebugLogs = () => {
    const debug = useRecoilValue(debugState)
    useEffect(() =>{
        const debugLevel = (debug) ? 'DEBUG' : 'ERROR'
        Amplify.Logger.LOG_LEVEL = debugLevel;
        if (debug){
            //implement debugger
        }
    }, [debug])
}

const useSignedInUser = () => {
    const [ user, setUser ] = useState();

    useEffect(() => {
        async function getUser() {
            const authUserModel = dynamoTables.find( table => table.ref === 'auth_user' );
            const authUsers = await DataStore.query(authUserModel.model);

            if (authUsers.length !== 0) {
                setUser(authUsers[0]);
            }
        }

        if (!user) {
            getUser();
        }
    }, [])

    return [ user, setUser ];
}

export { useSearchResults, useLoseFocus, useDarkTheme, useRecentItems, useNewRecentItems, useDebugLogs, useSignedInUser };