import { FiWifiOff, FiWifi } from "react-icons/fi";
import { Icon } from 'konsta/react';
import {useRecoilValue } from 'recoil';

import { networkState } from '../../State/Global';


export default function HasConnection(){
    const onlineStyle = { color: "green" }
    const offlineStyle = { color: "red" }

    const isOnline = useRecoilValue(networkState)

    return (
        isOnline ?
          <Icon
            ios={<FiWifi className="w-7 h-7 text-green-500" style={onlineStyle}/>}
            material={<FiWifi className="w-6 h-6 text-green-500" style={onlineStyle}/>}
          /> :
          <Icon
            ios={<FiWifiOff className="w-7 h-7" style={offlineStyle}/>}
            material={<FiWifiOff className="w-6 h-6" style={offlineStyle}/>}
          />
    )
}