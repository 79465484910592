import { useEffect, useState } from "react";
import { Segmented, SegmentedButton, Page, Block } from "konsta/react";
import { useSetRecoilState } from "recoil";
import { useNavigate } from 'react-router-dom';

import GlobalSearch from "../../Utils/GlobalSearch/GlobalSearch";
import WorkAssignmentActive from "./WorkAssignmentActive";
import WorkAssignmentCurrent from "./WorkAssignmentCurrent";
import WorkAssignmentPast from "./WorkAssignmentPast";

import { globalSearchTables } from "../../../dbTables/syncObjects";
import { globalSearchFocusState, globalNavbarOptions } from "../../State/Global";
import { useSignedInUser } from '../../Hooks/Hooks';
import { pageRoutes } from "../../../routes/routes";
import Spinner from "../../Utils/Loading/Spinner";

export default function WorkAssignmentLanding() {
    const waDetailNav = pageRoutes.find((route) => route.id === "work-assignment-detail-page")?.nav;
    const workAssignmentTable = globalSearchTables.find(searchTable => searchTable.ref === "pffsm__WO_Assignment__c");

    const [ activeNavigation, setActiveNavigation ] = useState(0);
    const [ query, setQuery ] = useState("");
    const [ dropSelect, setDropSelect ] = useState();
    const [ isLoading, setIsLoading ] = useState(true);

    const setHasFocus = useSetRecoilState(globalSearchFocusState);
    const setNavbarOptions = useSetRecoilState(globalNavbarOptions);
    const navigate = useNavigate();
    const [ user ] = useSignedInUser();

    const mainWorkAssignmentTable = {
        ...workAssignmentTable,
        fields: [
            "Name",
            "pffsm__WO_Task_Description__c",
            "pffsm__WO_Description__c",
            "pffsm__Plant_Description__c",
            "pffsm__Assignment_Status__c"
        ]
    }

    useEffect(() => {
        setNavbarOptions({
            title: "Work Assignments",
            page: "work-assignment-page",
            hasLeft: false,
            hasRight: false
        });
    }, []);

    useEffect(() => {
        if (user) {
            setIsLoading(false);
        }
    }, [ user ])

    useEffect(() => {
        if(dropSelect !== undefined) {
            var assignId = dropSelect.id;
            navigate(`${waDetailNav}${assignId}`)
        }
    },[ dropSelect ]);

    const getContent = () => {
        if (activeNavigation === 0) {
            return (<WorkAssignmentActive />);
        } else if (activeNavigation === 1) {
            return (<WorkAssignmentCurrent currentUserId={user.sfUserId}/>);
        } else if (activeNavigation === 2) {
            return (<WorkAssignmentPast />);
        }

        return (<div>Page Not Found</div>);
    }

    const userInputChange = (e) => {
        setQuery(e.target.value);
    }

    const searchButtonClick = (e) => {
        setHasFocus(true);
    }

    return (
        <Page id="scrollable" className="mt-12">
            { isLoading ?
                <Spinner /> :
                <>
                    <Block margin="my-2" className="z-10">
                        <GlobalSearch searchObjects={[ mainWorkAssignmentTable ]} query={query} userInputChange={ userInputChange }
                            searchButtonClick={ searchButtonClick} displayField="Name" subtextField="pffsm__WO_Task_Description__c"
                            setSelected={ setDropSelect } showdrop qrysuggest />
                    </Block>

                    <Block margin="my-4" className="z-0">
                        <Segmented outline rounded>
                            <SegmentedButton active={activeNavigation === 0} onClick={() => setActiveNavigation(0)}>
                                Active
                            </SegmentedButton>
                            <SegmentedButton active={activeNavigation === 1} onClick={() => setActiveNavigation(1)}>
                                My Active WA
                            </SegmentedButton>
                            <SegmentedButton active={activeNavigation === 2} onClick={() => setActiveNavigation(2)}>
                                Past
                            </SegmentedButton>
                        </Segmented>
                    </Block>

                    <div className="relative z-0">
                        <Block margin="my-0">
                            { getContent() }
                        </Block>
                    </div>
                </>
            }
        </Page>
    );
}