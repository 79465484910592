import { Block, BlockTitle, List, ListItem, ListInput } from "konsta/react";
import { useNavigate } from "react-router-dom";
import Slider from 'react-rangeslider';

import 'react-rangeslider/lib/index.css';
import './DetailContent.css';

export default function DetailView({ flattenedObject, isEditing }) {
    const divStyle = {
        width: '1000px'
    };

    const navigate = useNavigate();

    if (!flattenedObject || flattenedObject.length === 0) {
        return (<div className="text-center mt-4">Record Not Found</div>);
    }

    return (
        <div className="pb-16">
            {
                flattenedObject.map((flattenDetail, flattenDetailIndex) => (
                    <div key={ flattenDetailIndex }>
                        <Block className="detail-title">
                            <BlockTitle className="text-2xl detail-text">{ flattenDetail.label }</BlockTitle>
                        </Block>
                        <List className="detail-list">
                            {
                                flattenDetail.values.map((flattenDetailItem) => (
                                    flattenDetailItem.isEditable && isEditing ? flattenDetailItem.isPicklist ?
                                        <>
                                        <label className="pl-4-safe shrink font-semibold">{ flattenDetailItem.label }</label>
                                        <select defaultValue={flattenDetailItem.value} className="shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1
                                        block w-full sm:text-sm border border-gray-300 rounded-md dark:text-gray-300
                                        dark:bg-gray-700 pl-4-safe shrink font-semibold"
                                        name={flattenDetailItem.label} onChange={flattenDetailItem.handler}>
                                        {flattenDetailItem.picklistValues.map((item) =>(<option value={item.value}>{item.label}</option>) )}
                                        </select>
                                        </>  : flattenDetailItem.isLargeText ?
                                        <div className="pl-4-safe w-full relative hairline-b detail-description py-2.5">
                                            <label className="shrink font-semibold">
                                                { flattenDetailItem.label }
                                            </label>
                                            <div className="col-span-2">
                                                <textarea defaultValue ={ flattenDetailItem.editValue ?? flattenDetailItem.value } required={ flattenDetailItem.isRequired }
                                                    id={ flattenDetailItem.label } name={ flattenDetailItem.label } rows="3" onChange={ flattenDetailItem.handler }
                                                    className="shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md dark:text-gray-300 dark:bg-gray-700" ></textarea>
                                            </div>
                                        </div> :
                                    <ListInput
                                        key={ flattenDetailItem.label }
                                        strongTitle
                                        label
                                        title={ flattenDetailItem.label }
                                        defaultValue ={ flattenDetailItem.editValue ?? flattenDetailItem.value }
                                        required={ flattenDetailItem.isRequired }
                                        type={ flattenDetailItem.type }
                                        onChange={ flattenDetailItem.handler }
                                    /> :
                                    flattenDetailItem.isLargeText ?
                                    <div className="pl-4-safe w-full relative hairline-b detail-description py-2.5">
                                        <label className="shrink font-semibold">
                                            { flattenDetailItem.label }
                                        </label>
                                        <div className="col-span-2">
                                            <textarea defaultValue={flattenDetailItem.value} id={ flattenDetailItem.label } name={ flattenDetailItem.label } rows="2" className="shadow-sm focus:ring-blue-500 focus:border-blue-500 mt-1 block w-full sm:text-sm rounded-md dark:text-gray-300 dark:bg-gray-700" readOnly ></textarea>
                                        </div>
                                    </div> :
                                    flattenDetailItem.isLookup &&  flattenDetailItem.idValue && flattenDetailItem.value ?
                                    <ListItem titleWrapClassName="break-words" innerClassName="detail-description" key={ flattenDetailItem.label } title={ flattenDetailItem.label } after={ flattenDetailItem.value } link onClick={() => { navigate(`${flattenDetailItem.lookupObj}/detail/${flattenDetailItem.idValue}`)}} strongTitle />
                                    :
                                    <ListItem titleWrapClassName="break-words" innerClassName="detail-description" key={ flattenDetailItem.label } title={ flattenDetailItem.label } after={ flattenDetailItem.value } strongTitle />
                                ))
                            }
                        </List>
                    </div>
                ))
            }
        </div>
    );
}